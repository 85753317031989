import { AxiosError } from 'axios'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useBoolean } from 'usehooks-ts'
import { useEffect, useState } from 'react'
import { isBoolean, isUndefined, reduce } from 'lodash'
import { IMetadataValueType } from '../actions/Tenants'

export const metadataTypeOptions = ['string', 'number', 'undefined', 'boolean', 'json'] as const
export type MetadataTypes = (typeof metadataTypeOptions)[number]
export type EditedMetadataValue = string | undefined | boolean
export type MetadataValue = string | boolean | number | undefined | { [x: string]: MetadataValue } | MetadataValue[]
export type Metadata = Record<string, MetadataValue>
export type MakeSetValueFn = (
  type: MetadataTypes
) => typeof type extends 'boolean' ? () => void : (value: string) => void

export interface DynamicMetadataServiceConfig<T> {
  tableTitle: string
  isEditable: boolean
  updateMutation: (onSaveFn: () => void) => UseMutationResult<T, AxiosError, Partial<T>>
  getQuery: UseQueryResult<T>
  getMetadataFromObject: (obj?: T) => Metadata | undefined
  makeObjectFromMetadata: (metadata: Metadata) => Partial<T>
}

export const getType = (value: IMetadataValueType): MetadataTypes => {
  const rawType = typeof value
  if (rawType === 'object') {
    return 'json'
  }
  if (metadataTypeOptions.includes(rawType as MetadataTypes)) {
    return rawType as MetadataTypes
  }
  throw Error('Invalid type in metadata.')
}

const setValueInArray =
  (index: number) =>
  <T>(value: T) =>
  (prevValues: T[]) => {
    const newValues = [...prevValues]
    newValues[index] = value
    return newValues
  }

export const mapValueToEditedValue = (type: MetadataTypes, value: MetadataValue): EditedMetadataValue => {
  switch (type) {
    case 'string':
      return value as string
    case 'number':
      return (value as number).toString()
    case 'boolean':
      return value as boolean
    case 'json':
      return JSON.stringify(value)
    case 'undefined':
      return value as undefined
  }
}

export const mapEditedValueToValue = (type: MetadataTypes, value: EditedMetadataValue): MetadataValue => {
  switch (type) {
    case 'string':
      return value as string
    case 'number': {
      const num = parseInt(value as string, 10)
      return isNaN(num) ? 0 : num
    }
    case 'boolean':
      return value as boolean
    case 'json':
      try {
        return JSON.parse(value as string)
      } catch (_error) {
        return 0
      }
    case 'undefined':
      return value as undefined
  }
}

export const validateMetadataValue = (type: MetadataTypes, value: EditedMetadataValue): boolean => {
  switch (type) {
    case 'string':
      return true
    case 'number':
      return !isNaN(parseInt(value as string, 10))
    case 'boolean':
      return isBoolean(true)
    case 'json':
      try {
        JSON.parse(value as string)
        return true
      } catch (_error) {
        return false
      }
    case 'undefined':
      return isUndefined(value)
  }
}

export interface DynamicMetadataService {
  tableTitle: string
  metadata?: Metadata
  metadataTypes: Record<string, MetadataTypes>
  editedMetadata: Record<string, MetadataValue>
  editedValues: EditedMetadataValue[]
  editedTypes: MetadataTypes[]
  editedKeys: string[]
  setKey: (index: number) => (value: string) => void
  setType: (index: number) => (value: MetadataTypes) => void
  addRow: () => void
  deleteRow: (index: number) => () => void
  reset: () => void
  makeSetValue: (index: number) => MakeSetValueFn
  loading: boolean
  updateMetadata: (metadata: Record<string, any>) => void
  isUpdating: boolean
  editing: boolean
  isEditable: boolean
  toggleEditing: () => void
  metadataIsValid: boolean
}

export const useDynamicMetadataService = <T extends object>({
  tableTitle,
  isEditable,
  updateMutation,
  getQuery,
  getMetadataFromObject,
  makeObjectFromMetadata
}: DynamicMetadataServiceConfig<T>) => {
  const query = getQuery
  const metadata = getMetadataFromObject(query.data)
  const updateMetadataMutation = updateMutation(() => {
    reset()
  })
  const updateMetadata = (newMetadata: Metadata) => updateMetadataMutation.mutate(makeObjectFromMetadata(newMetadata))

  const { value: editing, toggle: toggleEditing, setFalse: disableEditing } = useBoolean(false)
  const [editedKeys, setEditedKeys] = useState<string[]>([])
  const [editedValues, setEditedValues] = useState<EditedMetadataValue[]>([])
  const [editedTypes, setEditedTypes] = useState<MetadataTypes[]>([])

  const metadataTypes = reduce(
    metadata || {},
    (result: Record<string, MetadataTypes>, value, key) => {
      result[key] = getType(value)
      return result
    },
    {}
  )

  const reset = () => {
    const keys = Object.keys(metadata || {})
    setEditedKeys(keys)
    setEditedTypes(keys.map(key => metadataTypes[key]))
    setEditedValues(keys.map(key => mapValueToEditedValue(metadataTypes[key], metadata?.[key])))
    disableEditing()
  }

  useEffect(() => {
    if (!editing) {
      reset()
    }
  }, [metadata])

  const editedMetadata = editedKeys.reduce((result: Metadata, key, index) => {
    const type = editedTypes[index]
    const editedValue = editedValues[index]
    result[key] = mapEditedValueToValue(type, editedValue)
    return result
  }, {})
  const metadataIsValid = editedKeys
    .map((_key, index) => validateMetadataValue(editedTypes[index], editedValues[index]))
    .reduce((total, next) => total && next, true)

  const makeSetValue =
    (index: number): MakeSetValueFn =>
    (type: MetadataTypes) => {
      if (type === 'boolean') {
        return () =>
          setEditedValues(prevValues => {
            const newValues = [...prevValues]
            newValues[index] = !prevValues[index]
            return newValues
          })
      } else {
        return (value: string) =>
          setEditedValues(prevValues => {
            const newValues = [...prevValues]
            newValues[index] = value
            return newValues
          })
      }
    }
  const setKey = (index: number) => (value: string) => {
    setEditedKeys(setValueInArray(index)(value))
  }
  const setType = (index: number) => (type: MetadataTypes) => {
    setEditedTypes(setValueInArray(index)(type))
    switch (type) {
      case 'string':
      case 'number':
      case 'json':
        if (typeof editedValues[index] !== 'string') {
          setEditedValues(setValueInArray(index)<EditedMetadataValue>(''))
        }
        break
      case 'boolean':
        setEditedValues(setValueInArray(index)<EditedMetadataValue>(false))
        break
      case 'undefined':
        setEditedValues(setValueInArray(index)<EditedMetadataValue>(undefined))
        break
    }
  }
  const addRow = () => {
    setEditedKeys(prevValues => [...prevValues, ''])
    setEditedTypes(prevValues => [...prevValues, 'string'])
    setEditedValues(prevValues => [...prevValues, ''])
  }
  const removeValue =
    (index: number) =>
    <V>(prevValues: V[]) => {
      const newValues = [...prevValues]
      newValues.splice(index, 1)
      return newValues
    }
  const deleteRow = (index: number) => () => {
    setEditedKeys(removeValue(index))
    setEditedTypes(removeValue(index))
    setEditedValues(removeValue(index))
  }
  return {
    tableTitle,
    metadata,
    metadataTypes,
    editedMetadata,
    updateMetadata,
    loading: query.isLoading,
    editedKeys,
    editedValues,
    editedTypes,
    setType,
    setKey,
    makeSetValue,
    addRow,
    deleteRow,
    isEditable,
    editing,
    toggleEditing,
    reset,
    isUpdating: updateMetadataMutation.isLoading,
    metadataIsValid
  }
}
