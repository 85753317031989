import { Button, Icon, Popup, Table } from 'semantic-ui-react'
import { IJiraTicketDetails } from '../../../actions/Jira'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'

interface IProps {
  tickets?: IJiraTicketDetails[]
  loading: boolean
}

export const getTicketReleaseNotesRequirementValidationIconName = (ticket: IJiraTicketDetails) => {
  switch (ticket.fields.customfield_12712.value) {
    case 'No':
      return 'check'
    case 'Yes':
      if (ticket.fields.customfield_12713) {
        return 'check'
      } else {
        // any falsey
        return 'close'
      }
    case 'TBD':
    default:
      return 'question'
  }
}

export const TicketsList = (props: IProps) => (
  <Table striped color="blue" stackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
        <Table.HeaderCell>Key</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
          <Popup
            trigger={
              <div>
                {' '}
                Notes <Icon name="question circle" />{' '}
              </div>
            }
            content={
              <p>
                <Icon name="check" /> Valid: ticket has a release notes requirement (&apos;Yes&apos; or &apos;No&apos;)
                that is met. <br />
                <Icon name="close" /> Invalid: ticket has requirement &apos;Yes&apos;, but no release notes. <br />
                <Icon name="question" /> Unassigned/Unknown: ticket has requirement &apos;TBD&apos;, or ticket
                requirement has an unexpected value.
              </p>
            }
            basic
            wide="very"
          />
        </Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>View</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <TableLoaderWrap
      loading={props.loading}
      array={props.tickets || []}
      emptyMessage="No Jira Tickets Found for this Release"
    >
      <Table.Body>
        {props.tickets?.map(ticket => (
          <Table.Row key={ticket.key}>
            <Table.Cell textAlign="center">
              {ticket.fields.issuetype.iconUrl && (
                <img src={ticket.fields.issuetype.iconUrl} alt={ticket.fields.issuetype.name} />
              )}
            </Table.Cell>
            <Table.Cell>{ticket.key}</Table.Cell>
            <Table.Cell>{ticket.fields.summary}</Table.Cell>
            <Table.Cell textAlign="center">
              <Icon name={getTicketReleaseNotesRequirementValidationIconName(ticket)} />
            </Table.Cell>
            <Table.Cell>{ticket.fields.status.name}</Table.Cell>
            <Table.Cell>
              <Button
                icon="file alternate outline"
                href={`https://skedulo.atlassian.net/browse/${ticket.key}`}
                className="square-icon-button"
                target="_blank"
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </TableLoaderWrap>
  </Table>
)
