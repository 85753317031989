import * as React from 'react'
import { Button, Divider, Icon, List, Segment } from 'semantic-ui-react'
import * as _ from 'lodash'
import { IApplication } from '../../../actions/Applications'
import { IReleaseNotesPage } from '../../../actions/Confluence'
import { IRelease, IReleaseApplication } from '../../../actions/Releases'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'

interface IProps {
  release?: IRelease
  prevRelease?: IRelease
  application?: IReleaseApplication
  runningApplications?: IApplication[]
  releaseNotesPage?: IReleaseNotesPage
  deploymentName?: string
  deployable: boolean
  loading: boolean
}

export const getDeployableReleaseStatus = (
  release: IRelease,
  deploymentName: string,
  application: IReleaseApplication,
  runningVersions?: IApplication[]
) => {
  if (release.released) {
    return 'Released'
  }
  if (runningVersions && runningVersions.length > 0) {
    const stagingApplication = runningVersions.filter(
      runningApplication =>
        runningApplication &&
        runningApplication.environment === 'staging' &&
        runningApplication.instance === 'us1' &&
        runningApplication.name === deploymentName
    )
    const stagingTag = stagingApplication.length > 0 && stagingApplication[0] && stagingApplication[0].meta.tag
    const prodApplication = runningVersions.filter(
      runningApplication =>
        runningApplication &&
        runningApplication.environment === 'prod' &&
        runningApplication.instance === 'us1' &&
        runningApplication.name === deploymentName
    )
    const prodTag = prodApplication.length > 0 && prodApplication[0] && prodApplication[0].meta.tag
    if (prodTag === release.tag) {
      return 'Deployed (Awaiting Complete Release)'
    }
    if (stagingTag === release.tag) {
      return 'Ready to be deployed'
    }
  }
  return 'Cut'
}

export const getReleaseStatus = (release: IRelease) => {
  if (release.released) {
    return 'Released'
  }
  if (release.staged) {
    return 'Staged'
  }
  return 'Created'
}

const getReleaseNotesUrl = (releaseNotes?: IReleaseNotesPage) => {
  if (releaseNotes) {
    return releaseNotes._links.base + releaseNotes._links.webui
  } else {
    return null
  }
}

const getReleaseGitHubButton = (
  application: IReleaseApplication,
  deploymentName: string,
  release: IRelease,
  runningApplications?: IApplication[],
  prevRelease?: IRelease
) => {
  let repoButtonText: string = ''
  let repoButtonURL: string = ''
  if (!release.isCD) {
    // not CD: link to release branch
    repoButtonText = 'View Release Branch'
    repoButtonURL = `https://github.com/${application.repositoryOwner}/${application.repositoryName}/tree/release/${release.version}`
  } else {
    // continuous delivery: link to a compare of the staging tag to the master tag
    const thisTag = release.tag.substring(0, 7)
    let prevTag: string | undefined
    const prodApp = runningApplications?.find(app => app.name === deploymentName && app.environment === 'prod')
    if (release.released && !_.isUndefined(prevRelease?.tag)) {
      // released: compare to previous version
      prevTag = prevRelease?.tag.substring(0, 7)
      repoButtonURL = `https://github.com/${application.repositoryOwner}/${application.repositoryName}/compare/${prevTag}..${thisTag}`
      repoButtonText = `Compare to ${prevRelease?.version}`
    } else if (!release.released && !_.isUndefined(prodApp?.config_version)) {
      // not released: compare to running config
      prevTag = prodApp?.meta.tag.substring(0, 7) // prodApp will be defined here
      repoButtonURL = `https://github.com/${application.repositoryOwner}/${application.repositoryName}/compare/${prevTag}..${thisTag}`
      repoButtonText = `Compare to ${prodApp?.name}-${prodApp?.environment}-${prodApp?.instance}`
    } else {
      // no previous version, no running version: return link to repo
      repoButtonText = 'View Repository'
      repoButtonURL = `https://github.com/${application.repositoryOwner}/${application.repositoryName}`
    }
  }
  return {
    repoButtonText,
    repoButtonURL
  }
}

export const ReleaseInformation = (props: IProps) => {
  const application = props.application || ({} as IReleaseApplication)
  const release = props.release || ({} as IRelease)
  const deploymentName = props.deploymentName || ''
  const { repoButtonText, repoButtonURL } = getReleaseGitHubButton(
    application,
    deploymentName,
    release,
    props.runningApplications,
    props.prevRelease
  )
  return (
    <div>
      <h3>Release Info</h3>
      <Segment color="blue">
        <LoaderWrap loading={props.release === undefined || props.application === undefined}>
          <React.Fragment>
            <h4>{`${application.applicationName} ${release.version}`}</h4>
            <List name="info-list">
              <List.Item>
                <List.Header>Release Type</List.Header>
                {release.isCD ? 'Continuous Deployment' : 'Classic'}
              </List.Item>
              <List.Item>
                <List.Header>Component</List.Header>
                {application.component}
              </List.Item>
              <List.Item>
                <List.Header>Status</List.Header>
                {props.deployable
                  ? getDeployableReleaseStatus(release, deploymentName, application, props.runningApplications)
                  : getReleaseStatus(release)}
              </List.Item>
              <List.Item>
                <List.Header>Hotfix</List.Header>
                <Icon name={release.hotfix ? 'check' : 'close'} />
              </List.Item>
              <List.Item>
                <List.Header>Cut Tag</List.Header>
                <div className="table-cell-overflow">{release.tag}</div>
              </List.Item>
              {release.releasedDate && (
                <List.Item>
                  <List.Header>Release Date</List.Header>
                  {release.releasedDate}
                </List.Item>
              )}
            </List>
            <Button icon labelPosition="left" href={repoButtonURL} target="_blank" fluid>
              <Icon name="github" />
              {repoButtonText}
            </Button>
            {!release.isCD && (
              <div>
                <Divider />
                <Button
                  icon
                  labelPosition="left"
                  href={getReleaseNotesUrl(props.releaseNotesPage)}
                  target="_blank"
                  fluid
                  loading={props.loading}
                  disabled={!props.releaseNotesPage}
                >
                  <Icon name="file alternate" />
                  View Release Notes
                </Button>
              </div>
            )}
          </React.Fragment>
        </LoaderWrap>
      </Segment>
    </div>
  )
}
