import { groupBy, some } from 'lodash'
import { useMemo } from 'react'
import { Link, useSearchParams } from 'react-router'
import { Row } from 'react-table'
import { Label } from 'semantic-ui-react'
import { FeatureFlagStatus, Platform } from 'src/actions/Tenants'
import { createMemoizedColumns, Table } from '../../components/TableComponents/ReactTable'
import { FilterCategories, filtersMatchAny, searchParamsToFilters } from '../filterComponents/Filters'
import {
  IFeatureFlagAggregate,
  featureFlagNameToKebabCase,
  featureFlagNameToSentenceCase
} from './NewFeatureFlagReport'

interface DisplayData {
  name: string
  count: number
  description: string
  status: FeatureFlagStatus
  defaultValue: 'Enabled' | 'Disabled'
  platform: Platform | 'Pulse'
}

export const NewFeatureFlagList = ({
  featureFlagAggregate,
  loading,
  filterCategories
}: {
  featureFlagAggregate: IFeatureFlagAggregate
  loading: boolean
  filterCategories: FilterCategories
}) => {
  const [searchParams] = useSearchParams()

  const displayData: DisplayData[] = useMemo(
    () =>
      Object.keys(featureFlagAggregate).map(key => ({
        name: featureFlagNameToSentenceCase(key),
        count: featureFlagAggregate[key].count,
        description: featureFlagAggregate[key].description,
        status: featureFlagAggregate[key].status,
        defaultValue: featureFlagAggregate[key].defaultValue ? 'Enabled' : 'Disabled',
        platform: featureFlagAggregate[key].platform === 'Skedulo' ? 'Pulse' : featureFlagAggregate[key].platform
      })),
    [featureFlagAggregate]
  )

  const filterFeatureFlags = (): DisplayData[] => {
    if (displayData.length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = displayData
    const groups = groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      result = result.filter(p => filtersMatchAny(categoryFilters, p[category as keyof DisplayData] as string, false))
    }
    return result
  }

  const flagColumns = (includeStatus: boolean) =>
    createMemoizedColumns<DisplayData>([
      {
        Header: 'Feature flag',
        accessor: 'name',
        Cell: ({ row }: { row: Row<DisplayData> }) => (
          <Link
            className="table-cell-no-wrap"
            to={`/feature-flag-report/${featureFlagNameToKebabCase(row.original.name)}`}
          >
            {row.original.name}
          </Link>
        )
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      ...(includeStatus
        ? [
            {
              Header: 'Status',
              accessor: (row: DisplayData) => row.status
            }
          ]
        : []),
      {
        Header: 'Platform',
        accessor: 'platform'
      },
      {
        Header: 'Default state',
        accessor: 'defaultValue',
        Cell: ({ row }: { row: Row<DisplayData> }) => (
          <Label color="blue" basic={row.original.defaultValue === 'Disabled'}>
            {row.original.defaultValue}
          </Label>
        )
      },
      {
        Header: 'Teams enabled',
        accessor: 'count'
      }
    ])

  const featuresGa = filterFeatureFlags().filter(f => f.status === 'GeneralAvailability')
  const featuresBeta = filterFeatureFlags().filter(f => f.status === 'Beta')
  const featuresAlpha = filterFeatureFlags().filter(f => f.status === 'Alpha')
  const featuresOther = filterFeatureFlags().filter(
    f => !some([...featuresGa, ...featuresAlpha, ...featuresBeta].filter(f2 => f2.name === f.name))
  )
  const featuresTable = [
    {
      title: 'General availability features',
      subtitle: 'Features that are fully implemented and stable',
      data: featuresGa
    },
    {
      title: 'Beta features',
      subtitle: 'Features that are available for customers to use but may be unstable',
      data: featuresBeta
    },
    {
      title: 'Alpha features',
      subtitle: 'Features that are available for preview by selected customers and are unstable',
      data: featuresAlpha
    },
    {
      title: 'Other features',
      subtitle: 'Features that are under development, for internal use or deprecated',
      data: featuresOther
    }
  ]

  const featureTable = (title: string, subtitle: string, data: DisplayData[]) => (
    <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }} key={title}>
      <h4>{title}</h4>
      <p>{subtitle}</p>
      <Table
        data={data || []}
        loading={loading}
        columns={flagColumns(title.includes('Other'))}
        emptyMessage={'Click the Fetch data button to load the latest feature flag data'}
        celled
        color="blue"
        textAlign="left"
        className="sticky-table"
      />
    </div>
  )

  return <div>{featuresTable.map(status => featureTable(status.title, status.subtitle, status.data))}</div>
}
