import React, { JSXElementConstructor, useEffect, useState } from 'react'
import { Dropdown, Icon, Label, Menu, Message, Popup, Tab, Image, TabPane } from 'semantic-ui-react'
import { startCase, toLower } from 'lodash'
import { Link, useSearchParams } from 'react-router'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useGetTeamQuery } from '../../queries/GetTeamQuery'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import DynamicBreadcrumbs from '../../components/Breadcrumbs/DynamicBreadcrumbs'
import { useTabLinkingService } from '../../services/TabLinkingService'
import { useRouterProps } from '../../router/RouterProps'
import { salesforceLogo } from '../../images/icons'
import { PageContent } from '../../components/react/PageContent'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { Breadcrumb } from '../../components/react/Breadcrumbs'
import TeamMetadataTable from './component/TeamMetadataTable'
import TeamLoginConnectionsTable from './component/TeamLoginConnectionsTable'
import TeamLoginOptionsTable from './component/TeamLoginOptionsTable'
import TeamFeatureFlags from './component/TeamFeatureFlags'
import TeamUsers from './component/TeamUsers'
import DeleteTeamModal from './component/DeleteTeamModal'
import ResetTeamModal from './component/ResetTeamModal'
import TeamNamesList from './component/TeamNamesList'
import { environmentColours } from './component/NewTeamsList'
import TeamDetailsTable from './component/TeamDetailsTable'
import { NewTeamNamesList } from './component/NewTeamNamesList'
import ConnectedFunctionList from './component/ConnectedFunctionList'
import MobileExtensionsList from './component/MobileExtensions/MobileExtensionsList'
import { PackagesList } from './component/PackagesList'
import { TriggeredActions } from './component/TriggeredActions'

export interface Pane {
  menuItem: string
  render: () => JSX.Element
}

const panes: {
  [x: string]: JSXElementConstructor<{
    teamId: string
    customerId: string
    metadata: Record<string, any>
  }>
} = {
  Details: TeamDetailsTable,
  LoginConnections: TeamLoginConnectionsTable,
  LoginOptions: TeamLoginOptionsTable,
  FeatureFlags: TeamFeatureFlags,
  Metadata: TeamMetadataTable,
  Users: TeamUsers,
  TeamNames: TeamNamesList,
  ConnectedFunctions: ConnectedFunctionList,
  MobileExtensions: MobileExtensionsList,
  Packages: PackagesList,
  TriggeredActions
}

const makePane =
  (teamId: string, customerId: string, metadata: Record<string, any>) =>
  (
    name: string,
    Component: JSXElementConstructor<{
      teamId: string
      customerId: string
      metadata: Record<string, any>
    }>
  ) => {
    const startCaseName = startCase(name)
    const sentenceCaseName = startCaseName.charAt(0) + toLower(startCaseName.slice(1))
    return {
      menuItem: sentenceCaseName,
      render: () => (
        <TabPane>
          <Component teamId={teamId} customerId={customerId} metadata={metadata} />
        </TabPane>
      )
    }
  }

const getPanes = (teamId: string, customerId: string, metadata: Record<string, any>) =>
  Object.keys(panes).map(paneName => makePane(teamId, customerId, metadata)(paneName, panes[paneName]))

const isBannerVisible = (urlSearchParams: URLSearchParams): boolean => urlSearchParams.get('banner') === 'true'

const filterPane = (pane: Pane, isNelxTeam: boolean) =>
  (pane.menuItem !== 'Login options' && pane.menuItem !== 'Login connections') ||
  (pane.menuItem === 'Login options' && isNelxTeam) ||
  (pane.menuItem === 'Login connections' && !isNelxTeam)

export const NewTeamDetails = () => {
  const routerProps = useRouterProps()
  const [urlSearchParams] = useSearchParams()
  const teamId = routerProps.params.id!

  const [redirect, setRedirect] = useState(false)
  const [banner, setBanner] = React.useState(isBannerVisible(urlSearchParams))
  const { data: team } = useGetTeamQuery(teamId)
  const { data: customer } = useGetCustomerQuery(team?.customerId ?? '')
  const { setDocumentTitle } = useDocumentTitle(team?.primaryName)

  const isNelxTeam = team?.authProvider === 'auth0-organization'
  const filteredPanes = getPanes(teamId, customer?.id as string, team?.metadata ?? {}).filter(pane =>
    filterPane(pane, isNelxTeam)
  )

  const filteredtabs = isNelxTeam
    ? Object.keys(panes).filter(tab => tab !== 'LoginConnections')
    : Object.keys(panes).filter(tab => tab !== 'LoginOptions')

  useEffect(() => {
    if (team?.primaryName) {
      setDocumentTitle(team.primaryName)
    }
  }, [team])

  const { onTabChange, activeIndex } = useTabLinkingService(filteredtabs, 'details')

  useEffect(() => {
    if (redirect) {
      routerProps.navigate('/teams')
    }
  }, [redirect])

  return (
    <PageContent>
      {banner && (
        <Message
          data-testid="team-redirect-banner"
          header="Changes to the team model"
          content={
            <div>
              <p>
                This team has been migrated to the new model. You are currently viewing the new team details page.{' '}
                <a
                  href="https://skedulo.atlassian.net/wiki/spaces/ProductDevelopment/pages/2202730518/New+Team+Model+Overview"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b style={{ fontWeight: 800, textDecoration: 'underline' }}>Learn more</b>
                </a>{' '}
                about changes or contact <b style={{ fontWeight: 800 }}> #squad-core-services</b> via slack if you have
                any questions.
              </p>
            </div>
          }
          icon="warning"
          warning
          onDismiss={() => setBanner(false)}
        />
      )}

      <Breadcrumb>
        <DynamicBreadcrumbs initialBreadcrumbs={[{ path: '/customers', name: 'Customers' }]} teamId={teamId} />
      </Breadcrumb>

      <PageContent.Sidebar>
        <h2 color="blue">
          <Link to={`/customers/${customer?.id}`}>{customer?.name}</Link>
        </h2>
        <NewTeamNamesList customer={customer} teamId={teamId} />
      </PageContent.Sidebar>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Filters>
            <h2 style={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
              {team?.primaryName}
              {team?.isSalesforce && (
                <Popup
                  content="Salesforce Team"
                  position="top center"
                  trigger={
                    <Image inline={true} id="salesforce-logo" src={salesforceLogo.href} size="mini" spaced="left" />
                  }
                  style={{
                    opacity: 0.9,
                    textAlign: 'center'
                  }}
                  inverted
                />
              )}{' '}
              {team?.environment && (
                <Label color={environmentColours[team?.environment] ?? 'grey'} basic>
                  {team?.environment}
                </Label>
              )}
              {team?.environment === 'Production' && (
                <Popup
                  content="Production teams cannot be reset or deleted."
                  trigger={
                    <div>
                      <Icon name="info circle" size="small" style={{ paddingLeft: '5px' }} />
                    </div>
                  }
                  basic
                />
              )}
            </h2>
          </FiltersTogglesButtons.Filters>
          <FiltersTogglesButtons.Buttons>
            <Menu secondary stackable>
              <Menu.Menu position="right">
                {team?.environment !== 'Production' && (
                  <Dropdown id="action-menu" text="Actions" button basic className="action-menu">
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <ResetTeamModal
                          teamId={teamId}
                          redirectFn={() => setRedirect(true)}
                          menuItem
                          isSalesforceForm={team?.isSalesforce ?? false}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <DeleteTeamModal teamId={teamId} redirectFn={() => setRedirect(true)} menuItem />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Menu.Menu>
            </Menu>
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>

        <div style={{ marginBottom: '10px' }}>{team?.description}</div>
        <Tab
          data-testid="new-teams-details-tabs"
          panes={filteredPanes}
          onTabChange={onTabChange}
          activeIndex={activeIndex}
        />
      </PageContent.Main>
    </PageContent>
  )
}
