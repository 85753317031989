import { Link } from 'react-router'
import { Row } from 'react-table'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { TenantWithSummary } from '../UsersReportForTenants'

export const UserSummaryTable = ({ data, loading }: { data?: TenantWithSummary[]; loading?: boolean }) => {
  const UnavailablePopup = (
    <Popup
      trigger={
        <div>
          Unavailable <Icon name="question circle" />
        </div>
      }
      content="Summary cannot be found for this tenant."
      basic
    />
  )
  const tenantColumns = createMemoizedColumns<TenantWithSummary>([
    {
      Header: 'Team',
      accessor: (tenant: TenantWithSummary) => tenant.tenant.teamName
    },
    {
      Header: 'Tenant ID',
      accessor: (tenant: TenantWithSummary) => tenant.summary.tenantId
    },
    {
      Header: 'Vendor',
      accessor: (tenant: TenantWithSummary) => tenant.tenant.vendor
    },
    {
      Header: 'Environment',
      accessor: (tenant: TenantWithSummary) => tenant.tenant.environment
    },
    {
      Header: 'Category',
      accessor: (tenant: TenantWithSummary) => tenant.tenant.category
    },
    {
      Header: 'Resources',
      accessor: (tenant: TenantWithSummary) => tenant.summary.totalActiveResources,
      Cell: ({ row }: { row: Row<TenantWithSummary> }) => {
        if (row.original.summary.totalActiveResources === null) {
          return UnavailablePopup
        }
        return <>{row.original.summary.totalActiveResources}</>
      }
    },
    {
      Header: 'Schedulers',
      accessor: (tenant: TenantWithSummary) => tenant.summary.totalActiveSchedulers,
      Cell: ({ row }: { row: Row<TenantWithSummary> }) => {
        if (row.original.summary.totalActiveSchedulers === null) {
          return UnavailablePopup
        }
        return <>{row.original.summary.totalActiveSchedulers}</>
      }
    },
    {
      Header: 'Details',
      accessor: (tenant: TenantWithSummary) => tenant.tenant.tenantId,
      Cell: ({ row }: { row: Row<TenantWithSummary> }) => (
        <Button
          icon="file alternate outline"
          as={Link}
          to={`/old-model/tenants/${row.original.tenant.tenantId}`}
          className="square-icon-button"
        />
      )
    }
  ])
  return (
    <div className="scrollable">
      <Table
        loading={loading}
        columns={tenantColumns}
        data={data || []}
        emptyMessage="No tenants found"
        celled
        color="blue"
        className="sticky-table"
      />
    </div>
  )
}
