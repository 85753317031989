import { Button, Form, Radio } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import {
  LoginOptionType,
  EMAIL_PASSWORD,
  EMAIL_PASSWORD_GUIDE,
  OPEN_ID_CONNECT,
  SELECT_SSO_MESSAGE,
  SSO,
  SsoType,
  SSO_GUIDE,
  SELECT_LOGIN_TYPE_MESSAGE
  /** Enable below when it is ready to be implemented */
  // GOOGLE_WORKSPACE,
  // MICROSOFT_AZURE_AD,
  // OKTA_WORKFORCE,
  // SAML_AUTH,
} from '../../../services/Constants'
import { ssoLogo } from '../../../../../utils/ssoLogo'

interface LoginOptionTypeProps {
  selectedLoginOptionType: LoginOptionType
  setSelectedLoginOptionType: (loginType: LoginOptionType) => void
  selectedSSO: SsoType
  setSelectedSSO: (sso: SsoType) => void
  isWarningMessageVisible: boolean
  hideWarningMessage: () => void
}

export interface ssoOption {
  name: SsoType
  description: string
}

export const sso_options: ssoOption[] = [
  {
    name: OPEN_ID_CONNECT,
    description: 'Allow users to login with Open ID Connect'
  }
  /** Enable below when it is ready to be implemented */
  // {
  //   name: GOOGLE_WORKSPACE,
  //   description: 'Allow users to login with their Google account'
  // },
  // {
  //   name: OKTA_WORKFORCE,
  //   description: 'Allow users to login with their Okta account'
  // },
  // {
  //   name: SAML_AUTH,
  //   description: 'Allow users to login with SAML'
  // },
  // {
  //   name: MICROSOFT_AZURE_AD,
  //   description: 'Allow users to login with Microsoft Azure AD'
  // }
]

const LoginOptionTypeForm = ({
  selectedLoginOptionType,
  setSelectedLoginOptionType,
  selectedSSO,
  setSelectedSSO,
  isWarningMessageVisible,
  hideWarningMessage
}: LoginOptionTypeProps) => {
  const { value: isSSOWarningShown, setTrue: showSelectSSOMessage, setFalse: hideSelectSSOMessage } = useBoolean(false)
  const handleSSOSelected = (ssoName: SsoType) => {
    hideWarningMessage()
    setSelectedSSO(ssoName)
    hideSelectSSOMessage()
  }
  const handleSelectedLoginOptionType = (loginOptionType: LoginOptionType) => {
    hideWarningMessage()
    if (loginOptionType === SSO) {
      showSelectSSOMessage()
    } else {
      hideSelectSSOMessage()
    }
    setSelectedLoginOptionType(loginOptionType)
  }

  return (
    <Form>
      <h3>Login option type</h3>
      {isWarningMessageVisible && !isSSOWarningShown && (
        <div style={{ color: 'red', marginLeft: '15px' }}>{SELECT_LOGIN_TYPE_MESSAGE}</div>
      )}
      <Form.Field>
        <Radio
          id={EMAIL_PASSWORD}
          label={
            <label style={{ paddingLeft: '30px' }}>
              <h4>{EMAIL_PASSWORD}</h4>
              {EMAIL_PASSWORD_GUIDE}
            </label>
          }
          style={{ textAlign: 'left', padding: '14px' }}
          value={selectedLoginOptionType}
          checked={selectedLoginOptionType === EMAIL_PASSWORD}
          onClick={() => handleSelectedLoginOptionType(EMAIL_PASSWORD)}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          id={SSO}
          label={
            <label style={{ paddingLeft: '30px' }}>
              <h4>{SSO}</h4>
              {SSO_GUIDE}
            </label>
          }
          style={{ textAlign: 'left', paddingLeft: '14px' }}
          value={selectedLoginOptionType}
          onClick={() => handleSelectedLoginOptionType(SSO)}
          checked={selectedLoginOptionType === SSO}
        />
        {isSSOWarningShown && isWarningMessageVisible && (
          <div style={{ color: 'red', marginLeft: '45px', marginTop: '10px' }}>{SELECT_SSO_MESSAGE}</div>
        )}
      </Form.Field>

      {sso_options.map(sso => (
        <Form.Field
          key={sso.name}
          onClick={() => handleSSOSelected(sso.name)}
          disabled={selectedLoginOptionType !== SSO}
        >
          <Button style={{ textAlign: 'left', marginLeft: '42px', padding: '20px', width: '60%' }} basic fluid>
            <Radio
              id={sso.name}
              label={
                <label
                  style={{
                    paddingLeft: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'top'
                  }}
                  onClick={() => handleSSOSelected(sso.name)}
                >
                  <div>
                    <h4>{sso.name}</h4>
                    {sso.description}
                  </div>
                  <div style={{ alignSelf: 'flex-end', justifySelf: 'flex-start' }}> {ssoLogo(sso?.name)}</div>
                </label>
              }
              style={{ textAlign: 'left', display: 'block' }}
              disabled={selectedLoginOptionType !== SSO}
              value={selectedSSO}
              onClick={() => handleSSOSelected(sso.name)}
              checked={selectedLoginOptionType === SSO && selectedSSO === sso.name}
            />
          </Button>
        </Form.Field>
      ))}
    </Form>
  )
}

export default LoginOptionTypeForm
