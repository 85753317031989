import { Button, Divider, Icon, Input, Popup } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { Fragment, useState } from 'react'
import { isNumber, upperFirst } from 'lodash'
import { Row } from 'react-table'
import { SortedHeaderCellProps } from 'src/components/TableComponents/BaseTableComponents/SortableHeaderCell'
import { TeamWithSummary } from '../../../../views/newCustomers/component/CustomerUsers'
import { LoaderWrap } from '../../../loadingComponents/LoaderWrap'
import { IUserSummary } from '../../../../actions/Tenants'
import { useUpdateTeamMutation } from '../../../../mutations/UpdateTeamMutation'
import { UserCount } from './components/UserCount'

export const getPercentageUsersUsed = (limit?: number | null, used?: number | null): number | null => {
  if (limit == null || used == null || isNaN(used) || limit <= 0) {
    return null
  }
  const percentUsed = (used / limit) * 100
  return percentUsed
}

interface ContentMapEntry {
  label: string
  content: JSX.Element
  sign?: '+' | '='
}

const COUNTS_CONTENT: Record<string, ContentMapEntry> = {
  totalActiveResources: {
    label: 'Resources',
    content: (
      <div>
        A user is counted as a Resource if they are active, have the "Resource" role assigned, and{' '}
        <b>don&apos;t have access to ANY</b> of the following permissions via their allocated user roles:
        <ul>
          <li>Manage resource allocations</li>
          <li>Dispatch allocated work to resources</li>
          <li>Create and send job and shift offers</li>
        </ul>
      </div>
    ),
    sign: '+'
  },
  totalActiveSchedulers: {
    label: 'Schedulers',
    content: (
      <div>
        A user is counted as a Scheduler if they are active and <b>have access to ANY</b> of the following permissions
        via their allocated user roles:
        <ul>
          <li>Manage resource allocations</li>
          <li>Dispatch allocated work to resources</li>
          <li>Create and send job and shift offers</li>
        </ul>
        Users with the Admin role applied are always counted as a Scheduler.
      </div>
    ),
    sign: '+'
  },
  totalActiveUnknowns: {
    label: 'Others',
    content: (
      <div>
        These are "Active users" who have not been counted as Resource or Scheduler. This could indicate that the user
        has custom roles or, <b>for Pulse teams only</b>, no roles assigned. There may be a special license arrangement
        with the customer.
      </div>
    ),
    sign: '='
  },
  totalActiveUsers: {
    label: 'Total active',
    content: (
      <div>
        An “active user” is a user with:
        <ul>
          <li>“User status” not equal to “Deactivated”</li>
          <li>Email address does not contain “@skedulo.com”</li>
          <li>
            <b>(Salesforce teams only)</b> Any role or permissions assigned
          </li>
        </ul>
      </div>
    )
  }
}

const UnavailablePopupTeam = () => (
  <Popup
    trigger={
      <div style={{ display: 'flex' }}>
        Unavailable <Icon name="question circle" />
      </div>
    }
    content="Summary cannot be found for this team. Ensure an API User is set for this team."
    style={{
      opacity: 0.9,
      textAlign: 'center'
    }}
    inverted
  />
)

const CountCell = ({ count, isTotal }: { count: string | number | null; isTotal: boolean }) =>
  isNumber(count) ? <div style={{ fontWeight: isTotal ? 'bold' : '' }}>{count}</div> : <UnavailablePopupTeam />

export const userCountColumns = (SortableTeamsHeaderCell?: (props: SortedHeaderCellProps) => JSX.Element) =>
  Object.keys(COUNTS_CONTENT).map(key => ({
    Header: SortableTeamsHeaderCell ? (
      <SortableTeamsHeaderCell title={COUNTS_CONTENT[key].label} name={key} />
    ) : (
      COUNTS_CONTENT[key].label
    ),
    id: key,
    accessor: (team: TeamWithSummary) => team.summary[key as keyof IUserSummary],
    Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
      <CountCell count={row.original.summary[key as keyof IUserSummary]} isTotal={key === 'totalActiveUsers'} />
    )
  }))

const CountItem = ({
  category,
  labelPrefix,
  data
}: {
  category: keyof IUserSummary
  labelPrefix: string
  data: IUserSummary
}) => (
  <div aria-label={`${labelPrefix} ${COUNTS_CONTENT[category].label}`}>
    <UserCount.Item>
      <UserCount.Value>{upperFirst(data[category]?.toString())}</UserCount.Value>
      <UserCount.Label>
        {labelPrefix} {labelPrefix ? COUNTS_CONTENT[category].label.toLowerCase() : COUNTS_CONTENT[category].label}{' '}
        <Popup
          trigger={<Icon name="question circle" />}
          content={COUNTS_CONTENT[category].content}
          basic
          wide
          hoverable
        />
      </UserCount.Label>
    </UserCount.Item>
  </div>
)

export const UserCountsBase = ({
  data,
  loading,
  labelPrefix,
  userLimitComponent
}: {
  data: IUserSummary[]
  loading: boolean
  labelPrefix: string
  userLimitComponent?: JSX.Element
}) => (
  <LoaderWrap loading={loading}>
    <div aria-label="User Summary">
      <UserCount>
        {Object.keys(COUNTS_CONTENT).map(key => (
          <Fragment key={key}>
            <CountItem category={key as keyof IUserSummary} labelPrefix={labelPrefix} data={data[0]} />
            {(COUNTS_CONTENT[key].sign || userLimitComponent) && (
              <UserCount.Value>{COUNTS_CONTENT[key].sign}</UserCount.Value>
            )}
          </Fragment>
        ))}
        {userLimitComponent}
      </UserCount>
      <Divider />
    </div>
  </LoaderWrap>
)

export const UserCounts = ({
  data,
  loading,
  hideLimitForTeam,
  isForCustomer,
  metadata,
  teamId
}: {
  data: IUserSummary[]
  loading: boolean
  hideLimitForTeam?: boolean
  isForCustomer?: boolean
  metadata?: Record<string, any>
  teamId?: string
}) => {
  const labelPrefix = isForCustomer ? 'Production' : ''
  const maxActiveUsersEnforced: number | undefined = metadata?.MaxActiveUsersEnforced
  const { value: isEdit, toggle: toggleEditing } = useBoolean(false)
  const [editedLimit, setEditedLimit] = useState<string>()
  const { mutate: updateTeam } = useUpdateTeamMutation(teamId!)(() => null)

  if (hideLimitForTeam || isForCustomer) {
    return <UserCountsBase data={data} loading={loading} labelPrefix={labelPrefix} />
  }

  const save = async () => {
    if (editedLimit !== undefined) {
      updateTeam({
        metadata: { ...metadata, MaxActiveUsersEnforced: parseInt(editedLimit, 10) }
      })
    }
    toggleEditing()
  }

  const editCancel = () => {
    setEditedLimit(undefined)
    toggleEditing()
  }

  const percentUsed = getPercentageUsersUsed(maxActiveUsersEnforced, data[0].totalActiveUsers)

  const userLimitComponent = (
    <>
      <UserCount.Limit>
        <UserCount.Label>
          Active user limit{' '}
          <Popup
            trigger={<Icon name="question circle" />}
            content={
              <div>
                Active user limits can be set by clicking the <b>Edit</b> button here or by navigating to the{' '}
                <b>Metadata</b> tab and adding a new property with the following values:
                <ul>
                  <li>
                    <b>Value:</b> MaxActiveUsersEnforced
                  </li>
                  <li>
                    <b>Type:</b> Number
                  </li>
                </ul>
                When set, users will be notified of their limit via an alert banner on the <b>Users</b> list. They will
                see an error, and will be unable to create or reactivate users if their limit has been exceeded
              </div>
            }
            basic
            wide
            hoverable
          />
        </UserCount.Label>
        <UserCount.Label>Percentage used</UserCount.Label>
      </UserCount.Limit>
      <UserCount.Limit>
        <UserCount.Value>
          {isEdit ? (
            <Input
              size="mini"
              placeholder="Set limit"
              value={editedLimit ?? maxActiveUsersEnforced ?? ''}
              onChange={(_, { value }) => setEditedLimit(value)}
              type="number"
            />
          ) : (
            (maxActiveUsersEnforced ?? 'Not set')
          )}{' '}
          <Button className="sked-blue-button" size="mini" onClick={editCancel} content={isEdit ? 'Cancel' : 'Edit'} />
          {isEdit && <Button className="sked-blue-button" primary size="mini" onClick={_ => save()} content={'Set'} />}
        </UserCount.Value>
        <UserCount.Value>
          <span style={{ color: percentUsed! < 100 ? 'inherit' : 'red' }}>
            {percentUsed ? percentUsed.toFixed() + '%' : 'N/A'}
          </span>
        </UserCount.Value>
      </UserCount.Limit>
    </>
  )

  return (
    <UserCountsBase data={data} loading={loading} labelPrefix={labelPrefix} userLimitComponent={userLimitComponent} />
  )
}
