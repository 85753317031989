import { Navigate } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useRouterProps } from '../../router/RouterProps'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useDeploymentsQueryByEnvironmentAndInstance } from '../../queries/deployments/GetDeploymentsQuery'
import { useApplicationPodsQuery, useApplicationQuery } from '../../queries/applications/GetApplicationsQuery'
import { useReleaseApplicationQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { PodsTable } from '../podComponents/PodsTable'
import { PageContent } from '../../components/react/PageContent'
import { HorizontalDivider } from '../../components/react/HorizontalDivider'
import { ApplicationDeployments } from './components/ApplicationDeployments'
import { ApplicationInformation } from './components/ApplicationInformation'
import { ApplicationRevisionsList } from './components/ApplicationRevisionsList'

export const ApplicationDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('')

  const appName = routerProps.params.name!
  const environment = routerProps.params.environment!
  const instance = routerProps.params.instance!

  const { data: application, error } = useApplicationQuery(appName, instance, environment)
  const { data: pods, status: podsStatus } = useApplicationPodsQuery(application)
  const { data: releaseApplication } = useReleaseApplicationQuery(application?.name)
  const queryClient = useQueryClient()

  const invalidateApplication = useCallback(
    () => async () => {
      await queryClient.invalidateQueries(['application', appName, instance, environment])
    },
    [appName, instance, environment, queryClient]
  )

  if (application !== undefined && documentTitle !== application.name) {
    setDocumentTitle(application.name)
  }

  const { data: deployments, status: deploymentsStatus } = useDeploymentsQueryByEnvironmentAndInstance(
    environment,
    instance
  )

  const filteredDeployments = deployments
    ? deployments.filter(deployment => application?.name && deployment.application?.includes(application.name))
    : []

  return (
    <PageContent>
      {error && <Navigate to="/applications/applications" />}
      <PageContent.Sidebar>
        <ApplicationInformation
          application={application}
          releaseApplication={releaseApplication}
          name={appName}
          instance={instance}
          environment={environment}
        />
      </PageContent.Sidebar>
      <PageContent.Main>
        <h3>Pods</h3>
        <PodsTable pods={pods || []} loading={podsStatus === 'loading'} />
        <HorizontalDivider />
        <ApplicationRevisionsList application={application} reloadApplication={invalidateApplication} />
        <HorizontalDivider />
        <ApplicationDeployments deployments={filteredDeployments} loading={deploymentsStatus === 'loading'} />
      </PageContent.Main>
    </PageContent>
  )
}
