import { useState } from 'react'
import { Label } from 'semantic-ui-react'
import {
  useGetTriggeredActionLogsQuery,
  useGetTriggeredActionsQuery
} from '../../../queries/triggered-actions/GetTriggeredActionsQuery'
import { ActionType, TriggeredAction, TriggerType } from '../../../actions/TriggeredActions'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { TriggeredActionsList } from './TriggeredActionsList'
import { TriggeredActionLogs } from './TriggeredActionLogs'

export const TriggeredActionStatus = (props: { status: string | null }) => {
  const { status } = props
  switch (status) {
    case 'success':
      return (
        <Label basic color="green">
          Successful
        </Label>
      )
    case 'error':
      return (
        <Label basic color="red">
          Failed
        </Label>
      )
    case null:
      return <></>
    default:
      return (
        <Label basic color="grey">
          {status ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() : ''}
        </Label>
      )
  }
}

export const TriggeredActionActionType = (props: { value?: ActionType | null }) => {
  const action_type = (value?: ActionType | null) => {
    switch (value) {
      case 'call_url':
        return 'Call URL'
      case 'send_sms':
        return 'Send SMS'
      default:
        return value
    }
  }
  return <span>{action_type(props.value)}</span>
}

export const TriggeredActionTriggerType = (props: { value?: TriggerType | null }) => {
  const trigger_type = (value?: TriggerType | null) => {
    switch (value) {
      case 'object_modified':
        return 'Object Modified'
      case 'event':
        return 'Event'
      default:
        return value
    }
  }
  return <span>{trigger_type(props.value)}</span>
}

export const TriggeredActions = (props: { teamId: string; customerId: string; metadata: Record<string, any> }) => {
  const { data: team } = useGetTeamQuery(props.teamId)
  const { data: triggeredActions, isLoading } = useGetTriggeredActionsQuery(team?.tenantId, team?.region)

  const [triggeredAction, setTriggeredAction] = useState<TriggeredAction | undefined>(undefined)
  const { data: triggeredActionLogs, isLoading: isLoadingLogs } = useGetTriggeredActionLogsQuery(
    team?.tenantId,
    team?.region,
    triggeredAction?.id
  )

  return triggeredAction ? (
    <TriggeredActionLogs
      name={triggeredAction.name}
      triggeredActionLogs={triggeredActionLogs}
      isLoading={isLoadingLogs}
      setTriggeredAction={setTriggeredAction}
    />
  ) : (
    <TriggeredActionsList
      triggeredActions={triggeredActions}
      isLoading={isLoading}
      setTriggeredAction={setTriggeredAction}
    />
  )
}
