import { Divider, Grid, Icon, Message } from 'semantic-ui-react'
import { Link } from 'react-router'
import { ISalesforceAccountWithContracts, Order } from '../../../actions/SalesforceAccount'
import { Table, createMemoizedColumns } from '../../../components/TableComponents/ReactTable'

export interface OrderCust {
  Type?: string
  ReadOnlyLicences?: number
  ResourceLicences?: number
  SchedulerLicences?: number
}

export const CustomerContractTable = ({
  data,
  loading,
  salesforceId
}: {
  data?: ISalesforceAccountWithContracts
  loading: boolean
  salesforceId: string
}) => {
  const result = data?.result
  const activeContract: Order | undefined = result?.Orders.filter(order => order.OrderStatus === 'Active')[0]
  const notFoundError = `Account data with Salesforce ID: ${salesforceId} is not found.`
  const noActiveError = `Account with Salesforce ID: ${salesforceId} has no active contract.`

  const rows: OrderCust[] | undefined = activeContract
    ? [
        {
          Type: 'Current',
          ReadOnlyLicences: activeContract.CurrentReadOnlyLicences,
          ResourceLicences: activeContract.CurrentResourceLicences,
          SchedulerLicences: activeContract.CurrentSchedulerLicences
        },
        {
          Type: 'Total',
          ReadOnlyLicences: activeContract.TotalReadOnlyLicences,
          ResourceLicences: activeContract.TotalResourceLicences,
          SchedulerLicences: activeContract.TotalSchedulerLicences
        }
      ]
    : undefined

  const contractColumns = createMemoizedColumns<OrderCust>([
    {
      Header: ' ',
      accessor: (order: OrderCust) => order.Type
    },
    {
      Header: 'Read only licences',
      accessor: (order: OrderCust) => order.ReadOnlyLicences
    },
    {
      Header: 'Resource licences',
      accessor: (order: OrderCust) => order.ResourceLicences
    },
    {
      Header: 'Scheduler licences',
      accessor: (order: OrderCust) => order.SchedulerLicences
    }
  ])

  return (
    <>
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <h3>Contract</h3>
        </Grid.Column>
        {activeContract && (
          <Grid.Column textAlign="right">
            <Link
              to={`https://sked.lightning.force.com/lightning/r/Subscription_Contract__c/${activeContract?.Id}/view`}
              target="_blank"
            >
              View contract info in Salesforce <Icon name="external alternate"></Icon>
            </Link>
          </Grid.Column>
        )}
      </Grid>
      {activeContract || loading ? (
        <Table
          columns={contractColumns}
          data={rows ?? []}
          emptyMessage="No contract information found"
          color="blue"
          textAlign="left"
          loading={loading}
        ></Table>
      ) : (
        <Message error>{result ? noActiveError : notFoundError}</Message>
      )}
      <Divider />
    </>
  )
}
