import 'prismjs/themes/prism-solarizedlight.css'
import './index.css'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router'
import { App } from './App'
import { AuthStoreProvider } from './context/AuthContext'
import { initDataDogRum } from './analytics/instrumentation'
initDataDogRum()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <BrowserRouter>
    <AuthStoreProvider>
      <App />
    </AuthStoreProvider>
  </BrowserRouter>
)
