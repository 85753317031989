import { ReactNode } from 'react'

const FiltersTogglesButtons = (props: { children: ReactNode }) => <div className="ftb">{props.children}</div>

const Filters = (props: { children: ReactNode }) => <div className="ftb-filters">{props.children}</div>
FiltersTogglesButtons.Filters = Filters

const Buttons = (props: { children: ReactNode }) => <div className="ftb-buttons">{props.children}</div>
FiltersTogglesButtons.Buttons = Buttons

const Toggles = (props: { children: ReactNode }) => <div className="ftb-toggles">{props.children}</div>
FiltersTogglesButtons.Toggles = Toggles

export { FiltersTogglesButtons }
