import { adminApi } from './Endpoints'

export interface IApplication {
  config_version: string
  environment: string
  instance: string
  name: string
  meta: IApplicationMeta
  kind: 'Deployment' | 'Function'
}

interface IApplicationMeta {
  name: string
  tag: string
  ready?: number
  available?: number
  unavailable?: number
  desired?: number
  total?: number
  updated?: number
  memory?: number
  timeout?: number
  runtime?: string
}

export interface ContainerInfo {
  name: string
  status: string
  restartCount: number
  lastTerminationReason?: string
  lastTerminationTimestamp?: string
  image: string
  version: string
}

export interface PodInfo {
  name: string
  namespace: string
  application: string
  instance: string
  creationTimestamp: string
  primaryImage: string
  primaryVersion: string
  status: string
  configVersion?: string
  containers: ContainerInfo[]
  initContainers: ContainerInfo[]
}

export interface IApplicationRevision {
  configHash: string
  revisionId: string
  creationTimestamp: string
  name: string
  changeCause: string
  tag: string
}

export const emptyApplication = {
  config_hash: '',
  environment: '',
  instance: '',
  name: '',
  meta: {
    name: '',
    tag: '',
    ready: 0,
    available: 0,
    unavailable: 0,
    desired: 0,
    total: 0,
    updated: 0
  }
}

export const getApplications = async (): Promise<IApplication[]> => {
  const response = await adminApi.get<IApplication[]>('/application')
  return response.data
}

export const getApplication = async (name: string, environment: string, instance: string) => {
  const response = await adminApi.get<IApplication>(`/application/${environment}/${instance}/${name}`)
  return response.data
}

export const getFunction = async (name: string, environment: string, instance: string) => {
  const response = await adminApi.get<IApplication>(`/application/function/${environment}/${instance}/${name}`)
  return response.data
}

export const restartApplication = async (name: string, environment: string, instance: string) => {
  const response = await adminApi.post<IApplication>(`/application/${environment}/${instance}/${name}/restart`)
  return response.data
}

export const getRevisions = async (name: string, environment: string, instance: string) => {
  const response = await adminApi.get<IApplicationRevision[]>(
    `/application/${environment}/${instance}/${name}/revisions`
  )
  return response.data
}

export const rollbackApplication = async (name: string, environment: string, instance: string, revisionId: string) => {
  const response = await adminApi.post<IApplication>(
    `/application/${environment}/${instance}/${name}/rollback/${revisionId}`
  )
  return response.data
}

export const getPods = async (name: string, environment: string, instance: string) => {
  const response = await adminApi.get<PodInfo[]>(`/application/pods/${environment}/${instance}/${name}`)
  return response.data
}

export const getGlobalPods = async (name: string) => {
  const response = await adminApi.get<PodInfo[]>(`/application/pods/${name}`)
  return response.data
}

export const deletePod = async (name: string, environment: string, instance: string) => {
  const response = await adminApi.delete<boolean>(`/application/pods/${environment}/${instance}/${name}`)
  return response.data
}
