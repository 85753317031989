import { Icon, Segment } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useLocation } from 'react-router'
import dayjs from 'dayjs'
import * as React from 'react'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { TriggeredAction, TriggeredActionLog, TriggeredActionLogResponse } from '../../../actions/TriggeredActions'
import { Breadcrumb } from '../../../components/react/Breadcrumbs'
import { TriggeredActionActionType, TriggeredActionStatus, TriggeredActionTriggerType } from './TriggeredActions'

export const TriggeredActionLogs = (props: {
  name: string
  triggeredActionLogs: TriggeredActionLogResponse | undefined
  isLoading: boolean
  setTriggeredAction: React.Dispatch<React.SetStateAction<TriggeredAction | undefined>>
}) => {
  const location = useLocation()
  const tableColumns = createMemoizedColumns<TriggeredActionLog>([
    {
      Header: 'Started',
      accessor: 'started',
      Cell: ({ row }: { row: Row<TriggeredActionLog> }) => (
        <span>{dayjs(row.original.started).format('MMMM D YYYY, h:mm:ss a')}</span>
      )
    },
    {
      Header: 'Type',
      accessor: 'data',
      Cell: ({ row }: { row: Row<TriggeredActionLog> }) =>
        row.original.data.trigger_type ? (
          <TriggeredActionTriggerType value={row.original.data.trigger_type} />
        ) : (
          <TriggeredActionActionType value={row.original.data.action_type} />
        )
    },
    {
      Header: 'Completed',
      accessor: 'completed',
      Cell: ({ row }: { row: Row<TriggeredActionLog> }) => (
        <span>{row.original.completed && dayjs(row.original.completed).format('MMMM D YYYY, h:mm:ss a')}</span>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }: { row: Row<TriggeredActionLog> }) => <TriggeredActionStatus status={row.original.status} />
    },
    {
      Header: 'Retries',
      accessor: 'retry'
    }
  ])

  return (
    <Segment>
      <Breadcrumb>
        <Breadcrumb.Section
          href={`${location.pathname}${location.search}`}
          onClick={() => props.setTriggeredAction(undefined)}
        >
          <Icon name="chevron left" size="big" /> Back to triggered actions
        </Breadcrumb.Section>
      </Breadcrumb>

      <h3>{props.name} logs</h3>

      <Table
        ariaLabel="Triggered Action Logs"
        columns={tableColumns}
        data={props.triggeredActionLogs?.result ?? []}
        emptyMessage="No logs found."
        textAlign="left"
        color="blue"
        loading={props.isLoading}
      />
    </Segment>
  )
}
