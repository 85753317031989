import { useEffect, useState } from 'react'
import { Link } from 'react-router'
import { lowerCase, toUpper } from 'lodash'
import { User } from '@auth0/auth0-spa-js'
import { Dropdown, Icon, Image, Label } from 'semantic-ui-react'
import { useRouterProps } from '../../router/RouterProps'
import { OldModelWarning } from '../banners/OldModelWarning'
import { adminConsoleLogo, applicationManagementLogo } from '../../images/icons'
import { VerticalDivider } from '../../components/react/VerticalDivider'
import AuthClient from '../../auth/AuthClient'
import { HorizontalDivider } from '../../components/react/HorizontalDivider'
import { PageHeader } from '../../components/react/PageHeader'

export const getLogoForPath = (currentSite: string) =>
  currentSite === 'applications' ? applicationManagementLogo : adminConsoleLogo

export const getEnvLabelForHost = (hostname: string) => {
  switch (hostname) {
    case 'admin-console.skedulo.com':
      return <></>
    case 'staging-admin-console.test.skl.io':
      return (
        <Label id="env-label" color="blue">
          STAGING
        </Label>
      )
    default:
      return (
        <Label id="env-label" color="orange">
          TEST
        </Label>
      )
  }
}

export const getClassName = (currentSite: string, path: string): string => {
  switch (currentSite) {
    case 'applications':
    case 'old-model':
      return currentSite
    default:
      return path ? 'default border' : 'default'
  }
}

const menuItemsHome: string[] = ['customers', 'teams', 'feature-flag-report', 'users-report', 'packages']
const menuItemsConsole: string[] = ['applications', 'deployments', 'releases', 'deliveries', 'mobile-releases']
const menuItemsLegacy: string[] = ['customers', 'teams', 'tenants', 'feature-flag-report', 'users-report']

const menuItemLinkClassName = (path: string, activeItem: string) => (path === activeItem ? 'active item' : 'item')

const HeaderMenuItems = (props: { currentSite: string; activeItem: string }) => {
  const sentenceCase = (item: string) => toUpper(item.charAt(0)) + lowerCase(item.slice(1))
  const activeItem = props.activeItem
  switch (props.currentSite) {
    case 'old-model':
      return (
        <>
          {menuItemsLegacy.map(item => (
            <li key={item}>
              <Link className={menuItemLinkClassName(item, activeItem)} to={`/old-model/${item}`}>
                {sentenceCase(item)}
              </Link>
            </li>
          ))}
        </>
      )
    case 'applications':
      return (
        <>
          {menuItemsConsole.map(item => (
            <li key={item}>
              <Link className={menuItemLinkClassName(item, activeItem)} key={item} to={`/applications/${item}`}>
                {sentenceCase(item)}
              </Link>
            </li>
          ))}
        </>
      )
    default:
      return (
        <>
          {menuItemsHome.map(item => (
            <li key={item}>
              <Link className={menuItemLinkClassName(item, activeItem)} key={item} to={`/${item}`}>
                {sentenceCase(item)}
              </Link>
            </li>
          ))}
        </>
      )
  }
}

const Header = () => {
  const routerProps = useRouterProps()
  const pathname = routerProps.location.pathname.split('/')
  const currentSite = pathname[1] !== 'old-model' && pathname[1] !== 'applications' ? '' : pathname[1]

  const [activeItem, setActiveItem] = useState('')
  const [user, setUser] = useState(undefined as User | undefined)
  const userInitials = `${user?.given_name?.charAt(0)}${user?.family_name?.charAt(0)}`

  useEffect(() => {
    const getUser = async () => {
      const fetchedUser = await AuthClient.getUser()
      setUser(fetchedUser)
    }
    getUser()
  }, [])

  const logout = () => {
    // Only valid logout URLs in auth0 tenant are allowed.
    AuthClient.logout({ logoutParams: { returnTo: window.location.origin } })
  }

  useEffect(() => {
    if (currentSite === '') {
      setActiveItem(pathname[1])
    } else {
      setActiveItem(pathname[2])
    }
  }, [currentSite, pathname])

  const getSettingsItems = () => {
    const detailsItem = (
      <li>
        <Link to={(currentSite && '/') + currentSite + '/my-details'}>
          <Icon name="user" />
          My details
        </Link>
      </li>
    )
    const logoutItem = (
      <li onClick={() => logout()}>
        <Icon name="log out" />
        Log out
      </li>
    )
    const homeItem = (target: string) => (
      <li>
        <Link to="/" target={target}>
          <Icon name={target === '' ? 'exchange' : 'external alternate'} />
          Admin console home
        </Link>
      </li>
    )
    const appManagementItem = (
      <li>
        <Link to="/applications" target="_blank">
          <Icon name="external alternate" />
          Application management
        </Link>
      </li>
    )
    switch (currentSite) {
      case 'old-model':
        return (
          <ul>
            {detailsItem}
            <HorizontalDivider />
            {homeItem('')}
            <HorizontalDivider />
            {appManagementItem}
            <HorizontalDivider />
            {logoutItem}
          </ul>
        )
      case 'applications':
        return (
          <ul>
            {detailsItem}
            <HorizontalDivider />
            {homeItem('_blank')}
            <HorizontalDivider />
            {logoutItem}
          </ul>
        )
      default:
        return (
          <ul>
            {detailsItem}
            <HorizontalDivider />
            <li>
              <Link to="/old-model">
                <Icon name="exchange" />
                Legacy customer model
              </Link>
            </li>
            <HorizontalDivider />
            {appManagementItem}
            <HorizontalDivider />
            {logoutItem}
          </ul>
        )
    }
  }

  const className = getClassName(currentSite, pathname[1])
  const logo = getLogoForPath(currentSite)
  return (
    <>
      <PageHeader className={className}>
        <PageHeader.Logo>
          <Link to={'/' + currentSite}>
            <Image id="header-logo" inline src={logo.href} />
          </Link>
          {getEnvLabelForHost(window.location.hostname)}
        </PageHeader.Logo>
        <PageHeader.Links>
          <HeaderMenuItems currentSite={currentSite} activeItem={activeItem} />
        </PageHeader.Links>
        <PageHeader.User>
          {currentSite === 'applications' && (
            <>
              <Link className="settings" to="/applications/settings">
                <Icon data-testid="settings-button" id="header-settings" name="cog" />
              </Link>
              <VerticalDivider />
            </>
          )}

          <Dropdown
            id="header-info-menu"
            data-testid="log-out-dropdown"
            trigger={
              <Label circular id="user-avatar" style={{ color: 'white' }}>
                {userInitials}
              </Label>
            }
            className={`${activeItem === 'header-info-menu' && 'active'} item`}
            direction="left"
            icon={null}
          >
            <Dropdown.Menu>{getSettingsItems()}</Dropdown.Menu>
          </Dropdown>
        </PageHeader.User>
      </PageHeader>
      {currentSite === 'old-model' && <OldModelWarning />}
    </>
  )
}

export default Header
