import { Link } from 'react-router'
import { Button, Icon } from 'semantic-ui-react'
import { useQueryClient } from '@tanstack/react-query'
import { IApplication } from '../../actions/Applications'
import { DeployApplicationModal } from '../deployments/components/DeployApplicationModal'
import { useApplicationsQuery } from '../../queries/applications/GetApplicationsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useDeploymentModal } from '../../hooks/useDeploymentModal'
import { FilterCategoryOptions, Filters } from '../filterComponents/Filters'
import { FilterSearch } from '../filterComponents/FilterSearch'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { PageContent } from '../../components/react/PageContent'
import { ApplicationsList } from './components/ApplicationsList'

export const Applications = () => {
  const { data: applications, status: applicationsStatus } = useApplicationsQuery()
  useDocumentTitle('Applications')
  const queryClient = useQueryClient()

  const modal = useDeploymentModal()

  const filterCategories = new Map<string, FilterCategoryOptions>([
    ['name', { text: 'Application' }],
    ['environment', { text: 'Environment' }],
    ['instance', { text: 'Instance' }],
    ['tag', { text: 'Tag' }],
    ['config_hash', { text: 'Config' }]
  ])

  const handleRedeploy = (app: IApplication) => () => {
    modal.populateDeployment({
      application: app.name,
      env: app.environment,
      instance: app.instance,
      tag: app.meta.tag,
      config: '',
      check_mode: false
    })
    modal.setModalOpen(true)
  }

  if (applications) {
    filterCategories.set('name', { text: 'Application', values: applications.map(application => application.name) })
  }

  return (
    <PageContent>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Filters>
            <Icon
              size="large"
              name="refresh"
              onClick={() => queryClient.invalidateQueries(['applications'])}
              loading={applicationsStatus === 'loading'}
              className="clickable"
              aria-label="Refresh Applications"
            />
            <FilterSearch category="name" />
            <Filters filterCategories={filterCategories} />
          </FiltersTogglesButtons.Filters>
          <FiltersTogglesButtons.Buttons>
            <Button
              as={Link}
              to="/applications/applications/compare"
              content="Compare Environments"
              className="form-button-sked-blue"
              primary
            />
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>
        <ApplicationsList
          filterCategories={filterCategories}
          applications={applications}
          handleRedeploy={handleRedeploy}
          loading={applicationsStatus === 'loading'}
        />
        <DeployApplicationModal modal={modal} />
      </PageContent.Main>
    </PageContent>
  )
}
