import { useLocation, useNavigate } from 'react-router'
import { Button, Grid, Image } from 'semantic-ui-react'
import { useEffect } from 'react'
import hasAuthTokenInLocalStorage from '../auth/AuthChecker'
import { useAuthStore } from '../context/AuthContext'
import AuthClient from '../auth/AuthClient'
import Env from '../env/Env'
import { loginLogo } from '../images/icons'

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated: auth, setIsAuthenticated: setAuth } = useAuthStore()

  // catch if no longer authenticated and authstore has not been updated
  // can be replicated if you delete tokens from local storage
  useEffect(() => {
    const isTokenValid = hasAuthTokenInLocalStorage()
    setAuth(isTokenValid)
    if (isTokenValid) {
      navigate(location?.state?.referrer ?? '/')
    }
  }, [auth, navigate, setAuth, location?.state?.referrer])

  const handlelogin = (connectionName: string) => {
    AuthClient.loginWithRedirect({
      appState: {
        redirectTo: location?.state?.referrer ?? '/'
      },
      authorizationParams: {
        connection: connectionName
      }
    })
  }

  const login = () => {
    handlelogin('skedulo-com')
  }

  const loginTesting = () => {
    handlelogin('Username-Password-Authentication')
  }

  return (
    <div id="login-form">
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src={loginLogo.href} verticalAlign="middle" />
          <h1 color="grey" className="centered">
            Skedulo Admin Console
          </h1>
          <Button onClick={login} size="massive" color="blue" fluid={true} className="sked-blue">
            Log in
          </Button>
          {Env.AUTH0_CALLBACK === 'http://localhost:3000/callback' ? (
            <Button
              onClick={loginTesting}
              size="massive"
              color="blue"
              fluid={true}
              className="sked-blue"
              id="login-testing"
            >
              Login Testing
            </Button>
          ) : null}
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default Login
