import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { getFunction } from '../../actions/Applications'

export const useFunctionQuery = (appName: string, instance: string, env: string) =>
  useQuery({
    queryKey: ['function', appName, env, instance],
    queryFn: () => getFunction(appName, env, instance),
    onError: error => {
      if (isAxiosError(error) && error.response && error.response.status === 404) {
        createErrorToast(`No function found with the name: ${appName}. For ${env} ${instance}.`)
      } else {
        createErrorToast(error)
      }
    }
  })
