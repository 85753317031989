import validate from 'uuid-validate'
import { authService, authServiceConfig, elasticserver, elasticServerConfig } from './Endpoints'
import { IResult, Vendor, VendorClaim } from './Utils'

export interface INewTenant {
  vendor: string
  tenantId: string
  orgId: string
  teamId: string
  environment: string
  category: string
}

export interface ITeamTenant {
  tenantId: string
  vendor: VendorClaim
  orgId: string
  environment?: string
  category?: string
}

export interface ITenant extends ITeamTenant {
  metadata: IMetadata
  parentTenantId?: string
  teamId: string
  createdBy?: string
  updatedBy?: string
  createdDate: string
  updatedDate: string
}

export interface TenantWithNameAndRegion extends ITenant {
  region: string
  teamName: string
  apiUrl: string
}

export interface IPatchTenant {
  environment?: string
  category?: string
  metadata?: IMetadata
}

export interface ITenantWithTeamName extends ITenant {
  teamName: string
}

export interface IMetadata {
  [x: string]: IMetadataValueType
}

export type IMetadataValueType = string | number | IMetadata | boolean | IMetadataValueType[] | undefined

export type FeatureFlagType = 'Feature' | 'Package' | 'Retired'
export type FeatureFlagStatus =
  | 'GeneralAvailability'
  | 'Beta'
  | 'Alpha'
  | 'Development'
  | 'Internal'
  | 'Deprecated'
  | 'Retired'
export type Platform = 'Skedulo' | 'Salesforce' | 'All'
export interface IFeatureFlagWithDescription {
  name: string
  description: string
  type: FeatureFlagType
  value: boolean
  status: FeatureFlagStatus
  platform: Platform
  defaultValue: boolean
}

export interface IMultiTenantFeatureFlags {
  successes: {
    [tenantId: string]: IFeatureFlagWithDescription[]
  }
  failures: {
    [tenantId: string]: string
  }
}

export type IFeatureFlags = Map<string, boolean>

export interface ITask {
  taskId: number
}

export interface ICloneTaskStatus {
  type: string
  reason?: string
  result?: {
    tenantId: string
    cloned: {
      teamId: string
      tenantId: string
      name: string
    }
    files: {
      [x: string]: IClonedFileError | string[]
    }
  }
}

export interface IClonedFileError {
  error: {
    errorType: string
    message: string
    errorId?: string
  }
}

export interface IDeletedStatus {
  name: string
  files: {
    [x: string]: IDeletedType
  }
}

export interface IDeletedType {
  type: string
}

export interface IUserList {
  userId: string
  createdAt: string
  active: boolean
  permissionKeys: string[] | null
  maskedEmail: string
  roleNames: string[] | null
  lastLoginDate: string | null
  status: string | null
  licensedAs: string | null
}

export interface IUserSummary {
  tenantId: string
  totalActiveUsers: number | null
  totalActiveResources: number | null
  totalActiveSchedulers: number | null
  totalActiveUnknowns: number | null
}

export const getUserSummary = (tenantIds: string[], teamApi: string) =>
  authService
    .post<IResult<IUserSummary[]>>(
      '/users/internal/summary',
      {
        tenantIds
      },
      {
        ...authServiceConfig,
        baseURL: `${teamApi}/authorization`
      }
    )
    .then(response => response.data.result)

export const getUserList = (tenantId: string, teamApi: string, includeLicenseInactiveUsers?: boolean) =>
  authService
    .post<IResult<IUserList[]>>(
      `/users/internal/search/${tenantId}`,
      {
        licenseActive: !includeLicenseInactiveUsers
      },
      {
        ...authServiceConfig,
        baseURL: `${teamApi}/authorization`
      }
    )
    .then(response => response.data.result)

export const categoryOptions = ['Customer', 'Partner', 'Sales', 'Engineering', 'CX', 'Other', 'Internal']

export const environmentOptions = ['Production', 'Sandbox', 'Development', 'Demo', 'Test']

export const getTenants = (): Promise<ITenant[]> =>
  elasticserver.get<IResult<ITenant[]>>('/admin/provision/tenant').then(result => result.data.result)

export const getTenant = (id: string): Promise<ITenant> =>
  elasticserver.get<IResult<ITenant>>(`/admin/provision/tenant/${id}`).then(result => result.data.result)

export const updateTenant = (id: string, patchTenant: IPatchTenant) =>
  elasticserver.put(`/admin/provision/tenant/${id}`, patchTenant)

export const addExistingTenant = (newTenant: INewTenant): Promise<string> =>
  elasticserver.post<IResult<string>>('/admin/provision/tenant', newTenant).then(result => result.data.result)

export const removeTenant = (teamId: string, tenantId: string): Promise<void> =>
  elasticserver.delete(`/admin/provision/team/${teamId}/tenant/${tenantId}`).then(response => response.data)

export const deleteTenantAndTeam = (tenantId: string): Promise<IDeletedStatus> =>
  elasticserver
    .post<IDeletedStatus>('/onboarding/standalone/remove_cloned_team', { tenantId })
    .then(response => response.data)

export const getTenantFeatures = (tenantId: string, api: string): Promise<IFeatureFlagWithDescription[]> =>
  elasticserver
    .get<IResult<IFeatureFlagWithDescription[]>>(`/config/features/descriptions/${tenantId}`, {
      ...elasticServerConfig,
      baseURL: api
    })
    .then(features => features.data.result)

export const getMultipleTenantFeatures = (tenantIds: string[], api: string): Promise<IMultiTenantFeatureFlags> =>
  elasticserver
    .post<IResult<IMultiTenantFeatureFlags>>('/config/features/descriptions/tenants/search', tenantIds, {
      ...elasticServerConfig,
      baseURL: api
    })
    .then(features => features.data.result)

export const setTenantFeatures = (
  tenantId: string,
  featuresPatch: IFeatureFlags,
  api: string
): Promise<IFeatureFlags> =>
  elasticserver
    .post<IResult<IFeatureFlags>>(`/config/features/${tenantId}`, Object.fromEntries(featuresPatch), {
      ...elasticServerConfig,
      baseURL: api
    })
    .then(features => features.data.result)

export const cloneTenant = (tenantId: string): Promise<ITask> =>
  elasticserver
    .post<IResult<ITask>>('/onboarding/standalone/clone_team_async', { tenantId })
    .then(result => result.data.result)

export const getCloneTaskStatus = (taskId: number): Promise<ICloneTaskStatus> =>
  elasticserver
    .get<IResult<ICloneTaskStatus>>(`/onboarding/standalone/clone_team_async/status/${taskId}`)
    .then(result => result.data.result)

export const validateSalesforceOrgId = (orgId?: string): boolean => /^[a-zA-Z0-9]{18}$/.test(orgId || '')

const toUuid = (orgId: string): string => {
  const c1 = orgId.slice(0, 8)
  const c2 = orgId.slice(8, 12)
  const c3 = orgId.slice(12, 16)
  const c4 = orgId.slice(16, 20)
  const c5 = orgId.slice(20, 32)
  return `${c1}-${c2}-${c3}-${c4}-${c5}`
}

const validateUuid = (uuid: string): boolean => /^[0-9a-f]{32}$/.test(uuid) && validate(toUuid(uuid), 4)

const validateSkeduloOrgId = (orgId: string): boolean => validateUuid(orgId)

export const validateTenantId = (vendor: Vendor, tenantId: string): boolean => {
  switch (vendor) {
    case 'salesforce':
      return validateSalesforceOrgId(tenantId)
    case 'skedulo':
      return validateSkeduloOrgId(tenantId)
    case '':
      return tenantId === ''
    default:
      return false
  }
}
