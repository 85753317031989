import { Button, Icon, Popup, Table } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router'
import { useCopyToClipboard } from 'usehooks-ts'
import { IDeployment } from '../../../actions/Deployments'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { DeploymentModal } from '../../../hooks/useDeploymentModal'
import { FilterCategories, filtersMatchAny, searchParamsToFilters } from '../../filterComponents/Filters'
import { createSuccessToast } from '../../alertComponents/Alert'
import { DatadogAPMButton } from '../../../components/DatadogButtons'
import { DeploymentStatusIcon } from './DeploymentStatusIcon'
import { DatadogApplicationLogsButton } from './DatadogButtons'

export const DeploymentsList = (props: {
  loading: boolean
  deployments?: IDeployment[]
  filterCategories: FilterCategories
  modal?: DeploymentModal
  displayLocalTime: boolean
  showConfigVersion?: boolean
  showTriggeredBy?: boolean
  hideApplication?: boolean
  hideEnvironment?: boolean
  hideInstance?: boolean
}) => {
  const [sortColumn, setSortColumn] = useState('timestamp')
  const [sortAscending, setSortAscending] = useState(false)
  const { filterCategories, deployments } = props
  const [searchParams] = useSearchParams()

  const [_, actuallyCopyToClipboard] = useCopyToClipboard()

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn)
      setSortAscending(true)
    } else {
      setSortAscending((prevState: boolean) => !prevState)
    }
  }

  const sortDirection = () => (sortAscending ? 'ascending' : 'descending')

  const SortedDeploymentHeaderCell = SortedHeaderCell(sortColumn, sortDirection(), handleSort)

  const filterDeployments = (): IDeployment[] => {
    if (!deployments || deployments.length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = deployments
    const groups = Object.groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      result = result.filter(deployment =>
        filtersMatchAny(categoryFilters!, deployment[category as keyof IDeployment] as string, false)
      )
    }
    return result
  }

  const sortedDeployments = sortData(filterDeployments(), sortColumn, sortDirection())

  const openModalWith = (row: IDeployment) => {
    props.modal && props.modal.populateDeployment(row)
    props.modal && props.modal.setModalOpen(true)
  }

  const copyToClipboard = async (value: string) => {
    await actuallyCopyToClipboard(value)
    createSuccessToast(`${value} copied to clipboard`)
  }

  return (
    <div className="scrollable">
      <Table
        striped
        color="blue"
        size="small"
        stackable
        sortable
        className={props.hideApplication ? undefined : 'sticky-table'}
        aria-label={props.hideApplication ? 'Application Deployments' : 'Deployments'}
      >
        <Table.Header>
          <Table.Row>
            <SortedDeploymentHeaderCell title="Timestamp" />
            {!props.hideApplication && <SortedDeploymentHeaderCell title="Application" />}
            {!props.hideEnvironment && <SortedDeploymentHeaderCell title="Environment" name="env" />}
            {!props.hideInstance && <SortedDeploymentHeaderCell title="Instance" />}
            <SortedDeploymentHeaderCell title="Tag" />
            {props.showConfigVersion && <SortedDeploymentHeaderCell title="Config Version" />}
            {props.showTriggeredBy && <SortedDeploymentHeaderCell title="Triggered By" />}
            <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
            {props.modal && (
              <Table.HeaderCell textAlign="center" style={{ width: '110px' }}>
                <Popup
                  trigger={
                    <div>
                      {' '}
                      Redeploy <Icon name="question circle" />{' '}
                    </div>
                  }
                  content="Opens deployment form with current deployment pre-filled. Redeploying same version may change config."
                  basic
                />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Logs</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Dashboard</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={props.loading} array={sortedDeployments} emptyMessage="No Recent Deployments">
          <Table.Body>
            {sortedDeployments.map(row => (
              <Table.Row key={row.id}>
                <Table.Cell className="table-cell-overflow">
                  {(props.displayLocalTime && row.timestamp && dayjs(row.timestamp).format('MMMM D YYYY, h:mm:ss a')) ||
                    row.timestamp}
                </Table.Cell>
                {!props.hideApplication && <Table.Cell>{row.application}</Table.Cell>}
                {!props.hideEnvironment && <Table.Cell>{row.env}</Table.Cell>}
                {!props.hideInstance && <Table.Cell>{row.instance}</Table.Cell>}
                <Table.Cell className="table-cell-overflow">{row.tag}</Table.Cell>
                {props.showConfigVersion && (
                  <Table.Cell className="table-cell-overflow">
                    {
                      <Popup
                        content={row.config}
                        on={['hover']}
                        trigger={
                          <span
                            onClick={async event => {
                              event.stopPropagation()
                              await copyToClipboard(row.config)
                            }}
                          >
                            {row.config.substring(0, 8)}
                          </span>
                        }
                      />
                    }
                  </Table.Cell>
                )}
                {props.showTriggeredBy && <Table.Cell>{row.user}</Table.Cell>}
                <Table.Cell textAlign="center">
                  <DeploymentStatusIcon status={row.status} checkMode={row.check_mode} explanation={row.explanation} />
                </Table.Cell>
                {props.modal && (
                  <Table.Cell textAlign="center">
                    <Button circular icon="redo" className="circle-icon-button" onClick={() => openModalWith(row)} />
                  </Table.Cell>
                )}
                <Table.Cell textAlign="center">
                  <Link to={`/applications/deployments/${row.env}/${row.instance}/${row.id}`}>
                    <Button icon="file alternate outline" className="square-icon-button" />
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <DatadogApplicationLogsButton
                    application={row.application}
                    env={row.env}
                    instance={row.instance}
                    startTime={dayjs(row.timestamp).valueOf() - 15 * 60 * 1000}
                    endTime={dayjs(row.timestamp).valueOf() + 45 * 60 * 1000}
                  />
                </Table.Cell>
                <Table.Cell>
                  <DatadogAPMButton
                    application={row.application}
                    env={row.env}
                    instance={row.instance}
                    startTime={dayjs(row.timestamp).valueOf() - 15 * 60 * 1000}
                    endTime={dayjs(row.timestamp).valueOf() + 45 * 60 * 1000}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    </div>
  )
}
