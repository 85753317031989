import { Button, Checkbox, Grid } from 'semantic-ui-react'
import { CSVLink } from 'react-csv'
import { useEffect, useMemo, useState } from 'react'
import { groupBy } from 'lodash'
import { useSearchParams } from 'react-router'
import { useDebounce } from 'usehooks-ts'
import { useSearchFilterStore } from '../../../../context/SearchFilterStoreContext'
import { filterData, sortData } from '../../../../views/tableComponents/TableFunctions'
import { IUserList } from '../../../../actions/Tenants'
import { VendorClaim } from '../../../../actions/Utils'
import { formatDate } from '../../../../utils/dateUtils'
import { FilterSearch } from '../../../../views/filterComponents/FilterSearch'
import {
  FilterCategoryOptions,
  Filters,
  filtersMatchAny,
  searchParamsToFilters
} from '../../../../views/filterComponents/Filters'
import { FiltersTogglesButtons } from '../../../../components/react/FiltersTogglesButtons'
import { useUserSummaryService, useUserListService, salesforceWarning } from './UsersService'
import { UserList } from './UserList'
import { UserCounts } from './UserCounts'

export interface IUserListFormatted {
  UserID: string
  CreatedDate: string
  CreatedDateISO: string
  Email: string
  Roles: string | null
  LastLoginDate?: string | null
  LastLoginDateISO?: string | null
  Status?: string | null
  LicensedAs: string | null
}

const DATE_TIME_FORMAT = 'MMM D YYYY, h:mm:ssa'

export const getFormattedUserList = (data: IUserList[] | undefined, isSalesforce: boolean): IUserListFormatted[] =>
  data?.map(user => {
    const formattedUserWithoutStatus = {
      UserID: user.userId,
      CreatedDate: formatDate(user.createdAt, DATE_TIME_FORMAT),
      CreatedDateISO: user.createdAt,
      Email: user.maskedEmail,
      Roles: user.roleNames ? user.roleNames.join(', ') : '',
      LicensedAs: user.licensedAs
    }
    return isSalesforce
      ? formattedUserWithoutStatus
      : {
          ...formattedUserWithoutStatus,
          LastLoginDate: user.lastLoginDate ? formatDate(user.lastLoginDate, DATE_TIME_FORMAT) : null,
          LastLoginDateISO: user.lastLoginDate,
          Status: user.status
        }
  }) ?? []

export const Users = ({
  tenantId,
  teamApi,
  vendor,
  metadata,
  teamId
}: {
  tenantId?: string
  teamApi?: string
  vendor?: VendorClaim
  metadata?: Record<string, any>
  teamId?: string
}) => {
  const isSalesforce = vendor === VendorClaim.Salesforce
  const [searchParams] = useSearchParams()
  // Search filters
  const { usersSearchFilter, setUsersSearchFilter } = useSearchFilterStore()
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValues = useDebounce(searchValue, 500)

  // Data gathering
  const userListService = useUserListService(tenantId, teamApi, usersSearchFilter.includeLicenceInactiveUsers)
  const userSummaryService = useUserSummaryService(tenantId ? [tenantId] : undefined, teamApi)

  // Filter handling
  const filterUsers = (): IUserListFormatted[] => {
    if (!userListService?.data || userListService.data?.length === 0) {
      return []
    }
    const filters = searchParamsToFilters(searchParams, filterCategories)
    let result = getFormattedUserList(userListService.data, isSalesforce)
    const groups = groupBy(filters, filter => filter.category)
    for (const [category, categoryFilters] of Object.entries(groups)) {
      if (category !== 'keyword') {
        result = result.filter(p => {
          const value = p[category as keyof IUserListFormatted]?.toString()
          return filtersMatchAny(categoryFilters, value?.length ? value : 'None', false)
        })
      } else {
        result = filterData(
          {
            searchValue: debouncedSearchValues,
            searchCategory: 'all'
          },
          result
        )
      }
    }
    return result
  }

  const filterCategories = useMemo(() => {
    const categories = new Map<string, FilterCategoryOptions>([
      ['keyword', { text: 'Keyword', hideExactMatch: true }],
      ['UserID', { text: 'User ID' }],
      ['Email', { text: 'Email' }],
      ['LicensedAs', { text: 'Licenced as', values: ['Scheduler', 'Other', 'Resource', 'None'] }],
      ['Roles', { text: 'Roles', values: ['Administrator', 'Scheduler', 'Resource', 'None'] }]
    ])

    if (!isSalesforce) {
      categories.set('Status', { text: 'User status', values: ['Created', 'Invited', 'Active', 'Deactivated'] })
    }

    return categories
  }, [vendor])

  useEffect(() => {
    const filters = searchParamsToFilters(searchParams, filterCategories)
    const keyword = filters.filter(f => f.category === 'keyword')?.[0]?.value ?? ''
    setSearchValue(keyword)
  }, [filterCategories, searchParams])

  // Sort handling
  const [sort, setSort] = useState<{
    column: string
    direction: 'ascending' | 'descending'
  }>({
    column: 'CreatedDateISO',
    direction: 'descending'
  })
  const handleSort = (column: string) => () => {
    const flipDirection = sort.direction === 'ascending' ? 'descending' : 'ascending'
    const direction = sort.column === column ? flipDirection : 'ascending'
    setSort({ column, direction })
  }
  const sortedUsers = sortData(filterUsers() ?? [], sort.column, sort.direction)

  return (
    <div data-testid="users">
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <h3>Users</h3>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <CSVLink data={sortedUsers} filename={'user_list_' + tenantId}>
            <Button className={'form-button-sked-blue'} content={'Export to CSV'} />
          </CSVLink>
        </Grid.Column>
      </Grid>
      {salesforceWarning(false, vendor)}
      {userSummaryService.data && userSummaryService.data.length > 0 && (
        <UserCounts
          data={userSummaryService.data}
          loading={userSummaryService.isFetching}
          hideLimitForTeam={isSalesforce || !teamId}
          metadata={metadata}
          teamId={teamId}
        />
      )}
      <FiltersTogglesButtons>
        <FiltersTogglesButtons.Filters>
          <FilterSearch
            category="keyword"
            inputProps={{ icon: 'search', iconPosition: 'left' }}
            placeholder="Search users"
          />
          <Filters filterCategories={filterCategories} />
        </FiltersTogglesButtons.Filters>
        <FiltersTogglesButtons.Toggles>
          <Checkbox
            label="Include non-licensed users"
            toggle
            checked={usersSearchFilter.includeLicenceInactiveUsers}
            onClick={() =>
              setUsersSearchFilter({
                ...usersSearchFilter,
                includeLicenceInactiveUsers: !usersSearchFilter.includeLicenceInactiveUsers
              })
            }
          />
        </FiltersTogglesButtons.Toggles>
      </FiltersTogglesButtons>
      <UserList
        sort={sort}
        handleSort={handleSort}
        data={sortedUsers}
        loading={userListService.isFetching}
        isSalesforce={isSalesforce}
      />
    </div>
  )
}
