import * as React from 'react'
import { DropdownProps, Form, Input, InputOnChangeData, Label, Segment } from 'semantic-ui-react'
import { addNewConnection, IDetailedAuthConection, INewConnection, ITeam } from '../../../actions/Teams'
import { createErrorToast } from '../../alertComponents/Alert'

interface IProps {
  updateTeam(): void
  toggleForm(): void
  teamId: string
  auth0Connections: IDetailedAuthConection[]
}

type Vendor = 'salesforce' | 'salesforce-community'

interface IState {
  vendor: Vendor | ''
  endpoint: string
  connectionName: string
  displayName: string
  submitting: boolean
}

const vendorOptions = [
  { key: 'sf', text: 'Salesforce', value: 'salesforce', prefix: 'sf-' },
  { key: 'sfc', text: 'Salesforce Community', value: 'salesforce-community', prefix: 'sfc-' }
]

export class NewConnectionForm extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      vendor: 'salesforce',
      endpoint: '',
      connectionName: '',
      displayName: '',
      submitting: false
    }
  }

  resetForm = () => {
    this.setState({
      vendor: 'salesforce',
      endpoint: '',
      connectionName: '',
      displayName: '',
      submitting: false
    })
  }

  getVendorTeamPrefix(): string | undefined {
    const result = vendorOptions.find(vendor => vendor.value === this.state.vendor)
    return result !== undefined ? result.prefix : undefined
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps): void => {
    const name = data.name
    const value = data.value
    // The ...this.state is required due to a @types/react bug should be removed when this is fixed.
    this.setState({ ...this.state, [name]: value })
  }

  onChangeLowerCase = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void => {
    this.onChange(event, {
      ...data,
      value: data.value.toLowerCase()
    })
  }

  validateConnectionName(): boolean {
    return (
      /^\S*$/.test(this.state.connectionName) &&
      !this.props.auth0Connections.some(
        connection => connection.name === `${this.getVendorTeamPrefix()}${this.state.connectionName}`
      )
    )
  }

  validateEndpoint(): boolean {
    const { vendor, endpoint } = this.state
    if (endpoint === '' || vendor === '') {
      return true
    }
    if (vendor === 'salesforce' && /^[a-z][a-z.0-9-]{0,61}[a-z0-9]$/.test(endpoint)) {
      return true
    }
    // NB: This is just ensuring that they don't type https at the start
    if (vendor === 'salesforce-community' && /^[a-z][a-z.0-9-]{0,61}[a-z0-9]\./.test(endpoint)) {
      return true
    }
    return false
  }

  validateSubmit(): boolean {
    return (
      this.validateEndpoint() &&
      this.validateConnectionName() &&
      this.state.vendor !== '' &&
      this.state.endpoint !== '' &&
      this.state.connectionName !== ''
    )
  }

  addConnection(): Promise<ITeam> {
    this.setState({ submitting: true })
    const { vendor, connectionName, endpoint, displayName } = this.state
    const newConnection: INewConnection = {
      displayName,
      vendor,
      instance: endpoint,
      name: connectionName,
      teamId: this.props.teamId
    }
    return addNewConnection(this.props.teamId, newConnection).then()
  }

  handleAddConnection = () => {
    this.addConnection()
      .then(() => {
        this.props.updateTeam()
        this.resetForm()
        this.props.toggleForm()
      })
      .catch(error => {
        this.setState({ submitting: false })
        createErrorToast(error)
      })
  }

  render() {
    const { connectionName, endpoint, submitting, vendor } = this.state
    return (
      <Segment color="blue">
        <h3 className="centered">New Login Option</h3>
        <Form onSubmit={this.handleAddConnection} loading={submitting}>
          <Form.Input
            required
            name="displayName"
            autoFocus
            label="Login Button Text"
            fluid
            placeholder="The name that appears on the login button"
            onChange={this.onChange}
          />
          <Form.Select
            name="vendor"
            label="Vendor"
            placeholder="Select vendor"
            value={vendor}
            options={vendorOptions}
            onChange={this.onChange}
            required
          />
          <Form.Field required error={!this.validateEndpoint()}>
            <label>Endpoint</label>
            <Input
              name="endpoint"
              placeholder={'Enter endpoint'}
              type="text"
              onChange={this.onChangeLowerCase}
              value={endpoint}
              required
              label="https"
            />
            {!this.validateEndpoint() && (
              <Label pointing color="red" name="endpoint error label">
                Must contain 2-63 characters: alphanumeric, &apos;.&apos; or &apos;-&apos;. Must start with a letter and
                be a valid url.
              </Label>
            )}
          </Form.Field>
          <Form.Field required error={!this.validateConnectionName()}>
            <label>Connection Name</label>
            <Input
              name="connectionName"
              fluid
              placeholder="Enter a new connection name"
              label={this.getVendorTeamPrefix()}
              value={connectionName}
              onChange={this.onChange}
              required
            />
            {!this.validateConnectionName() && (
              <Label pointing color="red" name="connection name error label">
                Name already used in this team.
              </Label>
            )}
          </Form.Field>
          <Form.Button
            type="submit"
            className="sked-blue-button"
            content="Submit"
            fluid
            disabled={!this.validateSubmit()}
          />
        </Form>
      </Segment>
    )
  }
}
