import { Icon } from 'semantic-ui-react'
import { useDocumentTitle } from 'usehooks-ts'
import { useQueryClient } from '@tanstack/react-query'
import { SimpleSearch, useSimpleSearch } from '../searchComponents/SimpleSearch'
import { useReleasesQuery } from '../../queries/releases/GetReleaseQuery'
import { useReleaseApplicationsQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { PageContent } from '../../components/react/PageContent'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { CutReleaseModal } from './Components/CutReleaseModal'
import { ReleasesList } from './Components/ReleasesList'

const title = 'Releases'

export const Releases = () => {
  const { data: applications } = useReleaseApplicationsQuery()
  const { data: releases } = useReleasesQuery()
  const queryClient = useQueryClient()
  useDocumentTitle(title)

  const createDetailsLink = (applicationName: string, version: string) =>
    `/applications/releases/${applicationName}/${version}`

  const searchOptions = [
    { text: 'All', value: 'all' },
    { text: 'Version', value: 'version' },
    { text: 'Application', value: 'applicationName' }
  ]
  const simpleSearch = useSimpleSearch(searchOptions)

  return (
    <PageContent>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Filters>
            <Icon
              size="large"
              name="refresh"
              onClick={queryClient.invalidateQueries(['releases'])}
              loading={releases === undefined || applications === undefined}
              className="clickable"
            />

            <SimpleSearch simpleSearch={simpleSearch} />
          </FiltersTogglesButtons.Filters>
          <FiltersTogglesButtons.Buttons>
            <CutReleaseModal applications={applications || []} />
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>
        <ReleasesList
          releases={releases}
          loading={releases === undefined || applications === undefined}
          searchValue={simpleSearch}
          createDetailsLink={createDetailsLink}
        />
      </PageContent.Main>
    </PageContent>
  )
}
