import { Row } from 'react-table'
import { Button, Label, Popup } from 'semantic-ui-react'
import { createMemoizedColumns, createMemoizedData, Table } from '../../../components/TableComponents/ReactTable'
import { TeamNamesData, useTeamNamesService } from '../services/TeamNamesService'
import { NewDeleteModal } from '../../../views/modalComponents/NewDeleteModal'

interface TeamNamesListProps {
  teamId: string
  customerId: string
}

const TeamNamesList = ({ teamId, customerId }: TeamNamesListProps) => {
  const { data, primaryName, isLoading, isDeleting, deleteTeamName } = useTeamNamesService({ teamId })

  /**
   * Memoize table data with primary name as first entry.
   */
  const tableData = createMemoizedData<TeamNamesData>(
    data
      ?.map(name => ({
        teamName: name
      }))
      .sort((a, b) => (a.teamName === primaryName ? -1 : a.teamName.localeCompare(b.teamName))) ?? [],
    [data, primaryName]
  )

  const tableColumns = createMemoizedColumns<TeamNamesData>(
    [
      {
        Header: 'Team Name',
        accessor: 'teamName',
        Cell: ({ row }: { row: Row<TeamNamesData> }) =>
          row.values.teamName === primaryName ? (
            <div>
              {row.values.teamName}
              <span style={{ marginLeft: '0.5rem' }}>
                <Label color="blue" horizontal>
                  Current
                </Label>
              </span>
            </div>
          ) : (
            <span>{row.values.teamName}</span>
          )
      },
      {
        Header: 'Remove',
        Cell: ({ row }: { row: Row<TeamNamesData> }) =>
          row.values.teamName === primaryName ? (
            <Popup
              content="Current team name cannot be removed."
              position="top center"
              trigger={
                <div>
                  <Button icon="delete" color="red" disabled />
                </div>
              }
              style={{
                opacity: 0.9,
                padding: '1rem',
                textAlign: 'center'
              }}
              inverted
            />
          ) : (
            <NewDeleteModal
              deleteMethod={() => deleteTeamName(row.values.teamName)}
              type="Team Name"
              content={`Are you sure you want to delete the team name: ${row.values.teamName}?`}
              isDeleting={isDeleting}
              disabled={isDeleting}
            />
          )
      }
    ],
    [primaryName, isDeleting]
  )

  return (
    <div data-testid="team-names">
      <h3>Team Names</h3>
      <Table<TeamNamesData>
        columns={tableColumns}
        data={tableData}
        loading={isLoading}
        emptyMessage="No team names found."
        color="blue"
        celled
      />
    </div>
  )
}

export default TeamNamesList
