import * as React from 'react'
import { Navigate, useLocation } from 'react-router'
import { SemanticToastContainer } from 'react-semantic-toasts'
import { Dimmer, Loader } from 'semantic-ui-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SearchFilterStoreProvider } from './context/SearchFilterStoreContext'
import { hasAuthTokenInLocalStorage } from './auth/AuthChecker'
import AuthClient from './auth/AuthClient'
import { useAuthStore } from './context/AuthContext'
import { logError } from './analytics/error-log'
import { logDataDogUserInfo } from './analytics/instrumentation'
import { AppRoutes } from './router/AppRoutes'
import Header from './views/header/Header'

export const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation()

  if (hasAuthTokenInLocalStorage()) {
    logDataDogUserInfo()
    return children
  } else {
    return <Navigate to="/login" state={{ referrer: `${location.pathname}${location.search}` }} />
  }
}

export const Spinner = () => (
  <Dimmer active={true} inverted={true}>
    <Loader />
  </Dimmer>
)

/**
 * Default configurations for React Query Client:
 * - Query cache time: 10 mins
 * - Query stale time: 1 min
 * - Query retries on error: 1 retry
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60,
      retry: 1
    }
  }
})

export const App = () => {
  const { isAuthenticated: auth } = useAuthStore()

  /**
   * Fetch and record access token for endpoints. When user logs in successfuly, access token is cached
   * and `getTokenSilently` will retrieve the valid cached token which may be refreshed.
   * Addendum: This hook is needed in addition to the custom redirect component used by class components to handle token fetching through Auth0
   * irregardless if the entry point was a class/functional component and to handle fetching access/refresh tokens silently.
   */
  React.useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        await AuthClient.getTokenSilently()
      } catch (e) {
        logError(e)
        await AuthClient.logout({
          logoutParams: { returnTo: window.location.origin }
        })
      }
    }

    if (auth) {
      fetchAccessToken()
    }
  }, [auth])

  return (
    <div id="main">
      <QueryClientProvider client={queryClient}>
        <SearchFilterStoreProvider>
          <SemanticToastContainer className="alert-toast" position="top-right" />
          {auth ? <Header /> : <></>}
          <ReactQueryDevtools />
          <AppRoutes />
        </SearchFilterStoreProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
