import { Button, Checkbox, Icon, Segment } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useState } from 'react'
import dayjs from 'dayjs'
import { TriggeredAction } from '../../../actions/TriggeredActions'
import { createMemoizedColumns, MemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { FiltersTogglesButtons } from '../../../components/react/FiltersTogglesButtons'
import { SortableHeaderCell } from '../../../components/TableComponents/BaseTableComponents/SortableHeaderCell'
import { sortData } from '../../tableComponents/TableFunctions'
import { TriggeredActionActionType, TriggeredActionStatus, TriggeredActionTriggerType } from './TriggeredActions'

const TriggeredActionTickCross = (props: { value: boolean }) =>
  props.value ? (
    <Icon name="check circle" size="large" color="green" />
  ) : (
    <Icon name="minus circle" color="blue" size="large" />
  )

export const TriggeredActionsList = (props: {
  triggeredActions: TriggeredAction[] | undefined
  isLoading: boolean
  setTriggeredAction: React.Dispatch<React.SetStateAction<TriggeredAction | undefined>>
}) => {
  const [enabledOnly, setEnabledOnly] = useState(false)
  const [{ column: sortColumn, direction: sortDirection }, setSort] = useState<{
    column: string
    direction: 'ascending' | 'descending'
  }>({
    column: 'name',
    direction: 'ascending'
  })

  const handleSort = (column: string) => () => {
    const flipDirection = sortDirection === 'ascending' ? 'descending' : 'ascending'
    const direction = sortColumn === column ? flipDirection : 'ascending'
    setSort({ column, direction })
  }

  const filteredTriggeredActions = enabledOnly
    ? props.triggeredActions?.filter(ta => ta.enabled)
    : props.triggeredActions

  const SortableTriggeredActionHeaderCell = SortableHeaderCell(sortColumn, sortDirection, handleSort)

  const sortTriggeredActions = () => sortData(filteredTriggeredActions ?? [], sortColumn, sortDirection)

  const tableColumns = createMemoizedColumns<TriggeredAction>(
    [
      {
        Header: <SortableTriggeredActionHeaderCell title="Name" name="name" />,
        accessor: 'name'
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }: { row: Row<TriggeredAction> }) => <TriggeredActionActionType value={row.original.action} />
      },
      {
        Header: 'Trigger',
        accessor: 'trigger',
        Cell: ({ row }: { row: Row<TriggeredAction> }) => <TriggeredActionTriggerType value={row.original.trigger} />
      },
      {
        Header: 'Deferred',
        accessor: 'deferred',
        Cell: ({ row }: { row: Row<TriggeredAction> }) => <TriggeredActionTickCross value={row.original.enabled} />
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        Cell: ({ row }: { row: Row<TriggeredAction> }) => <TriggeredActionTickCross value={row.original.enabled} />
      },
      {
        Header: <SortableTriggeredActionHeaderCell title="Last executed" name="lastExecuted" />,
        accessor: 'lastExecuted',
        Cell: ({ row }: { row: Row<TriggeredAction> }) => (
          <span>{row.original.lastExecuted && dayjs(row.original.lastExecuted).format('MMMM D YYYY, h:mm:ss a')}</span>
        )
      },
      {
        Header: 'Last execution status',
        accessor: 'lastExecutionStatus',
        Cell: ({ row }: { row: Row<TriggeredAction> }) => (
          <TriggeredActionStatus status={row.original.lastExecutionStatus} />
        )
      },
      {
        Header: 'Logs',
        accessor: triggeredAction => triggeredAction.id,
        Cell: ({ row }: { row: Row<TriggeredAction> }) =>
          row.original.lastExecuted ? (
            <Button
              icon="file alternate outline"
              className="square-icon-button"
              onClick={() => props.setTriggeredAction(row.original)}
            />
          ) : (
            <></>
          )
      }
    ],
    [sortColumn, sortDirection]
  )

  return (
    <Segment>
      <h3>Triggered Actions</h3>
      <FiltersTogglesButtons>
        <FiltersTogglesButtons.Toggles>
          <Checkbox
            label={<label htmlFor="showEnabledOnly">Show enabled only</label>}
            id="showEnabledOnly"
            toggle
            checked={enabledOnly}
            onChange={(_, data) => {
              setEnabledOnly(!!data.checked)
            }}
          />
        </FiltersTogglesButtons.Toggles>
      </FiltersTogglesButtons>

      <Table
        ariaLabel="Triggered Actions"
        columns={tableColumns as MemoizedColumns<any>}
        data={sortTriggeredActions()}
        emptyMessage="No triggered actions found."
        textAlign="left"
        color="blue"
        loading={props.isLoading}
      />
    </Segment>
  )
}
