import * as React from 'react'
import { Button, DropdownProps, Grid, Icon, InputOnChangeData, SearchProps } from 'semantic-ui-react'
import { Link } from 'react-router'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getTenants, ITenant } from '../../actions/Tenants'
import { createErrorToast } from '../alertComponents/Alert'
import { SearchInput } from '../searchComponents/SearchInput'
import { IViewSearchProps } from '../Utils'
import { TenantsList } from './components/TenantsList'

interface IState {
  tenants: ITenant[]
  loading: boolean
}

const title = 'Tenants'

export class Tenants extends React.PureComponent<IViewSearchProps, IState> {
  constructor(props: IViewSearchProps) {
    super(props)
    this.state = {
      tenants: [],
      loading: false
    }
  }

  async componentDidMount() {
    await this.fetchTenants()
    document.title = formatDocumentTitle(title)
  }

  fetchTenants = async () => {
    try {
      this.setState({ loading: true })
      const tenants = await getTenants()
      this.setState({ tenants })
    } catch (error) {
      createErrorToast(error)
    } finally {
      this.setState({ loading: false })
    }
  }

  handleSearchChange: SearchProps['onChange'] = (event: React.ChangeEvent, data: InputOnChangeData) => {
    const newSearch = { searchValue: data.value, searchCategory: this.props.searchValue.searchCategory }
    this.props.handleSearchChange(newSearch)
  }

  handleSearchCategoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    const newSearch = { searchValue: this.props.searchValue.searchValue, searchCategory: data.value as string }
    this.props.handleSearchChange(newSearch)
  }

  render() {
    const { loading, tenants } = this.state
    const { searchValue } = this.props

    const searchOptions = [
      { text: 'All', value: 'all' },
      { text: 'Team', value: 'teamName' },
      { text: 'Tenant ID / Org ID', value: 'tenantID' },
      { text: 'Vendor', value: 'vendor' },
      { text: 'Category', value: 'category' },
      { text: 'Environment', value: 'environment' }
    ]
    return (
      <div className="route-component legacy-page">
        <Grid columns={2} stackable verticalAlign="middle">
          <Grid.Column>
            <Grid verticalAlign="middle">
              <Grid.Column>
                <Icon size="large" name="refresh" onClick={this.fetchTenants} loading={loading} className="clickable" />
              </Grid.Column>
              <Grid.Column>
                <SearchInput
                  searchValue={searchValue}
                  searchOptions={searchOptions}
                  handleSearchChange={this.handleSearchChange}
                  handleSearchCategoryChange={this.handleSearchCategoryChange}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button color="blue" as={Link} content="Feature Flag Report" to="/old-model/feature-flag-report" />
            <Button color="blue" as={Link} content="Users Report" to="/old-model/users-report" />
          </Grid.Column>
        </Grid>
        <TenantsList tenants={tenants} loading={loading} searchValue={searchValue} withTeamName />
      </div>
    )
  }
}
