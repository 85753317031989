import { useSearchParams } from 'react-router'
import { Input, InputProps } from 'semantic-ui-react'
import { useDebouncedCallback } from 'use-debounce'
import { useState } from 'react'

export const FilterSearch = (props: { category: string; placeholder?: string; inputProps?: any }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState((searchParams.get(props.category) ?? '').replace('~', ''))

  const debouncedSetSearchParams = useDebouncedCallback(
    value =>
      setSearchParams(
        prevSearchParams => {
          if (value === '') {
            prevSearchParams.delete(props.category)
          } else {
            prevSearchParams.set(props.category, `~${value}`)
          }
          return prevSearchParams
        },
        { replace: true }
      ),
    500
  )

  const onChange = (_event: React.SyntheticEvent, data: InputProps) => {
    const value = (data.value ?? '').replaceAll('~', '')
    setSearchTerm(value)
    debouncedSetSearchParams(value)
  }

  return (
    <Input
      name="filterSearch"
      value={searchTerm}
      onChange={onChange}
      placeholder={props.placeholder ?? 'Search'}
      {...props.inputProps}
    />
  )
}
