import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { createErrorToast } from '../views/alertComponents/Alert'
import {
  getSalesforceAccountMap,
  getSalesforceAccountWithContracts,
  ISalesforceAccountListResult,
  ISalesforceAccountWithContracts
} from '../actions/SalesforceAccount'

export const useGetCustomerAccountDataQuery = (salesforceId: string): UseQueryResult<ISalesforceAccountWithContracts> =>
  useQuery(['customerAccountData', salesforceId], () => getSalesforceAccountWithContracts(salesforceId), {
    onError: error => createErrorToast(error, { title: 'Error fetching salesforce account:' }),
    enabled: !!salesforceId
  })

export const useGetCustomerAccountsMap = (): UseQueryResult<Map<string, ISalesforceAccountListResult>> =>
  useQuery(['customerAccounts'], () => getSalesforceAccountMap(), {
    onError: error => createErrorToast(error, { title: 'Error fetching salesforce account list:' })
  })
