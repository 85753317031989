import { Row } from 'react-table'
import { Icon, Image, Label, SemanticCOLORS } from 'semantic-ui-react'
import { Link } from 'react-router'
import { Customer, ExternalCustomer, InternalCustomer } from '../../../actions/NewCustomers'
import { createMemoizedColumns } from '../../../components/TableComponents/ReactTable'
import { CustomersService } from '../services/CustomersService'
import {
  getValuesFromDynamoQuery,
  InfinitePaginatedTable
} from '../../../components/TableComponents/InfinitePaginatedTable'
import { formatDate } from '../../../utils/dateUtils'
import { RegionFlagPopup } from '../../../utils/regionFlags'
import { ISalesforceAccountListResult } from '../../../actions/SalesforceAccount'
import { salesforceLogo, skeduloLogo } from '../../../images/icons'

const ChurnLozenge = () => (
  <Label basic color="grey">
    Churned
  </Label>
)

const HappinessLozenge = (props: { happiness: string }) => (
  <Label basic color={props.happiness.toLowerCase() as SemanticCOLORS}>
    {props.happiness}
  </Label>
)

const InternalLozenge = () => (
  <Label basic color="blue">
    Internal
  </Label>
)

const ExternalLozenge = (props: { customer: Customer; details?: ISalesforceAccountListResult }) => {
  if (!props.details || !props.customer.salesforceId) {
    return <></>
  }
  if (props.details.Type === 'Churn') {
    return <ChurnLozenge />
  }
  if (props.details.CustomerHappiness) {
    return <HappinessLozenge happiness={props.details.CustomerHappiness} />
  }
  return (
    <Label basic color="black">
      Unknown
    </Label>
  )
}

const PlatformLogo = (props: { platform?: string | null }) => {
  if (!props.platform) {
    return <></>
  }
  if (props.platform.startsWith('Skedulo on Salesforce')) {
    return <Image inline size="mini" src={salesforceLogo.href} />
  }
  if (props.platform === 'Skedulo Platform') {
    return <Image inline size="mini" src={skeduloLogo.href} />
  }
  if (props.platform === 'AU Healthcare') {
    return <>AU HC</>
  }
  return <>{props.platform}</>
}

export const NewCustomersList = ({
  customersService,
  customerAccountDetails,
  isKeywordSearch
}: {
  customersService: CustomersService
  customerAccountDetails?: Map<string, ISalesforceAccountListResult>
  isKeywordSearch: boolean
}) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = customersService

  const customerColumns = createMemoizedColumns<Customer>(
    [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
          <Link to={`/customers/${row.original.id}`}>{row.original.name}</Link>
        )
      },
      {
        Header: 'Salesforce ID',
        accessor: 'salesforceId',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) =>
          row.original.salesforceId ? (
            <a
              href={`https://sked.lightning.force.com/lightning/r/Account/${row.original.salesforceId}/view`}
              target="_blank"
              rel="noreferrer"
            >
              {row.original.salesforceId}{' '}
              <span>
                <Icon name="external" />
              </span>
            </a>
          ) : (
            <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>
          )
      },
      {
        Header: 'Region',
        accessor: 'region',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) =>
          row.original.region ? (
            <RegionFlagPopup region={row.original.region} />
          ) : (
            <p style={{ fontStyle: 'italic', color: '#d1d5db' }}>N/A</p>
          )
      },
      {
        Header: 'Status',
        accessor: 'isInternal',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) =>
          row.original.isInternal ? (
            <InternalLozenge />
          ) : (
            <ExternalLozenge customer={row.original} details={customerAccountDetails?.get(row.original.salesforceId)} />
          )
      },
      {
        Header: 'Platform',
        accessor: 'id',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
          <PlatformLogo
            platform={row.original.salesforceId && customerAccountDetails?.get(row.original.salesforceId)?.PlatformType}
          />
        )
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
          <>{formatDate(row.original.createdAt)}</>
        )
      },
      {
        Header: 'Updated',
        accessor: 'updatedAt',
        Cell: ({ row }: { row: Row<InternalCustomer> | Row<ExternalCustomer> }) => (
          <>{formatDate(row.original.updatedAt)}</>
        )
      }
    ],
    [customerAccountDetails]
  )
  return (
    <InfinitePaginatedTable
      loading={isLoading}
      columns={customerColumns}
      data={data}
      emptyMessage={`No customers found ${isKeywordSearch ? '' : '(search is case sensitive)'}`}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      fetchingNextPage={isFetchingNextPage}
      getValuesFromData={getValuesFromDynamoQuery}
      scrollable
    />
  )
}
