import { Checkbox } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import DynamoQuerySearchControls from '../../components/SearchComponents/DynamoQuerySearchControls'
import { useSearchFilterStore } from '../../context/SearchFilterStoreContext'
import { useFilterService } from '../../services/DynamoQueryFilterService'
import { DynamoQueryFilter } from '../../actions/Utils'
import { useRouterProps } from '../../router/RouterProps'
import { useGetCustomerAccountsMap } from '../../queries/GetCustomerAccountDataQuery'
import { PageContent } from '../../components/react/PageContent'
import { useCustomersService } from './services/CustomersService'
import { NewCustomersList } from './component/NewCustomersList'
import { CreateCustomerModal } from './component/CreateCustomerModal'

const searchableParameters = ['keyword', 'name', 'salesforceId']

const title = 'Customers'

export const createAdditionalFilter = (showInternal: boolean, showExternal: boolean): DynamoQueryFilter | undefined => {
  let filter = ''
  if (!showInternal) {
    // eslint-disable-next-line no-template-curly-in-string
    filter += '${isInternal} = {false}'
  }
  if (!showExternal && !showInternal) {
    filter += ' AND '
  }
  if (!showExternal) {
    // eslint-disable-next-line no-template-curly-in-string
    filter += '${isInternal} = {true}'
  }
  return filter ? { filter } : {}
}

export const NewCustomers = () => {
  const { customersSearchFilter, setCustomersSearchFilter, updateSearchParams } = useSearchFilterStore()

  const additionalFilter = createAdditionalFilter(
    customersSearchFilter.showInternal.value,
    customersSearchFilter.showNonInternal.value
  )
  const { searchValue, searchOptions, handleSearchCategoryChange, handleSearchChange, dynamoQueryFilter } =
    useFilterService(searchableParameters, 'keyword', additionalFilter)
  const customersService = useCustomersService(dynamoQueryFilter)
  const { data: customerAccountDetails } = useGetCustomerAccountsMap()
  const { isFetching, refetch } = customersService
  const debouncedSearchValues = useDebounce(searchValue, 500)
  const [toggleValues, setToggleValues] = useState(customersSearchFilter)
  const routerProps = useRouterProps()

  const onToggleFilter = (filter: 'showInternal' | 'showNonInternal') => () => {
    setToggleValues({
      ...customersSearchFilter,
      [filter]: {
        ...customersSearchFilter[filter],
        value: !customersSearchFilter[filter].value
      }
    })
  }

  const { setDocumentTitle } = useDocumentTitle(title)

  /**
   * Check search filter store on mount and set initial search value and category.
   */
  useEffect(() => {
    if (customersSearchFilter.searchValue) {
      handleSearchChange({}, { value: customersSearchFilter.searchValue })
    }
    if (customersSearchFilter.searchCategory) {
      handleSearchCategoryChange({}, { value: customersSearchFilter.searchCategory })
    }
    setDocumentTitle(title)
  }, [])

  /**
   * Update search filter store for customers.
   */
  useEffect(() => {
    const value = debouncedSearchValues.searchValue
    const category = debouncedSearchValues.searchCategory
    setCustomersSearchFilter({ ...customersSearchFilter, searchValue: value, searchCategory: category })
  }, [debouncedSearchValues])

  useEffect(() => {
    setCustomersSearchFilter({ ...customersSearchFilter, ...toggleValues })
    updateSearchParams(routerProps, toggleValues, ['showNonInternal', 'showInternal'])
  }, [toggleValues])

  useEffect(() => {
    updateSearchParams(routerProps, toggleValues, ['showNonInternal', 'showInternal'], true)
  }, [routerProps.location.search])

  return (
    <PageContent>
      <h2>Customers</h2>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Filters>
            <DynamoQuerySearchControls
              isFetching={isFetching}
              refetch={refetch}
              searchValue={searchValue}
              searchOptions={searchOptions}
              handleSearchChange={handleSearchChange}
              handleSearchCategoryChange={handleSearchCategoryChange}
            />
          </FiltersTogglesButtons.Filters>
          <FiltersTogglesButtons.Toggles>
            <Checkbox
              toggle
              checked={customersSearchFilter.showNonInternal.value}
              onClick={onToggleFilter('showNonInternal')}
              label={<label htmlFor="showNonInternal">Show non-internal customers</label>}
              id="showNonInternal"
            />
            <Checkbox
              toggle
              checked={customersSearchFilter.showInternal.value}
              onClick={onToggleFilter('showInternal')}
              label={<label htmlFor="showInternal">Show internal customers</label>}
              id="showInternal"
            />
          </FiltersTogglesButtons.Toggles>
          <FiltersTogglesButtons.Buttons>
            <CreateCustomerModal />
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>
        <NewCustomersList
          customersService={customersService}
          customerAccountDetails={customerAccountDetails}
          isKeywordSearch={debouncedSearchValues.searchCategory === 'keyword'}
        />
      </PageContent.Main>
    </PageContent>
  )
}
