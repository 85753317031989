import { kebabCase, startCase } from 'lodash'
import { SyntheticEvent, useEffect } from 'react'
import { TabProps } from 'semantic-ui-react'
import { useSearchParams } from 'react-router'

// This code is duplicated in TenantDetails.tsx and must be updated in both locations
export const useTabLinkingService = (panesList: string[], initialTab: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get('tab')

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams({ ['tab']: initialTab }, { replace: true })
    }
  }, [searchParams, setSearchParams])

  const onTabChange = (_event: SyntheticEvent<HTMLElement>, data: TabProps) => {
    const tab = kebabCase(panesList[(data.activeIndex as number) || 0])
    setSearchParams({ ['tab']: tab })
  }

  const activeIndex = panesList.indexOf(startCase(activeTab || initialTab).replace(' ', ''))

  return {
    onTabChange,
    activeIndex
  }
}
