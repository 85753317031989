import { Loader } from 'semantic-ui-react'
import { useState } from 'react'
import { PodInfo } from '../../../actions/Applications'
import { PodsTable } from '../../podComponents/PodsTable'
import { PodStatusCount } from '../../podComponents/PodStatusCount'

export const DeliveriesPodsList = (props: { pods: PodInfo[]; loading: boolean; tag?: string }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [successfulState, setSuccessfulState] = useState(true)

  const filteredPods = props.tag ? props.pods.filter(pod => pod.primaryVersion === props.tag) : props.pods

  const statuses = Object.groupBy(filteredPods, pod => pod.status.toLowerCase())

  const runningCount = (statuses.running || []).length
  const pendingCount = (statuses.pending || []).length
  const errorCount = [...(statuses.crashloopbackoff || []), ...(statuses.error || [])].length
  const imagePullCount = [...(statuses.imagepullbackoff || []), ...(statuses.errimagepull || [])].length
  const otherCount = filteredPods.length - (runningCount + pendingCount + errorCount + imagePullCount)

  const successful = runningCount === filteredPods.length

  if (successful !== successfulState) {
    setSuccessfulState(successful)
    setOpen(!successful)
  }

  const sortedPods = filteredPods.toSorted(
    (a, b) =>
      a.namespace.localeCompare(b.namespace) || a.instance.localeCompare(b.instance) || a.name.localeCompare(b.name)
  )

  return (
    <details open={open}>
      <summary>
        <h3 aria-label="Delivery Pods">
          Pods&nbsp;&nbsp;&nbsp;
          <Loader active={props.loading} inline />
          <PodStatusCount status="running" count={runningCount} />
          <PodStatusCount status="pending" count={pendingCount} />
          <PodStatusCount status="error" count={errorCount} />
          <PodStatusCount status="imagepull" count={imagePullCount} />
          <PodStatusCount status="other" count={otherCount} />
        </h3>
      </summary>

      <PodsTable pods={sortedPods} loading={props.loading} multi />
    </details>
  )
}
