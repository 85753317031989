import { DeploymentsList } from '../../deployments/components/DeploymentsList'
import { IDeployment } from '../../../actions/Deployments'

export const ApplicationDeployments = (props: { deployments: IDeployment[]; loading: boolean }) => (
  <div className="application-deployments">
    <h3>Deployment History</h3>
    <DeploymentsList
      loading={props.loading}
      deployments={props.deployments}
      hideApplication
      hideInstance
      hideEnvironment
      showConfigVersion
      filterCategories={new Map()}
      displayLocalTime={false}
    />
  </div>
)
