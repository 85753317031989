import { InputOnChangeData, Label, Table } from 'semantic-ui-react'
import { FormErrors } from '../../../../../views/newTeams/services/CreateTeamService'
import { INewLoginOption } from '../../../../../actions/Teams'
import { LoginOptionRow } from '../../../../../views/formComponents/Tables'

interface LoginOptionsFormSectionProps {
  title: string
  loginOptions: INewLoginOption[]
  errors: FormErrors
  handleLoginOptionInput: (
    index: number
  ) => (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  addLoginOption: () => void
  deleteLoginOption: (index: number) => () => void
}

const LoginOptionsFormSection = ({
  title,
  loginOptions,
  errors,
  handleLoginOptionInput,
  addLoginOption,
  deleteLoginOption
}: LoginOptionsFormSectionProps) => (
  <>
    <h3>{title}</h3>
    <div className="create-team-note">
      {
        'Skedulo for Salesforce creates Salesforce and Sandbox connections automatically. Add more if required now or after the team has been created.'
      }
    </div>
    {errors?.login?.error && (
      <Label pointing="below" color="red">
        {errors.login.message}
      </Label>
    )}
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Login option</Table.HeaderCell>
          <Table.HeaderCell>Login button text</Table.HeaderCell>
          <Table.HeaderCell>Endpoint</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <LoginOptionRow
          loginOptionData={{ loginOption: 'salesforce', loginButtonText: '', endpoint: '' }}
          onChange={handleLoginOptionInput(-1)}
          iconAction={addLoginOption}
          endpointError={false}
          default
          disabled
          id="0"
        />
        {loginOptions &&
          loginOptions.map((login, index) => (
            <LoginOptionRow
              key={index}
              loginOptionData={login}
              onChange={handleLoginOptionInput(index)}
              iconAction={deleteLoginOption(index)}
              endpointError={errors.login.error}
              id={`${index}`}
              disabled
            />
          ))}
      </Table.Body>
    </Table>
  </>
)

export default LoginOptionsFormSection
