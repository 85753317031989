import { ReactNode } from 'react'

const PageContent = (props: { children: ReactNode }) => <div className="page-content">{props.children}</div>

const Main = (props: { children: ReactNode }) => <div className="page-content-main">{props.children}</div>

const Sidebar = (props: { children: ReactNode }) => <div className="page-content-sidebar">{props.children}</div>

PageContent.Main = Main
PageContent.Sidebar = Sidebar

export { PageContent }
