import { Row } from 'react-table'
import { Label, Popup, SemanticCOLORS } from 'semantic-ui-react'
import { SortableHeaderCell } from '../../../../components/TableComponents/BaseTableComponents/SortableHeaderCell'
import { createMemoizedColumns, Table } from '../../../../components/TableComponents/ReactTable'
import { IUserListFormatted } from './Users'

interface StatusColours {
  [key: string]: SemanticCOLORS
}

export const statusColours: StatusColours = {
  Deactivated: 'red',
  Created: 'grey',
  Active: 'green',
  Invited: 'orange',
  Inactive: 'red'
}

const dateTimePopup = (date: string | null) => (
  <Popup trigger={<span>{date?.split(',')[0]}</span>} content={date} basic />
)

const makeStatusLabel = (value: string) => (
  <Label color={statusColours[value]} basic>
    {value}
  </Label>
)

export const UserList = ({
  sort,
  handleSort,
  data,
  loading,
  isSalesforce
}: {
  sort: {
    column: string
    direction: 'ascending' | 'descending'
  }
  handleSort: (column: string) => () => void
  data?: IUserListFormatted[]
  loading?: boolean
  isSalesforce?: boolean
}) => {
  const SortableUsersHeaderCell = SortableHeaderCell(sort.column, sort.direction, handleSort)

  const userColumns = createMemoizedColumns<IUserListFormatted>(
    [
      {
        Header: <SortableUsersHeaderCell title="User ID" name="UserID" />,
        accessor: 'UserID'
      },
      {
        Header: <SortableUsersHeaderCell title="Email" name="Email" />,
        accessor: 'Email'
      },
      {
        Header: <SortableUsersHeaderCell title="Roles" name="Roles" />,
        accessor: 'Roles'
      },
      {
        Header: <SortableUsersHeaderCell title="Created" name="CreatedDateISO" />,
        accessor: 'CreatedDate',
        Cell: ({ row }: { row: Row<IUserListFormatted> }) => dateTimePopup(row.original.CreatedDate)
      },
      {
        Header: <SortableUsersHeaderCell title="Last logged in" name="LastLoginDateISO" />,
        accessor: 'LastLoginDate',
        Cell: ({ row }: { row: Row<IUserListFormatted> }) => dateTimePopup(row.original.LastLoginDate ?? '')
      },
      {
        Header: <SortableUsersHeaderCell title="Licensed as" name="LicensedAs" />,
        accessor: 'LicensedAs'
      },
      {
        Header: <SortableUsersHeaderCell title="User status" name="Status" />,
        accessor: 'Status',
        Cell: ({ row }: { row: Row<IUserListFormatted> }) => makeStatusLabel(row.original.Status ?? 'Created')
      }
    ],
    [sort]
  )

  const excludeColumns = isSalesforce ? ['LastLoginDate', 'Status'] : []
  const filteredColumns = userColumns.filter(column => !excludeColumns.some(accessor => accessor === column.accessor))

  return (
    <div>
      <p style={{ paddingTop: '10px' }}>
        <em>
          <small>Note: Only the first 2000 users are returned below.</small>
        </em>
      </p>
      <Table
        loading={loading}
        columns={filteredColumns}
        data={data || []}
        emptyMessage="No users found"
        celled
        color="blue"
      />
    </div>
  )
}
