import { regionalApiServer } from './Endpoints'

export type ActionType = 'send_sms' | 'call_url'

export type TriggerType = 'object_modified' | 'event'

export interface TriggeredAction {
  name: string
  enabled: boolean
  type: string
  action: ActionType
  updated: string
  deferred: boolean
  trigger: TriggerType
  id: string
  lastExecuted: string | null
  lastExecutionStatus: string | null
}

export interface TriggeredActionLog {
  status: string
  id: number
  tenantId: string
  sourceId: string
  referenceId?: number
  data: any
  result?: any
  created: string
  started?: string
  completed?: string
  retry: number
}

export interface TriggeredActionLogResponse {
  paging: {
    total: number
    limit: number
    offset: number
  }
  result: TriggeredActionLog[]
}

export const getTriggeredActions = (tenantId: string, regionName: string): Promise<TriggeredAction[]> =>
  regionalApiServer(regionName.toUpperCase())
    .get('/artifacts/internal/triggered-action', {
      params: {
        tenantId
      }
    })
    .then(response => response.data)

export const getTriggeredActionLogs = (
  triggeredActionId: string,
  tenantId: string,
  regionName: string,
  offset?: number
): Promise<TriggeredActionLogResponse> =>
  regionalApiServer(regionName.toUpperCase())
    .get(`/triggered_actions/logs/${tenantId}`, {
      params: {
        sourceId: triggeredActionId,
        order: 'desc',
        offset
      }
    })
    .then(response => response.data)
