import _ from 'lodash'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import humanizeDuration from 'humanize-duration'

import * as React from 'react'

import { Button, Divider, Icon, List, Segment } from 'semantic-ui-react'
import { TDeploymentDetails } from 'src/actions/Deployments'
import { SimpleModal } from '../../modalComponents/SimpleModal'
import { useDeploymentCancelMutation } from '../../../mutations/deployments/CancelDeploymentMutation'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'
import { GithubURL } from '../../../components/GithubURL'
import { DeploymentStatusIcon } from './DeploymentStatusIcon'

dayjs.extend(duration)

interface IProps {
  deployment: TDeploymentDetails
}

export const DeploymentInformation = (props: IProps) => {
  const { mutate: cancelDeployment } = useDeploymentCancelMutation()

  const getAWXUrl = () => {
    if (!deployment) {
      return '#'
    } else {
      const awxEnv = deployment.env === 'staging' ? 'test' : deployment.env
      return `https://awx.${deployment.instance}.private.${awxEnv}.skl.io/#/jobs/playbook/${deployment.id}`
    }
  }

  const getElapsedDurationString = (sec: number) => {
    const elapsed = dayjs.duration(sec, 'seconds')
    return humanizeDuration(elapsed.asMilliseconds(), { units: ['d', 'h', 'm', 's'], largest: 2, round: true })
  }

  const { deployment } = props
  const canCancel =
    deployment.status === 'running' || deployment.status === 'waiting' || deployment.status === 'pending'
  return (
    <div aria-label="deployment-information">
      <h3>Deployment Info</h3>
      <Segment color="blue">
        <LoaderWrap loading={!deployment.application}>
          <React.Fragment>
            <List name="info-list">
              <List.Item>
                <List.Header>Application</List.Header>
                {_.capitalize(deployment.application)}
              </List.Item>
              <List.Item>
                <List.Header>Environment</List.Header>
                {deployment.env}
              </List.Item>
              <List.Item>
                <List.Header>Instance</List.Header>
                {deployment.instance}
              </List.Item>
              <List.Item>
                <Button
                  href={getAWXUrl()}
                  target="_blank"
                  rel="noreferrer"
                  icon
                  labelPosition="left"
                  name="link to awx job"
                >
                  <Icon name="server" />
                  View in AWX
                </Button>
              </List.Item>
            </List>
            <Divider horizontal> Details </Divider>
            <List name="info-list">
              {canCancel && (
                <List.Item>
                  <SimpleModal
                    submitFn={async () =>
                      cancelDeployment({
                        environment: deployment.env,
                        instance: deployment.instance,
                        id: deployment.id
                      })
                    }
                    header={'Cancel Deployment'}
                    content={'Are you sure you want to cancel this deployment'}
                    successMessage={`Successfully canceled deployment ${deployment.id}`}
                    buttonClassName="form-button-sked-blue"
                  />
                </List.Item>
              )}
              <List.Item>
                <DeploymentStatusIcon
                  status={deployment.status}
                  checkMode={deployment.check_mode}
                  explanation={deployment.explanation}
                />
                <List.Content>
                  <List.Header>Status</List.Header>
                  {_.startCase(deployment.status)}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="clock" />
                <List.Content>
                  <List.Header>Timestamp</List.Header>
                  <div className="table-cell-overflow">
                    {dayjs(deployment.timestamp).format('MMMM D YYYY, h:mm:ss a')}
                  </div>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="hourglass" />
                <List.Content>
                  <List.Header>Elapsed Duration</List.Header>
                  <div className="table-cell-overflow">{getElapsedDurationString(deployment.duration)}</div>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="user" />
                <List.Content>
                  <List.Header>Triggered By</List.Header>
                  <div className="table-cell-overflow">{deployment.user}</div>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="git" />
                <List.Content>
                  <List.Header>Tag</List.Header>
                  <div className="table-cell-overflow">
                    {deployment.tag ? ( // Tag isn't always provided for all deploys
                      <>
                        <GithubURL
                          owner="skedulo"
                          // This probably isn't the most accurate but it might work "ok" due to the magic links that we rely on in admin-api
                          repository={deployment.repositoryName ?? deployment.application}
                          commit={deployment.tag}
                        />{' '}
                        <Icon name="external" />
                      </>
                    ) : (
                      deployment?.tag
                    )}
                  </div>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="git" />
                <List.Content>
                  <List.Header>Config</List.Header>
                  <div className="table-cell-overflow">
                    {deployment.config ? (
                      <>
                        <GithubURL owner="skedulo" repository="ansible-skedulo-kube" commit={deployment.config} />{' '}
                        <Icon name="external" />
                      </>
                    ) : (
                      deployment?.config
                    )}
                  </div>
                </List.Content>
              </List.Item>
            </List>
          </React.Fragment>
        </LoaderWrap>
      </Segment>
    </div>
  )
}
