import { Button, Loader, Table } from 'semantic-ui-react'
import { useState } from 'react'
import { IApplication, IApplicationRevision } from '../../../actions/Applications'
import { useApplicationRevisionsQuery } from '../../../queries/applications/GetApplicationsQuery'
import { RollbackApplicationModal } from './RollbackApplicationModal'

interface IProps {
  application?: IApplication
  reloadApplication: () => void
}
type OpenRollbackModal = (revision: IApplicationRevision) => void

const ApplicationRevisionRow = (props: {
  revision: IApplicationRevision
  index: number
  openRollbackModal: OpenRollbackModal
}) => (
  <Table.Row>
    <Table.Cell>{props.revision.revisionId}</Table.Cell>
    <Table.Cell>{props.revision.creationTimestamp}</Table.Cell>
    <Table.Cell className="table-cell-overflow">{props.revision.tag}</Table.Cell>
    <Table.Cell>{props.revision.configHash}</Table.Cell>
    <Table.Cell>{props.revision.changeCause}</Table.Cell>
    <Table.Cell textAlign="center">
      {props.index === 0 ? (
        'Current'
      ) : (
        <Button
          icon="redo"
          circular
          className="circle-icon-button"
          onClick={() => props.openRollbackModal(props.revision)}
        />
      )}
    </Table.Cell>
  </Table.Row>
)

export const ApplicationRevisionsList = (props: IProps) => {
  const [rollbackModalOpen, setRollbackModalOpen] = useState(false)
  const [rollbackModalRevision, setRollbackModalRevision] = useState<IApplicationRevision | undefined>(undefined)
  const { data: revisions, status: revisionsStatus } = useApplicationRevisionsQuery(props.application)

  const openRollbackModal = (revision: IApplicationRevision) => {
    setRollbackModalRevision(revision)
    setRollbackModalOpen(true)
  }

  const resetRollbackModal = () => {
    setRollbackModalRevision(undefined)
    setRollbackModalOpen(false)
  }

  return (
    <div>
      <RollbackApplicationModal
        open={rollbackModalOpen}
        resetModal={resetRollbackModal}
        revision={rollbackModalRevision}
        application={props.application}
      />
      <h3>Revision History</h3>
      <Table striped color="blue" aria-label="Application Revisions">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Time Created</Table.HeaderCell>
            <Table.HeaderCell>Tag</Table.HeaderCell>
            <Table.HeaderCell>Config</Table.HeaderCell>
            <Table.HeaderCell>Cause</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Roll Back</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {revisions && revisions.length > 0 && (
          <Table.Body>
            {revisions
              .sort((a, b) => parseInt(b.revisionId, 10) - parseInt(a.revisionId, 10))
              .map((revision, index) => (
                <ApplicationRevisionRow
                  revision={revision}
                  index={index}
                  openRollbackModal={openRollbackModal}
                  key={revision.revisionId}
                />
              ))}
          </Table.Body>
        )}
        {(!revisions || revisions.length === 0) && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="16" textAlign="center">
                {revisionsStatus === 'loading' ? (
                  <Loader active={true} inline />
                ) : (
                  <h4 className="centered">No Revisions for this Application</h4>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  )
}
