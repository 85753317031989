import { ReactNode } from 'react'
import { Link } from 'react-router'

const Breadcrumb = (props: { children: ReactNode }) => <div className="breadcrumb">{props.children}</div>

const Section = (props: {
  href?: string
  children: ReactNode
  className?: string
  onClick?: () => void | Promise<void>
}) =>
  props.href ? (
    <Link className={`breadcrumb-section ${props.className}`} to={props.href} onClick={props.onClick}>
      {props.children}
    </Link>
  ) : (
    <a className={`breadcrumb-section ${props.className}`} onClick={props.onClick}>
      {props.children}
    </a>
  )

const Divider = () => <span className="breadcrumb-divider">&nbsp;/&nbsp;</span>

Breadcrumb.Section = Section
Breadcrumb.Divider = Divider

export { Breadcrumb }
