import * as React from 'react'
import { useNavigate } from 'react-router'
import { useAuthStore } from '../context/AuthContext'
import { Spinner } from '../App'
import { logDataDogUserInfo } from '../analytics/instrumentation'
import { logError } from '../analytics/error-log'
import AuthClient from './AuthClient'
import hasAuthTokenInLocalStorage from './AuthChecker'

export const RedirectCallback = () => {
  const navigate = useNavigate()

  const { setIsAuthenticated: setAuth } = useAuthStore()

  /**
   * Fetch and record access token for endpoints. When user logs in successfuly, access token is cached
   * and `getTokenSilently` will retrieve the valid cached token which may be refreshed.
   */
  React.useEffect(() => {
    const handleRedirect = async () => {
      try {
        const { appState } = await AuthClient.handleRedirectCallback()
        const redirectToUrl = appState?.redirectTo ?? '/'
        await AuthClient.getTokenSilently()
        setAuth(hasAuthTokenInLocalStorage())
        logDataDogUserInfo()

        navigate(redirectToUrl, { replace: true })
      } catch (e) {
        logError(e)
        await AuthClient.logout({ logoutParams: { returnTo: window.location.origin } })
      }
    }
    handleRedirect()
  }, [navigate, setAuth])

  return <Spinner />
}
