import { Checkbox, Icon } from 'semantic-ui-react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { useLocalStorage } from 'usehooks-ts'
import { useDeploymentsQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDeploymentModal } from '../../hooks/useDeploymentModal'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { FilterCategoryOptions, Filters } from '../filterComponents/Filters'
import { FilterSearch } from '../filterComponents/FilterSearch'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { PageContent } from '../../components/react/PageContent'
import { DeployApplicationModal } from './components/DeployApplicationModal'
import { DeploymentsList } from './components/DeploymentsList'

export const invalidateDeployments = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: ['deployments'] })
}

export const Deployments = () => {
  const filterCategories = new Map<string, FilterCategoryOptions>([
    ['application', { text: 'Application' }],
    ['env', { text: 'Environment' }],
    ['instance', { text: 'Instance' }],
    ['tag', { text: 'Tag' }],
    ['config_hash', { text: 'Config' }],
    ['user', { text: 'Deployed By' }]
  ])

  const [displayLocalTime, setDisplayLocalTime] = useLocalStorage('display-local-time', false)
  const [showConfigVersion, setShowConfigVersion] = useLocalStorage('show-config-version', false)
  const [showTriggeredBy, setShowTriggeredBy] = useLocalStorage('show-deploy-user', false)

  useDocumentTitle('Deployments')

  const modal = useDeploymentModal()

  const queryClient = useQueryClient()

  const { status, data: deployments } = useDeploymentsQuery()

  if (deployments) {
    filterCategories.set('application', {
      text: 'Application',
      values: deployments.map(deployment => deployment.application)
    })
  }

  return (
    <PageContent>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Filters>
            <Icon
              size="large"
              name="refresh"
              onClick={() => invalidateDeployments(queryClient)}
              loading={status === 'loading'}
              className="clickable"
            />
            <FilterSearch category="application" />
            <Filters filterCategories={filterCategories} />
          </FiltersTogglesButtons.Filters>
          <FiltersTogglesButtons.Toggles>
            <Checkbox
              label={<label htmlFor="displayLocalTime">Show local times</label>}
              id="displayLocalTime"
              toggle
              checked={displayLocalTime}
              onChange={(_, data) => {
                setDisplayLocalTime(!!data.checked)
              }}
            />
            <Checkbox
              label={<label htmlFor="showConfigVersion">Show config versions</label>}
              id="showConfigVersion"
              toggle
              checked={showConfigVersion}
              onChange={(_, data) => {
                setShowConfigVersion(!!data.checked)
              }}
            />
            <Checkbox
              label={<label htmlFor="showTriggeredBy">Show triggered by</label>}
              id="showTriggeredBy"
              toggle
              checked={showTriggeredBy}
              onChange={(_, data) => {
                setShowTriggeredBy(!!data.checked)
              }}
            />
          </FiltersTogglesButtons.Toggles>
          <FiltersTogglesButtons.Buttons>
            <DeployApplicationModal modal={modal} withButton />
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>
        <DeploymentsList
          loading={status === 'loading'}
          deployments={deployments || []}
          filterCategories={filterCategories}
          modal={modal}
          displayLocalTime={displayLocalTime}
          showConfigVersion={showConfigVersion}
          showTriggeredBy={showTriggeredBy}
        />
      </PageContent.Main>
    </PageContent>
  )
}
