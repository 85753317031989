import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import {
  getTriggeredActionLogs,
  getTriggeredActions,
  TriggeredAction,
  TriggeredActionLogResponse
} from '../../actions/TriggeredActions'

export const useGetTriggeredActionsQuery = (
  tenantId?: string,
  regionName?: string
): UseQueryResult<TriggeredAction[]> =>
  useQuery(['triggered-actions', tenantId], () => getTriggeredActions(tenantId!, regionName!), {
    onError: error => createErrorToast(error),
    enabled: !!tenantId && !!regionName
  })

export const useGetTriggeredActionLogsQuery = (
  tenantId?: string,
  regionName?: string,
  triggeredActionId?: string
): UseQueryResult<TriggeredActionLogResponse> =>
  useQuery(
    ['triggered-actions-logs', triggeredActionId],
    () => getTriggeredActionLogs(triggeredActionId!, tenantId!, regionName!),
    {
      onError: error => createErrorToast(error),
      enabled: !!tenantId && !!triggeredActionId && !!regionName
    }
  )
