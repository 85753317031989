import { Button, Form, Modal, Radio, Input, Popup, Message, Icon, Checkbox } from 'semantic-ui-react'
import { useBoolean, useDebounce } from 'usehooks-ts'
import { useState } from 'react'
import { Dictionary, keys } from 'lodash'
import { InputWithError } from '../../formComponents/Inputs'
import { RadioGroup, RadioGroupOption } from '../../../components/RadioGroup/RadioGroup'
import { useInstallPackageMutation } from '../../../mutations/packages/InstallPackageMutation'
import { PackageInstallationRequest } from '../../../actions/Package'
import { createErrorToast } from '../../../views/alertComponents/Alert'
import { useCheckDeploymentValidity } from '../../../queries/packages/ValidatePackageDeployment'

export interface PackageInstallationModalProps {
  tenantId: string
  region: string
  disableInstallPackages: boolean
  isFetching: boolean
  teamName: string
}

export interface IProductPackage {
  title: string
  packageName: string
}

export const PRODUCT_PACKAGES: RadioGroupOption[] = [
  {
    text: 'Pulse scheduling experience',
    value: 'skedulo-core/pulse-scheduling-experience'
  },
  {
    text: 'Pulse allocation experience',
    value: 'skedulo-core/pulse-allocation-experience'
  }
]

export const CORE_PRODUCT_PACKAGE = 'core'
export const CUSTOMISED_PACKAGE = 'customised'

export const INSTALLATION_OPTIONS: RadioGroupOption[] = [
  {
    text: 'Product package',
    value: CORE_PRODUCT_PACKAGE
  },
  {
    text: 'Custom package',
    value: CUSTOMISED_PACKAGE
  }
]

export const PackageInstallationModal = (props: PackageInstallationModalProps) => {
  const { region, tenantId, disableInstallPackages, isFetching, teamName } = props

  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean(false)
  const [packageName, setPackageName] = useState('')
  const [tenantToken, setTenantToken] = useState('')
  const [packageVersion, setPackageVersion] = useState('')
  const [selectedInstallationOption, setSelectedInstallationOption] = useState('')
  const { value: isTenantInstall, setValue: setIsTenantInstall } = useBoolean(false)
  const { mutate: installPackage, isLoading } = useInstallPackageMutation(() => onClose())
  const debouncedPackageName = useDebounce(packageName.trim(), 500)
  const debouncedPackageVersion = useDebounce(packageVersion.trim(), 500)
  const { data: validationResult, isLoading: isLoadingCheckValid } = useCheckDeploymentValidity(
    debouncedPackageName,
    debouncedPackageVersion,
    tenantId,
    region,
    isTenantInstall
  )
  const {
    value: openConfirmation,
    setTrue: openModalConfirmation,
    setFalse: closeModalConfirmation
  } = useBoolean(false)
  const [formErrors, setFormErrors] = useState<Dictionary<string>>({})

  const onClose = () => {
    setSelectedInstallationOption('')
    setPackageName('')
    setPackageVersion('')
    setIsTenantInstall(false)
    setTenantToken('')
    setFormErrors({})
    closeModal()
    closeModalConfirmation()
  }

  const isValidForm = () => {
    const errs: Dictionary<string> = {}

    if (region === '') {
      createErrorToast(new Error('Team region is undefined.'), { title: 'Error:' })
    }
    if (selectedInstallationOption === '') {
      errs.installOption = 'Installation type must be defined'
    } else if (packageName === '') {
      errs.package = 'Package name must be defined'
    } else if (validationResult?.message) {
      errs.package = validationResult?.message
    }
    if (isTenantInstall && !tenantToken) {
      errs.tenantToken = 'Tenant token must be defined'
    }

    if (keys(errs).length) {
      setFormErrors(errs)
      return false
    }
    return true
  }

  const handleInstall = () => {
    if (isTenantInstall) {
      const request: PackageInstallationRequest = {
        packageName: debouncedPackageName,
        version: debouncedPackageVersion,
        tenantIds: tenantId
      }
      installPackage({ request, region, token: tenantToken })
    } else {
      const tenantIds = tenantId ? [tenantId] : []
      const request: PackageInstallationRequest = {
        packageName: debouncedPackageName,
        version: debouncedPackageVersion,
        tenantIds
      }
      installPackage({ request, region })
    }
  }

  const handleSubmit = () => {
    if (isValidForm()) {
      openModalConfirmation()
    }
  }

  const ConfirmationModal = () => (
    <Modal open={openConfirmation} onClose={closeModalConfirmation} data-testid="package-install-confirmation-modal">
      <Modal.Header id="modal-header-install-package">Install package</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to install package{' '}
          <b>
            {packageName}
            {packageVersion ? ' v' + packageVersion : ' (latest version)'}
          </b>{' '}
          to team <b>{teamName}</b>?
        </p>
        {isTenantInstall && (
          <Message info>
            <Icon name="question circle" size="large" />
            This will be carried out on behalf of the tenant associated with the API token you provided in the previous
            step.
          </Message>
        )}
        <Message error>
          <Icon name="exclamation triangle" size="large" />
          This action may be irreversible. Please ensure you are confident before proceeding.
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModalConfirmation} content="Cancel" />
        <Button negative id="confirm-modal-install" content="Install" onClick={handleInstall} disabled={isLoading} />
      </Modal.Actions>
    </Modal>
  )

  return openConfirmation ? (
    <ConfirmationModal />
  ) : (
    <Modal
      data-testid="package-installation-modal"
      closeIcon
      open={open}
      trigger={
        <Popup
          trigger={
            <div>
              <Button
                className={'form-button-sked-blue'}
                content={'Install package'}
                onClick={openModal}
                disabled={disableInstallPackages}
                loading={isFetching}
              />
            </div>
          }
          content={'The usePackagingV2 feature flag must be enabled to install packages'}
          basic
          position="top right"
          disabled={!disableInstallPackages}
        />
      }
      onClose={onClose}
      onOpen={openModal}
    >
      <Modal.Header id="modal-header-install-package">Install package</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field style={{ marginBottom: '30px' }}>
            <Form.Group style={{ alignItems: 'center' }}>
              <Form.Field width={6}>
                <Checkbox
                  toggle
                  onClick={(_, { checked }) => {
                    setIsTenantInstall(!!checked)
                    setSelectedInstallationOption(checked ? CUSTOMISED_PACKAGE : '')
                    setPackageName('')
                    formErrors.tenantToken = ''
                    formErrors.installOption = ''
                    formErrors.package = ''
                  }}
                  label="Perform install on behalf of tenant?"
                  checked={isTenantInstall}
                />
              </Form.Field>
              {isTenantInstall && (
                <InputWithError
                  type="password"
                  data-testid="tenant-token"
                  id="tenantToken"
                  name="tenantToken"
                  placeholder="Enter the tenant API token used to authorize the installation"
                  value={tenantToken}
                  onChange={(_, { value }) => {
                    setTenantToken(value)
                    formErrors.tenantToken = ''
                  }}
                  error={!!formErrors.tenantToken}
                  label={formErrors.tenantToken}
                  fluid
                  width={10}
                />
              )}
            </Form.Group>
          </Form.Field>

          {!isTenantInstall && (
            <Form.Field error={Boolean(formErrors.installOption)}>
              <div style={{ marginBottom: '10px' }}>Select the type of package to install</div>
              {formErrors.installOption && <label color="red">{formErrors.installOption}</label>}
              <Form.Group inline widths="equal">
                {INSTALLATION_OPTIONS.map(installOption => (
                  <Form.Field key={installOption.text}>
                    <Button
                      type="button"
                      className={packageName === installOption.text ? 'radio selected' : 'radio'}
                      onClick={() => {
                        setSelectedInstallationOption(installOption.value)
                        setPackageName('')
                        formErrors.installOption = ''
                        formErrors.package = ''
                      }}
                      basic
                      fluid
                    >
                      <Radio
                        label={<label>{installOption.text}</label>}
                        name={`installOptionCheckboxGroup-${installOption.value}`}
                        value={installOption.text}
                        checked={selectedInstallationOption === installOption.value}
                      />
                    </Button>
                  </Form.Field>
                ))}
              </Form.Group>
            </Form.Field>
          )}

          {selectedInstallationOption === CORE_PRODUCT_PACKAGE && (
            <Form.Field error={Boolean(formErrors.package)}>
              <h3>{INSTALLATION_OPTIONS[0].text}</h3>
              <div style={{ marginBottom: '10px' }}>
                Select a package below for installation using the latest version.
              </div>
              {formErrors.package && <label color="red">{formErrors.package}</label>}
              <RadioGroup
                options={PRODUCT_PACKAGES}
                name="region"
                value={packageName}
                handleInput={(_event, { value }) => {
                  setPackageName(value)
                  formErrors.package = ''
                }}
              />
            </Form.Field>
          )}

          {selectedInstallationOption === CUSTOMISED_PACKAGE && (
            <Form.Field>
              <h3>{INSTALLATION_OPTIONS[1].text}</h3>
              <Form.Field required>
                <label htmlFor="manual-package-name">Package name</label>
                <InputWithError
                  error={!!formErrors.package}
                  label={formErrors.package}
                  data-testid="custom-package-name"
                  id="packageName"
                  name="packageName"
                  placeholder="Enter package name"
                  value={packageName}
                  onChange={(_, { value }) => {
                    setPackageName(value)
                    formErrors.package = ''
                  }}
                  fluid
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="manual-package-version">Package version</label>
                <div style={{ marginBottom: '0px' }} className="create-team-note">
                  Empty or unspecified package version defaults to the latest available version for the specified
                  package name.
                </div>
                <Input
                  data-testid="custom-package-version"
                  id="version"
                  name="version"
                  type="text"
                  placeholder="Enter package version"
                  value={packageVersion}
                  onChange={(_, { value }) => {
                    setPackageVersion(value)
                    formErrors.package = ''
                  }}
                  fluid
                />
              </Form.Field>
            </Form.Field>
          )}
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          id="install-package-submit"
          className="form-button-sked-blue"
          content="Submit"
          onClick={handleSubmit}
          loading={isLoadingCheckValid || isLoading || isFetching}
          disabled={isLoadingCheckValid || isLoading || isFetching}
        />
      </Modal.Actions>
    </Modal>
  )
}
