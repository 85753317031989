import { Loader } from 'semantic-ui-react'
import { TDeploymentDetails } from 'src/actions/Deployments'
import { useRouterProps } from '../../router/RouterProps'
import { useDeploymentQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { PageContent } from '../../components/react/PageContent'
import { DeploymentInformation } from './components/DeploymentInformation'
export const DeploymentDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('Deployment Details')

  const id = routerProps.params.id || ''
  const env = routerProps.params.env || ''
  const instance = routerProps.params.instance || ''
  const { data: deployment, status: deploymentStatus } = useDeploymentQuery(env, instance, id)

  if (deployment?.application && documentTitle !== deployment?.application) {
    setDocumentTitle(deployment?.application)
  }

  return (
    <PageContent>
      <PageContent.Sidebar>
        <DeploymentInformation deployment={(deployment || {}) as TDeploymentDetails} />
      </PageContent.Sidebar>
      <PageContent.Main>
        {deployment?.explanation && <div>{deployment.explanation}</div>}
        <div className="console">
          <div dangerouslySetInnerHTML={{ __html: deployment?.output || '' }} />
          {(!deployment || deployment.status === 'running' || deployment.status === 'pending') && (
            <Loader size="tiny" />
          )}
          {deploymentStatus === 'loading' && <Loader size="tiny" />}
        </div>
      </PageContent.Main>
    </PageContent>
  )
}
