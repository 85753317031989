import { Navigate } from 'react-router'
import { useRouterProps } from '../../router/RouterProps'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useDeploymentsQueryByEnvironmentAndInstance } from '../../queries/deployments/GetDeploymentsQuery'
import { useFunctionQuery } from '../../queries/applications/GetFunctionQuery'
import { useReleaseApplicationQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { PageContent } from '../../components/react/PageContent'
import { ApplicationDeployments } from './components/ApplicationDeployments'
import { ApplicationInformation } from './components/ApplicationInformation'

export const FunctionDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('')

  const appName = routerProps.params.name!
  const environment = routerProps.params.environment!
  const instance = routerProps.params.instance!

  const { data: application, error } = useFunctionQuery(appName, instance, environment)
  const { data: releaseApplication } = useReleaseApplicationQuery(application?.name)

  if (application !== undefined && documentTitle !== application.name) {
    setDocumentTitle(application.name)
  }

  const { data: deployments, status: deploymentsStatus } = useDeploymentsQueryByEnvironmentAndInstance(
    environment,
    instance
  )

  const filteredDeployments = deployments
    ? deployments.filter(deployment => application?.name && deployment.application?.includes(application.name))
    : []

  return (
    <PageContent>
      {error && <Navigate to="/applications/applications" />}
      <PageContent.Sidebar>
        <ApplicationInformation
          application={application}
          releaseApplication={releaseApplication}
          name={appName}
          instance={instance}
          environment={environment}
        />
      </PageContent.Sidebar>
      <PageContent.Main>
        <ApplicationDeployments deployments={filteredDeployments} loading={deploymentsStatus === 'loading'} />
      </PageContent.Main>
    </PageContent>
  )
}
