import { Message } from 'semantic-ui-react'
import { Link } from 'react-router'

const contentText = (linkText: any) => (
  <div>
    <p>
      You are viewing legacy pages, which are only for managing customers and teams that have not yet migrated to the
      new customer model.<br></br>Use the{linkText}
      instead, unless you are managing customers and teams that have not been migrated.
    </p>
  </div>
)

export const OldModelWarning = () => (
  <Message
    content={contentText(
      <Link to="/customers">
        <b style={{ fontWeight: 800 }}> new customer model pages </b>
      </Link>
    )}
    error
    attached="bottom"
    id="old-model-warning"
  />
)
