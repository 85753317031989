import { Link } from 'react-router'
import { Row } from 'react-table'
import { Popup, Icon, Label, Image } from 'semantic-ui-react'
import { userCountColumns } from '../../../views/tenants/components/Users/UserCounts'
import { environmentColours } from '../../newTeams/component/NewTeamsList'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { salesforceLogo } from '../../../images/icons'
import { TeamWithSummary } from './CustomerUsers'

export const CustomerUsersList = ({ data, loading }: { data?: TeamWithSummary[]; loading?: boolean }) => {
  const LimitNotSetPopup = (
    <Popup
      trigger={
        <div>
          Not set <Icon name="question circle" />
        </div>
      }
      content={
        <div>
          Active user limits can be set by navigating to the <b>Users</b> tab on this team. <br />
          <br />
          When set, users will be notified of their limit via an alert banner on the <b>Users</b> list. They will see an
          error, and will be unable to create or reactivate users if their limit has been exceeded
        </div>
      }
      basic
      hoverable
    />
  )

  const teamsColumns = createMemoizedColumns<TeamWithSummary>([
    {
      Header: 'Team',
      accessor: (team: TeamWithSummary) => team.team.primaryName,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <Link to={`/teams/${row.original.team.id}?tab=users`}>
          {row.original.team.primaryName}
          {row.original.team.isSalesforce && (
            <Popup
              content="Salesforce Team"
              position="top center"
              trigger={
                <Image
                  inline={true}
                  id="salesforce-logo"
                  src={salesforceLogo.href}
                  size="mini"
                  spaced="left"
                  style={{ width: 20 }}
                />
              }
              style={{
                opacity: 0.9,
                textAlign: 'center'
              }}
              inverted
            />
          )}
        </Link>
      )
    },
    {
      Header: 'Environment',
      accessor: (team: TeamWithSummary) => team.team.environment,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) => (
        <Label color={environmentColours[row.original.team.environment] ?? 'grey'} basic>
          {row.original.team.environment}
        </Label>
      )
    },
    ...userCountColumns(),
    {
      Header: 'Active user limit',
      accessor: (team: TeamWithSummary) => team.team.metadata.MaxActiveUsersEnforced,
      Cell: ({ row }: { row: Row<TeamWithSummary> }) =>
        row.original.team.metadata.MaxActiveUsersEnforced ?? LimitNotSetPopup
    }
  ])

  return (
    <Table
      loading={loading}
      columns={teamsColumns}
      data={data || []}
      emptyMessage="No teams found"
      color="blue"
      className="sticky-table"
      textAlign="left"
    />
  )
}
