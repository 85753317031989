import { useParams } from 'react-router'
import { Icon } from 'semantic-ui-react'
import { IPackageProperties, IPackageWithTenantCount } from '../../actions/Package'
import { EditableInformation } from '../../components/InformationComponents/EditableInformation'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetPackageQuery } from '../../queries/packages/GetPackageQuery'
import { DisplayOnlyInformation } from '../../components/InformationComponents/DisplayOnlyInformation'
import { PageContent } from '../../components/react/PageContent'
import { Breadcrumb } from '../../components/react/Breadcrumbs'
import {
  usePackageInformationService,
  usePackagePropertiesInformationService
} from './services/PackageInformationService'
import { PackageVersionsList } from './component/PackageVersionsList'

export const PackageDetails = () => {
  const params = useParams()
  const packageName = params.id!

  const { data: packageData } = useGetPackageQuery(packageName)
  const { data: regionsInfo } = useGetRegionQuery()

  const packagePropertiesInformationService = usePackagePropertiesInformationService(packageData!)
  const packageInformationService = usePackageInformationService(packageData!, regionsInfo || [])

  return (
    <PageContent>
      <Breadcrumb>
        <Breadcrumb.Section href="/packages">
          <Icon name="chevron left" size="small" /> Packages
        </Breadcrumb.Section>
      </Breadcrumb>
      <h2>{packageName}</h2>

      <PageContent.Sidebar>
        <DisplayOnlyInformation<IPackageWithTenantCount> {...packageInformationService} />
        <br />
        <EditableInformation<IPackageProperties> {...packagePropertiesInformationService} useAlternateGridUI />
      </PageContent.Sidebar>
      <PageContent.Main>
        <PackageVersionsList
          title="Package versions"
          packageName={packageName}
          regionsInfo={regionsInfo || []}
          latestVersion={packageData?.latestVersion}
        />
      </PageContent.Main>
    </PageContent>
  )
}
