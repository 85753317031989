import * as _ from 'lodash'
import { Button, Icon, Checkbox } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import {
  getMultipleTenantFeatures,
  IFeatureFlagWithDescription,
  IMultiTenantFeatureFlags,
  TenantWithNameAndRegion
} from '../../actions/Tenants'
import { useGetFeatureFlagReportQuery } from '../../queries/GetFeatureFlagReportQuery'
import { useGetTenantsInfoQuery } from '../../queries/GetTenantsInfoQuery'
import { PageContent } from '../../components/react/PageContent'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { FeatureFlagList } from './components/FeatureFlagList'
import { FeatureEnabledForTenantList } from './components/FeatureEnabledForTenantList'

export interface TenantWithFeaturesNameAndRegion extends TenantWithNameAndRegion {
  featureFlags: IFeatureFlagWithDescription[]
}

export interface IFeatureFlagAggregate {
  [x: string]: { count: number; description: string }
}

export const aggregateTenantFeatures = (customTenants: TenantWithFeaturesNameAndRegion[]): IFeatureFlagAggregate => {
  const aggr: IFeatureFlagAggregate = {}
  customTenants.forEach(tenant => {
    tenant.featureFlags
      .filter(feature => feature.type === 'Feature')
      .forEach(feature => {
        aggr[feature.name] = {
          count: (aggr[feature.name]?.count || 0) + (feature.value ? 1 : 0),
          description: feature.description
        }
      })
  })
  return aggr
}

export const combineTenantsWithFeatures =
  (tenantFeatures: IMultiTenantFeatureFlags) =>
  (tenant: TenantWithNameAndRegion): TenantWithFeaturesNameAndRegion | undefined => {
    const features = tenantFeatures.successes[tenant.tenantId]
    if (!features) {
      return undefined
    }
    return {
      ...tenant,
      featureFlags: features
    }
  }

export const getFeaturesForTenants = async (
  tenants: TenantWithNameAndRegion[]
): Promise<TenantWithFeaturesNameAndRegion[]> => {
  const tenantsByRegion = _.groupBy<TenantWithNameAndRegion>(tenants, 'apiUrl')
  const featureRequests = Object.keys(tenantsByRegion).map(
    async (apiUrl): Promise<TenantWithFeaturesNameAndRegion[]> => {
      const regionTenants = tenantsByRegion[apiUrl]
      const tenantIds = regionTenants.map(tenant => tenant.tenantId)
      const tenantFeatures = await getMultipleTenantFeatures(tenantIds, apiUrl)
      const tenantsWithFeatures = regionTenants.map(combineTenantsWithFeatures(tenantFeatures))
      return _.compact(tenantsWithFeatures)
    }
  )
  const tenantsAndFeaturesByRegion = await Promise.all(featureRequests)
  return _.flatten(tenantsAndFeaturesByRegion)
}

const title = 'Feature Flag Report'

export const FeatureFlagReport = () => {
  const [featureFlag, setFeatureFlag] = useState<string | undefined>()
  const [showEnabled, setShowEnabled] = useState<boolean>(true)
  const [showCustomerOnly, setShowCustomerOnly] = useState<boolean>(false)
  const [showProductionOnly, setShowProductionOnly] = useState<boolean>(false)
  const { data: tenantsData, refetch } = useGetTenantsInfoQuery()
  const { data, isFetching } = useGetFeatureFlagReportQuery(tenantsData)
  const aggr = aggregateTenantFeatures(data || [])
  const { setDocumentTitle } = useDocumentTitle(title)

  useEffect(() => {
    setDocumentTitle(title)
  }, [])

  return (
    <PageContent>
      {featureFlag && <Icon name="backward" onClick={() => setFeatureFlag(undefined)} className="clickable" />}
      <h2>{featureFlag || 'Feature flag report'}</h2>

      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Toggles>
            {featureFlag && (
              <Checkbox
                toggle
                label="Show Customer Tenants Only"
                checked={showCustomerOnly}
                onClick={() => setShowCustomerOnly(prevState => !prevState)}
                style={{ padding: '5px' }}
              />
            )}
            {featureFlag && (
              <Checkbox
                toggle
                label="Show Production Tenants Only"
                checked={showProductionOnly}
                onClick={() => setShowProductionOnly(prevState => !prevState)}
                style={{ padding: '5px' }}
              />
            )}
            {featureFlag && (
              <Checkbox
                toggle
                label="Show Enabled"
                checked={showEnabled}
                onClick={() => setShowEnabled(prevState => !prevState)}
                style={{ padding: '5px' }}
              />
            )}
          </FiltersTogglesButtons.Toggles>
          <FiltersTogglesButtons.Buttons>
            <Button size="large" color="blue" content="Fetch data" onClick={() => refetch()} loading={isFetching} />
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>

        {featureFlag ? (
          <FeatureEnabledForTenantList
            tenants={data || []}
            featureFlag={featureFlag}
            checkEnabled={showEnabled}
            showCustomerOnly={showCustomerOnly}
            showProductionOnly={showProductionOnly}
          />
        ) : (
          <FeatureFlagList
            tenants={data || []}
            featureFlagAggregate={aggr}
            loading={isFetching}
            setFeatureFlag={setFeatureFlag}
          />
        )}
      </PageContent.Main>
    </PageContent>
  )
}
