import _ from 'lodash'
import { Divider, Icon } from 'semantic-ui-react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router'
import { IApplication } from '../../actions/Applications'

import { ApplicationsList } from '../applications/components/ApplicationsList'
import { DeployApplicationModal } from '../deployments/components/DeployApplicationModal'
import { useRouterProps } from '../../router/RouterProps'
import { useDeploymentModal } from '../../hooks/useDeploymentModal'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useReleaseQuery } from '../../queries/releases/GetReleaseQuery'
import { useApplicationsQuery } from '../../queries/applications/GetApplicationsQuery'
import {
  usePreviousReleaseQuery,
  useReleaseApplicationQuery,
  useReleasesForVersionQuery,
  useReleaseTicketsQuery
} from '../../queries/releases/GetReleaseApplicationsQuery'
import { useGetReleaseNotesPageQuery } from '../../queries/releaseNotes/GetReleaseNotesQuery'
import { useDeploymentEnvironmentsQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { PageContent } from '../../components/react/PageContent'
import { FiltersTogglesButtons } from '../../components/react/FiltersTogglesButtons'
import { Breadcrumb } from '../../components/react/Breadcrumbs'
import { TicketsList } from './Components/TicketsList'
import { ReleasesList } from './Components/ReleasesList'
import { ReleaseInformation } from './Components/ReleaseInformation'
import { DeployReleaseModal } from './Components/DeployReleaseModal'
import { CompleteReleaseModal } from './Components/CompleteReleaseModal'

export const ReleaseDetails = () => {
  const routerProps = useRouterProps()
  const modal = useDeploymentModal()

  const applicationName = routerProps.params.applicationName!
  const version = routerProps.params.version!
  const [searchParams, setSearchParams] = useSearchParams()

  const { setDocumentTitle } = useDocumentTitle('Release Details')
  const { data: release, status: releaseStatus } = useReleaseQuery(applicationName, version)
  const { data: application, status: applicationStatus } = useReleaseApplicationQuery(release?.applicationName)
  const { data: prevRelease, status: prevReleaseStatus } = usePreviousReleaseQuery(
    release?.applicationName,
    release?.version
  )
  const { data: otherReleases, status: otherReleasesStatus } = useReleasesForVersionQuery(release?.version)
  const { data: tickets, status: ticketsStatus } = useReleaseTicketsQuery(
    release?.version,
    application?.component,
    application?.applicationName
  )
  const { data: deploymentEnvironments, status: deploymentEnvironmentsStatus } = useDeploymentEnvironmentsQuery()
  const { data: releaseNotesPage, status: releaseNotesPageStatus } = useGetReleaseNotesPageQuery(
    application?.component,
    release?.version
  )
  const { data: runningApplications, status: runningApplicationsStatus } = useApplicationsQuery()

  const loadingTableData =
    releaseStatus === 'loading' ||
    applicationStatus === 'loading' ||
    prevReleaseStatus === 'loading' ||
    otherReleasesStatus === 'loading' ||
    ticketsStatus === 'loading' ||
    deploymentEnvironmentsStatus === 'loading' ||
    releaseNotesPageStatus === 'loading' ||
    runningApplicationsStatus === 'loading'

  useEffect(() => {
    if (application !== undefined) {
      setDocumentTitle(`${application?.applicationName} Release Details`)
    }
  }, [application, setDocumentTitle])

  let deployable = false
  let deploymentName = ''

  if (release && application && deploymentEnvironments !== undefined) {
    const deploymentTemplates = _.flatten(
      deploymentEnvironments.map(environment => environment.deploymentTemplates)
    ).filter(template => template.applicationName === application.applicationName)
    if (deploymentTemplates.length !== 0) {
      deploymentName = deploymentTemplates[0].name
      const deployableEnvironments = deploymentEnvironments
        .filter(environment =>
          _.some(environment.deploymentTemplates, {
            name: deploymentName,
            applicationName: application.applicationName
          })
        )
        .map(environment => environment.name)
      if (deployableEnvironments.includes('prod') && deployableEnvironments.includes('staging')) {
        deployable = true
      }
    }
  }

  if (deploymentName && !searchParams) {
    setSearchParams(`Application=${deploymentName}`)
  }

  const handleRedeploy = (app: IApplication) => () => {
    modal.populateDeployment({
      application: app.name,
      env: app.environment,
      instance: app.instance,
      tag: app.meta.tag
    })
    modal.setModalOpen(true)
  }

  const createDetailsLink = (app: string, ver: string) => `/applications/releases/${app}/${ver}`

  return (
    <PageContent>
      <Breadcrumb>
        <Breadcrumb.Section className="back-button" onClick={() => routerProps.navigate(-1)}>
          <Icon name="chevron left" size="big" />
          Back
        </Breadcrumb.Section>
      </Breadcrumb>
      <PageContent.Main>
        <FiltersTogglesButtons>
          <FiltersTogglesButtons.Buttons>
            <DeployReleaseModal release={release} deployable={deployable} />
            {!release?.isCD && <CompleteReleaseModal release={release} />}
          </FiltersTogglesButtons.Buttons>
        </FiltersTogglesButtons>
        <ReleaseInformation
          release={release}
          prevRelease={prevRelease}
          application={application}
          runningApplications={runningApplications}
          releaseNotesPage={releaseNotesPage}
          deploymentName={deploymentName}
          deployable={deployable}
          loading={loadingTableData}
        />

        {!release?.isCD && ( // don't show for CD
          <div>
            <h3 style={{ flexGrow: 0 }}>Tickets</h3>
            <TicketsList tickets={tickets} loading={ticketsStatus === 'loading'} />
            <Divider />
          </div>
        )}
        <h3>Running Versions</h3>
        <ApplicationsList
          applications={runningApplications}
          simple
          filterCategories={
            new Map([['name', { text: 'Application', values: runningApplications?.map(app => app.name) }]])
          }
          handleRedeploy={handleRedeploy}
          loading={runningApplicationsStatus === 'loading'}
        />
        <DeployApplicationModal modal={modal} />
        <Divider />
        <h3>Other Releases for This Version</h3>
        <ReleasesList
          releases={otherReleases || []}
          loading={otherReleasesStatus === 'loading'}
          searchValue={{ searchText: '', searchCategory: '' }}
          createDetailsLink={createDetailsLink}
          simple
        />
      </PageContent.Main>
    </PageContent>
  )
}
