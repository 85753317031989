import { useMemo } from 'react'
import { find } from 'lodash'
import { useGetCustomerConnectionsQuery } from '../../../queries/GetCustomerConnectionsQuery'
import { getTeamsList } from '../services/CustomerTeamsService'
import { FiltersTogglesButtons } from '../../../components/react/FiltersTogglesButtons'
import { CustomerLoginOptionsList } from './CustomerLoginOptionsList'
import AddLoginOptionsForm from './LoginOptions/AddLoginOptionsForm'

export const CustomerLoginOptions = ({ customerId }: { customerId: string }) => {
  const { data: customerConnections, isLoading } = useGetCustomerConnectionsQuery(customerId)
  const teams = getTeamsList(customerId)

  const connectionWithTeamData = useMemo(
    () =>
      customerConnections?.map(connection => ({
        ...connection,
        teams: teams?.filter(team => find(team.authConnections, { connectionId: connection.id }))
      })) ?? [],
    [customerConnections, teams]
  )

  return (
    <div>
      <FiltersTogglesButtons>
        <FiltersTogglesButtons.Filters>
          <h3>Login options</h3>
        </FiltersTogglesButtons.Filters>
        <FiltersTogglesButtons.Buttons>
          <AddLoginOptionsForm buttonText="Add login option" title="Add login option" customerId={customerId} />
        </FiltersTogglesButtons.Buttons>
      </FiltersTogglesButtons>
      <CustomerLoginOptionsList data={connectionWithTeamData} loading={isLoading} />
    </div>
  )
}
