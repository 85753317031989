import { useState } from 'react'
import { Icon, Loader, Button, Table, SemanticICONS } from 'semantic-ui-react'
import dayjs from 'dayjs'

import { DeliveryCheckType, IDeliveryCheck } from '../../../actions/DeliveryCheck'
import { DeliveryStatus } from '../../../actions/Deliveries'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { DeliveryCheckMetadataModal } from './DeliveryCheckMetadataModal'
import { DeliveryCheckStatusCount, DeliveryCheckStatusIcon } from './DeliveryCheckStatusCount'

const checkIconsByCheckType: { [key in DeliveryCheckType]: SemanticICONS } = {
  ansible_config_check: 'file',
  delivery_dependency_check: 'exchange',
  delivery_tag_exists_in_ecr_check: 'docker',
  db_migration_check: 'database'
}

interface IProps {
  deliveryStatus?: DeliveryStatus
  pending: number
  failed: number
  expired: number
  warning: number
  passed: number
  loading: boolean
  deliveryChecks: IDeliveryCheck[]
  handleRerunCheck: () => void
  isExpired: (expiredAt: string) => boolean
}

export const DeliveriesChecksInfo = (props: IProps) => {
  const [open, setOpen] = useState(false)
  // use successfulState to work out when successful changes, and then once and once only set
  // open to match that change. Otherwise, we need to respect the onclick toggle
  const [successfulState, setSuccessfulState] = useState(true)

  const successful = !(props.failed || props.expired || props.pending || props.warning)

  if (successful !== successfulState) {
    setSuccessfulState(successful)
    setOpen(true)
  }
  const shouldRerunDeliveryChecks = (): boolean =>
    props.deliveryStatus !== 'approved' && props.deliveryStatus !== 'rejected' && props.deliveryChecks.length > 0

  return (
    <details open={open}>
      <summary>
        <h3>
          Delivery Checks&nbsp;&nbsp;&nbsp;
          <Loader active={props.loading} inline />
          <DeliveryCheckStatusCount status="failed" count={props.failed} />
          <DeliveryCheckStatusCount status="expired" count={props.expired} />
          <DeliveryCheckStatusCount status="created" count={props.pending} />
          <DeliveryCheckStatusCount status="warning" count={props.warning} />
          <DeliveryCheckStatusCount status="passed" count={props.passed} />
        </h3>

        {shouldRerunDeliveryChecks() ? (
          <Button
            icon="redo"
            className="primary right floated"
            onClick={e => {
              e.stopPropagation()
              props.handleRerunCheck()
            }}
            content="Re-run all"
          />
        ) : null}
      </summary>
      <div className="scrollable">
        <Table striped color="blue" size="small" stackable sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="four wide" textAlign="center" colSpan="2">
                Name
              </Table.HeaderCell>
              <Table.HeaderCell className="six wide" textAlign="center">
                Result
              </Table.HeaderCell>
              <Table.HeaderCell className="one wide" textAlign="center">
                Details
              </Table.HeaderCell>
              <Table.HeaderCell className="one wide" textAlign="center">
                Status
              </Table.HeaderCell>
              <Table.HeaderCell className="three wide" textAlign="center">
                Expiry
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <TableLoaderWrap loading={props.loading} array={props.deliveryChecks} emptyMessage="No Delivery Checks">
            <Table.Body>
              {props.deliveryChecks.map(check => (
                <Table.Row key={check.id}>
                  <Table.Cell textAlign="center">
                    <Icon name={checkIconsByCheckType[check.checkType]} size="large" />
                  </Table.Cell>
                  <Table.Cell textAlign="left">{check.name}</Table.Cell>
                  <Table.Cell textAlign="left">{check.details}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {check.metadata ? (
                      <DeliveryCheckMetadataModal
                        deliveryCheckData={check.metadata}
                        deliveryCheckName={check.name}
                        key={check.id}
                      />
                    ) : null}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DeliveryCheckStatusIcon
                      status={check.status}
                      expired={props.isExpired(check.expiresAt)}
                      id={check.id}
                    />
                  </Table.Cell>
                  <Table.Cell className="table-cell-overflow">
                    {(check.expiresAt && dayjs(check.expiresAt).format('MMMM D YYYY, h:mm:ss a')) || check.expiresAt}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </TableLoaderWrap>
        </Table>
      </div>
    </details>
  )
}
