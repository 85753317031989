import { ReactNode } from 'react'

export const PageHeader = (props: { children: ReactNode; className: string }) => (
  <nav className={`page-header ${props.className}`}>{props.children}</nav>
)

const Links = (props: { children: ReactNode }) => <ul className="page-header-links">{props.children}</ul>

const Logo = (props: { children: ReactNode }) => <div className="page-header-logo">{props.children}</div>

const User = (props: { children: ReactNode }) => <div className="page-header-user">{props.children}</div>

PageHeader.Links = Links
PageHeader.Logo = Logo
PageHeader.User = User

export default { PageHeader }
