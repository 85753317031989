import * as React from 'react'
import { Loader, Table } from 'semantic-ui-react'

interface ITableLoaderProps {
  loading: boolean
  array: any[]
  children: JSX.Element
  emptyMessage: string
}

export const TableLoaderWrap: React.FC<ITableLoaderProps> = props =>
  props.array.length > 0 ? (
    props.children
  ) : (
    <Table.Footer>
      <Table.Row key={1}>
        <Table.Cell colSpan="16" textAlign="center">
          {props.loading ? (
            <Loader active={props.loading} inline />
          ) : (
            <h4 className="centered">{props.emptyMessage}</h4>
          )}
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  )
