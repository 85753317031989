import { Image, Popup } from 'semantic-ui-react'

export const RegionFlag = ({ region, width }: { region?: string; width?: string }) => {
  const regionLower = region?.toLowerCase()
  // use a lookup rather than constructing the URL so that parcel knows to include the images in the bundle
  const flags: { [key: string]: URL } = {
    au: new URL('../images/flag-au.png', import.meta.url),
    ca: new URL('../images/flag-ca.png', import.meta.url),
    uk: new URL('../images/flag-uk.png', import.meta.url),
    us: new URL('../images/flag-us.png', import.meta.url)
  }

  if (regionLower && Object.keys(flags).includes(regionLower)) {
    return <Image src={flags[regionLower].href} verticalAlign="top" width={width || '32px'} id={`flag-${region}`} />
  } else {
    return <></>
  }
}

export const RegionFlagPopup = ({ region }: { region?: string }) => (
  <Popup
    content={region}
    position="top left"
    trigger={
      <div>
        <RegionFlag region={region} />
      </div>
    }
    style={{
      opacity: 0.9,
      textAlign: 'center'
    }}
    inverted
  />
)
