import { Button, Dropdown, Icon, Loader } from 'semantic-ui-react'
import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { DeliveryStatus } from '../../../actions/Deliveries'
import { ICommitInformation } from '../../../actions/Commits'
import { DeliveryCommitsList } from './DeliveryCommitsList'

export interface IProps {
  environmentVersions?: { [name: string]: string }
  owner?: string
  repository?: string
  version?: string
  status?: DeliveryStatus
  title: string
  getListOfCommits(baseVersion: string, headVersion?: string): Promise<ICommitInformation[]>
}

export const DeliveryCommits = (props: IProps) => {
  const [compareInstance, setCompareInstance] = useState('')

  useEffect(() => {
    if (props.status === 'pending' && !compareInstance) {
      setCompareInstance('us1-prod')
    }
  }, [compareInstance, props.status])

  const environmentVersion = props.environmentVersions?.[compareInstance]

  const { data: deliveryCommits, fetchStatus: deliveryFetchStatus } = useQuery({
    queryKey: ['deliveryCommits', props.repository!, environmentVersion!, props.version],
    queryFn: () => props.getListOfCommits(environmentVersion!, props.version!),
    enabled: props.status === 'pending' && !!environmentVersion && !!props.version && !!props.repository
  })

  const gitHubCompareURL = () =>
    environmentVersion &&
    `https://github.com/${props.owner}/${props.repository}/compare/${environmentVersion}..${props.version}`

  const environmentDropdownOptions =
    props.environmentVersions &&
    Object.keys(props.environmentVersions).map(env => ({
      key: env,
      value: env,
      text: env
    }))

  const loading = deliveryFetchStatus === 'fetching' || !props.repository

  return (
    <details>
      <summary>
        <h3>
          {environmentDropdownOptions && environmentDropdownOptions.length > 0 ? (
            <>
              {props.title} commits to be added to{' '}
              <Dropdown
                icon={
                  <Icon name="dropdown" />
                  // setting transform overwrites the transform inherited from active accordion title
                }
                value={compareInstance}
                onChange={(_ev, data) => setCompareInstance(data.value as string)}
                options={environmentDropdownOptions}
                onClick={evt => {
                  evt.stopPropagation()
                }}
              />
            </>
          ) : (
            `${props.title}: ${props.status === 'pending' ? 'No environments found to compare' : 'Delivery complete'}`
          )}
        </h3>

        {props.status === 'pending' && (
          <Button
            basic
            compact
            icon
            labelPosition="left"
            href={gitHubCompareURL()}
            target="_blank"
            name="compare_button"
            onClick={evt => {
              evt.stopPropagation()
            }}
          >
            <Icon name="github" />
            Compare to {compareInstance || <Loader active inline size="tiny" />}
          </Button>
        )}
      </summary>
      <DeliveryCommitsList
        loading={loading}
        commits={deliveryCommits || []}
        repository={props.repository}
        emptyMessage={
          props.status !== 'pending'
            ? 'This delivery has been completed.'
            : `No differences between ${props.version} and ${compareInstance} (${environmentVersion})`
        }
        label={`${props.title} Delivery Commits`}
      />
    </details>
  )
}
