import { useState } from 'react'
import { Loader, Message } from 'semantic-ui-react'
import { DeploymentsList } from '../../deployments/components/DeploymentsList'
import { IDeployment } from '../../../actions/Deployments'
import { DeliveryDeploymentStatusCount } from './DeliveryDeploymentStatusCount'

export interface ISimpleInstace {
  environment: string
  instance: string
}

interface IProps {
  deployments?: IDeployment[]
  loading: boolean
  failed: ISimpleInstace[]
  pending: ISimpleInstace[]
  running: ISimpleInstace[]
  missing: ISimpleInstace[]
  successful: ISimpleInstace[]
}

const listInstances = (instances: ISimpleInstace[]) =>
  instances.map((inst, idx) => (
    <span key={idx}>
      {idx ? ', ' : ''}
      <strong style={{ fontWeight: 'bold' }}>{`${inst.instance}-${inst.environment}`}</strong>
    </span>
  )) // strong won't good bold in the message (sets to 'bolder' not 'bold')

export const DeliveriesDeploymentInfo = (props: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  // use successfulState to work out when successful changes, and then once and once only set
  // open to match that change. Otherwise, we need to respect the onclick toggle
  const [successfulState, setSuccessfulState] = useState(true)
  const successful = props.failed.length + props.missing.length + props.pending.length + props.running.length === 0
  if (successful !== successfulState) {
    setSuccessfulState(successful)
    setOpen(!successful)
  }

  return (
    <details open={open}>
      <summary>
        <h3 aria-label="Deployment Summary">
          Deployments&nbsp;&nbsp;&nbsp;
          <Loader active={props.loading} inline />
          <DeliveryDeploymentStatusCount status="failed" count={props.failed.length} />
          <DeliveryDeploymentStatusCount status="missing" count={props.missing.length} />
          <DeliveryDeploymentStatusCount status="running" count={props.pending.length + props.running.length} />
          <DeliveryDeploymentStatusCount status="successful" count={props.successful.length} />
        </h3>
      </summary>
      {props.missing.length > 0 && (
        <Message warning aria-label="Missing Deployments">
          {
            // prettier-ignore
            'Deployments of this build missing from this application\'s deployment template: '
          }
          {listInstances(props.missing)}
        </Message>
      )}
      {props.failed.length > 0 && (
        <Message negative>
          {'Deployments of this build failed: '}
          {listInstances(props.failed)}
        </Message>
      )}
      {props.pending.length > 0 && (
        <Message negative>
          {'Deployments of this build still pending: '}
          {listInstances(props.pending)}
        </Message>
      )}
      <DeploymentsList
        loading={props.loading}
        deployments={props.deployments || []}
        filterCategories={new Map()}
        displayLocalTime
        showConfigVersion
        hideApplication
      />
    </details>
  )
}
