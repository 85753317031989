import { Route, Routes } from 'react-router'
import * as React from 'react'
import Login from '../views/Login'
import { RedirectCallback } from '../auth/RedirectCallback'
import Landing from '../views/Landing'
import { NewFeatureFlagReport } from '../views/newTeams/NewFeatureFlagReport'
import Customers from '../views/customers/Customers'
import CustomerDetails from '../views/customers/CustomerDetails'
import Teams from '../views/teams/Teams'
import TeamDetails from '../views/teams/TeamDetails'
import { Tenants } from '../views/tenants/Tenants'
import { FeatureFlagReport } from '../views/tenants/FeatureFlagReport'
import { UsersReportForTenants } from '../views/tenants/UsersReportForTenants'
import { TenantDetails } from '../views/tenants/TenantDetails'
import { Deployments } from '../views/deployments/Deployments'
import { DeploymentDetails } from '../views/deployments/DeploymentDetails'
import { Releases } from '../views/releases/Releases'
import { ReleaseDetails } from '../views/releases/ReleaseDetails'
import { MobileReleases } from '../views/mobileReleases/MobileReleases'
import { MobileReleaseDetails } from '../views/mobileReleases/MobileReleaseDetails'
import { Deliveries } from '../views/deliveries/Deliveries'
import { DeliveryDetails } from '../views/deliveries/DeliveryDetails'
import { Settings } from '../views/settings/Settings'
import MyDetailsPage from '../views/myDetails/MyDetailsPage'
import { RequireAuth } from '../App'
import { Searchable } from '../views/Searchable'
import { NewCustomers } from '../views/newCustomers/NewCustomers'
import { NewCustomerDetails } from '../views/newCustomers/NewCustomerDetails'
import { CustomerLoginOptionsDetails } from '../views/newCustomers/CustomerLoginOptionsDetails'
import { NewTeams } from '../views/newTeams/NewTeams'
import { NewTeamDetails } from '../views/newTeams/NewTeamDetail'
import { Applications } from '../views/applications/Applications'
import { ApplicationCompare } from '../views/applications/ApplicationCompare'
import { ApplicationDetails } from '../views/applications/ApplicationDetails'
import GlobalPackageList from '../views/package/GlobalPackageList'
import { PackageDetails } from '../views/package/PackageDetails'
import { PackageVersionsDetails } from '../views/package/PackageVersionsDetails'
import { UsersReport } from '../views/newTeams/UsersReport'
import { FunctionDetails } from '../views/applications/FunctionDetails'
import { LegacyRouter } from './RouterProps'

const NoMatch = () => (
  <React.Fragment>
    <h1> No Match </h1>
  </React.Fragment>
)

export const AppRoutes = (): React.ReactElement => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/callback" element={<RedirectCallback />} />
    {/* Landing sites */}
    <Route
      path="/"
      element={
        <RequireAuth>
          <Landing />
        </RequireAuth>
      }
    />

    <Route
      path="/applications"
      element={
        <RequireAuth>
          <Landing />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model"
      element={
        <RequireAuth>
          <Landing />
        </RequireAuth>
      }
    />

    {/* Home / New model */}
    <Route
      path="/customers"
      element={
        <RequireAuth>
          <NewCustomers />
        </RequireAuth>
      }
    />
    <Route
      path="/customers/:id"
      element={
        <RequireAuth>
          <NewCustomerDetails />
        </RequireAuth>
      }
    />
    <Route
      path="/customers/:customerId/login-options/:id"
      element={
        <RequireAuth>
          <CustomerLoginOptionsDetails />
        </RequireAuth>
      }
    />
    <Route
      path="/teams"
      element={
        <RequireAuth>
          <NewTeams />
        </RequireAuth>
      }
    />
    <Route
      path="/teams/:id"
      element={
        <RequireAuth>
          <NewTeamDetails />
        </RequireAuth>
      }
    />
    <Route
      path="/users-report"
      element={
        <RequireAuth>
          <UsersReport />
        </RequireAuth>
      }
    />
    <Route
      path="/feature-flag-report"
      element={
        <RequireAuth>
          <NewFeatureFlagReport />
        </RequireAuth>
      }
    />
    <Route
      path="/feature-flag-report/:id"
      element={
        <RequireAuth>
          <NewFeatureFlagReport />
        </RequireAuth>
      }
    />

    <Route
      path="/packages"
      element={
        <RequireAuth>
          <GlobalPackageList />
        </RequireAuth>
      }
    />

    <Route
      path="/packages/:id"
      element={
        <RequireAuth>
          <PackageDetails />
        </RequireAuth>
      }
    />

    <Route
      path="/packages/:id/:version"
      element={
        <RequireAuth>
          <PackageVersionsDetails />
        </RequireAuth>
      }
    />

    {/* Legacy / Old model */}
    <Route
      path="/old-model/customers"
      element={
        <RequireAuth>
          <Searchable Component={Customers} searchValueName="customer" />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/customers/:id"
      element={
        <RequireAuth>
          <LegacyRouter Component={CustomerDetails} />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/teams"
      element={
        <RequireAuth>
          <Searchable Component={Teams} searchValueName="team" />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/teams/:id"
      element={
        <RequireAuth>
          <LegacyRouter Component={TeamDetails} />
        </RequireAuth>
      }
    />

    <Route
      path="/old-model/tenants"
      element={
        <RequireAuth>
          <Searchable Component={Tenants} searchValueName="tenant" />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/feature-flag-report"
      element={
        <RequireAuth>
          <FeatureFlagReport />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/users-report"
      element={
        <RequireAuth>
          <UsersReportForTenants />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/tenants/:id"
      element={
        <RequireAuth>
          <LegacyRouter Component={TenantDetails} />
        </RequireAuth>
      }
    />

    <Route
      path="/applications/applications"
      element={
        <RequireAuth>
          <Applications />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/applications/compare"
      element={
        <RequireAuth>
          <ApplicationCompare />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/applications/:environment/:instance/:name"
      element={
        <RequireAuth>
          <ApplicationDetails />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/function/:environment/:instance/:name"
      element={
        <RequireAuth>
          <FunctionDetails />
        </RequireAuth>
      }
    />

    <Route
      path="/applications/deployments"
      element={
        <RequireAuth>
          <Deployments />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/deployments/:env/:instance/:id"
      element={
        <RequireAuth>
          <DeploymentDetails />
        </RequireAuth>
      }
    />

    <Route
      path="/applications/releases"
      element={
        <RequireAuth>
          <Releases />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/releases/:applicationName/:version"
      element={
        <RequireAuth>
          <ReleaseDetails />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/mobile-releases"
      element={
        <RequireAuth>
          <Searchable Component={MobileReleases} searchValueName="mobileReleases" />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/mobile-releases/:applicationName/:version"
      element={
        <RequireAuth>
          <LegacyRouter Component={MobileReleaseDetails} />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/deliveries"
      element={
        <RequireAuth>
          <Searchable Component={Deliveries} searchValueName="deliveries" />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/deliveries/:applicationName/:version"
      element={
        <RequireAuth>
          <DeliveryDetails />
        </RequireAuth>
      }
    />

    <Route
      path="/applications/settings/:setting"
      element={
        <RequireAuth>
          <Settings />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/settings"
      element={
        <RequireAuth>
          <Settings />
        </RequireAuth>
      }
    />
    <Route
      path="/my-details"
      element={
        <RequireAuth>
          <MyDetailsPage />
        </RequireAuth>
      }
    />
    <Route
      path="/applications/my-details"
      element={
        <RequireAuth>
          <MyDetailsPage />
        </RequireAuth>
      }
    />
    <Route
      path="/old-model/my-details"
      element={
        <RequireAuth>
          <MyDetailsPage />
        </RequireAuth>
      }
    />

    <Route path="*" element={<NoMatch />} />
  </Routes>
)
