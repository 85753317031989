import { Button, Table } from 'semantic-ui-react'
import { Link } from 'react-router'
import { TenantWithFeaturesNameAndRegion } from '../FeatureFlagReport'
import { sortData } from '../../../views/tableComponents/TableFunctions'

export interface IProps {
  tenants: TenantWithFeaturesNameAndRegion[]
  featureFlag: string
  checkEnabled: boolean
  showCustomerOnly: boolean
  showProductionOnly: boolean
}

export const FeatureEnabledForTenantList = (props: IProps) => {
  const displayData = sortData(
    props.tenants
      .filter(tenant => !props.showCustomerOnly || tenant.category === 'Customer')
      .filter(tenant => !props.showProductionOnly || tenant.environment === 'Production')
      .filter(tenant => {
        const enabled = tenant.featureFlags.find(flag => flag.name === props.featureFlag)?.value
        return (enabled && props.checkEnabled) || (!enabled && !props.checkEnabled)
      }),
    'teamName',
    'ascending'
  )

  return (
    <>
      <small>{displayData.length} tenants</small>
      <div className="scrollable">
        <Table stackable size="small" color="blue" striped={true} className={'sticky-table'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Team name</Table.HeaderCell>
              <Table.HeaderCell>Tenant ID</Table.HeaderCell>
              <Table.HeaderCell>Tenant category</Table.HeaderCell>
              <Table.HeaderCell>Tenant environment</Table.HeaderCell>
              <Table.HeaderCell>Tenant information</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {displayData.map(tenant => (
              <Table.Row key={tenant.tenantId}>
                <Table.Cell>{tenant.teamName}</Table.Cell>
                <Table.Cell>{tenant.tenantId}</Table.Cell>
                <Table.Cell>{tenant.category}</Table.Cell>
                <Table.Cell>{tenant.environment}</Table.Cell>
                <Table.Cell>
                  <Button icon="file code" as={Link} to={`/old-model/tenants/${tenant.tenantId}`} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}
