import { Grid, Header, Label, Loader, Table, Popup, Image } from 'semantic-ui-react'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router'
import { Customer } from '../../../actions/NewCustomers'
import { DynamoQueryResponse } from '../../../actions/Utils'
import { useCustomerTeamsService } from '../../newCustomers/services/CustomerTeamsService'
import { Team } from '../../../actions/NewTeams'
import { prefetchGetTeamQuery } from '../../../queries/GetTeamQuery'
import { salesforceLogo } from '../../../images/icons'
import { environmentColours } from './NewTeamsList'

export interface NewTeamNamesListProps {
  customer: Customer | undefined
  teamId: string
}

export const addSalesforceIcon = (isSalesforce: boolean) => {
  if (isSalesforce) {
    return (
      <Popup
        content="Salesforce Team"
        position="top center"
        trigger={
          <Image
            inline={true}
            id="salesforce-logo"
            src={salesforceLogo.href}
            size="mini"
            spaced="left"
            style={{ width: 20 }}
          />
        }
        style={{
          opacity: 0.9,
          textAlign: 'center'
        }}
        inverted
      />
    )
  }
  return null
}

export const NewTeamNamesList = ({ customer, teamId }: NewTeamNamesListProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const customerTeamsService = useCustomerTeamsService(customer?.id as string)({})
  const { data: allTeamsForCustomer } = customerTeamsService
  const [searchParams] = useSearchParams()

  const urlForTeam = (id?: string) => {
    const tab = searchParams.get('tab')
    const tabQueryString = tab ? `?tab=${tab}` : ''
    return `/teams/${id}${tabQueryString}`
  }

  const renderTeamsPerPage = (page: DynamoQueryResponse<Team>) =>
    page.results.map((teamForCustomer: Team) => (
      <Table.Row
        key={`teamlist-${teamForCustomer?.id}`}
        style={{ cursor: 'pointer' }}
        active={teamForCustomer?.id === teamId}
        onMouseOver={() => prefetchGetTeamQuery(queryClient, teamForCustomer?.id)}
        onClick={() => {
          navigate(urlForTeam(teamForCustomer?.id), { replace: true })
        }}
      >
        <Table.Cell>
          <p>
            {' '}
            {teamForCustomer.primaryName}
            {addSalesforceIcon(teamForCustomer.isSalesforce)}
          </p>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Label color={environmentColours[teamForCustomer.environment] ?? 'grey'} basic>
            {teamForCustomer.environment}
          </Label>
        </Table.Cell>
      </Table.Row>
    ))

  return (
    <Grid.Column data-testid="teams-list" width={4} style={{ height: '100%' }}>
      <Header>Teams</Header>
      <Table singleLine stackable size="small" selectable className="scrollable">
        <Table.Body>
          {allTeamsForCustomer ? (
            allTeamsForCustomer?.pages.map(teamsCustomerPage => renderTeamsPerPage(teamsCustomerPage))
          ) : (
            <Table.Row>
              <Table.Cell textAlign="center">
                <Loader active={true} inline />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  )
}
