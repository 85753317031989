import { ReactNode } from 'react'

const UserCount = (props: { children: ReactNode }) => <div className="uc">{props.children}</div>

const Value = (props: { children: ReactNode }) => <div className="uc-value">{props.children}</div>
UserCount.Value = Value

const Label = (props: { children: ReactNode }) => <div className="uc-label">{props.children}</div>
UserCount.Label = Label

const Item = (props: { children: ReactNode }) => <div className="uc-item">{props.children}</div>
UserCount.Item = Item

const Limit = (props: { children: ReactNode }) => <div className="uc-limit">{props.children}</div>
UserCount.Limit = Limit

export { UserCount }
