import React, { JSXElementConstructor, useEffect } from 'react'
import { Tab, Message, Dropdown, Menu, TabPane } from 'semantic-ui-react'
import { startCase, toLower } from 'lodash'
import { Customer } from 'src/actions/NewCustomers'
import { useSearchParams } from 'react-router'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import DynamicBreadcrumbs from '../../components/Breadcrumbs/DynamicBreadcrumbs'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useTabLinkingService } from '../../services/TabLinkingService'
import { useRouterProps } from '../../router/RouterProps'
import { PageContent } from '../../components/react/PageContent'
import { Breadcrumb } from '../../components/react/Breadcrumbs'
import { NewCustomerInformation } from './component/NewCustomerInformation'
import { CustomerTeamsList } from './component/CustomerTeamsList'
import { CustomerMetadataTable } from './component/CustomerMetadataTable'
import { NewDeleteCustomerModal } from './component/NewDeleteCustomerModal'
import { CustomerUsers } from './component/CustomerUsers'
import { CustomerLoginOptions } from './component/CustomerLoginOptions'
import { CustomerAccountData } from './component/CustomerAccountData'

const panes: { [x: string]: JSXElementConstructor<{ customerId: string }> } = {
  Teams: CustomerTeamsList,
  Metadata: CustomerMetadataTable,
  Users: CustomerUsers,
  LoginOptions: CustomerLoginOptions,
  AccountData: CustomerAccountData
}

const makePane = (customerId: string) => (name: string, Component: JSXElementConstructor<{ customerId: string }>) => {
  const startCaseName = startCase(name)
  const sentenceCaseName = startCaseName.charAt(0) + toLower(startCaseName.slice(1))

  return {
    menuItem: sentenceCaseName,
    render: () => (
      <TabPane>
        <Component customerId={customerId} />
      </TabPane>
    )
  }
}

const getPanes = (customerId: string) =>
  Object.keys(panes).map(paneName => makePane(customerId)(paneName, panes[paneName]))

const isBannerVisible = (urlSearchParams: URLSearchParams): boolean => urlSearchParams.get('banner') === 'true'

export const filterTabs = (tabs: string[], customer: Customer | undefined): string[] => {
  if (!customer?.metadata?.nelx) {
    tabs.splice(tabs.indexOf('LoginOptions'), 1)
  }

  if (!customer?.salesforceId) {
    tabs.splice(tabs.indexOf('AccountData'), 1)
  }

  return tabs
}

export const NewCustomerDetails = () => {
  const routerProps = useRouterProps()
  const [urlSearchParams] = useSearchParams()

  const customerId = routerProps.params.id!

  const [redirect, setRedirect] = React.useState(false)
  const [banner, setBanner] = React.useState(isBannerVisible(urlSearchParams))
  const { data: customer } = useGetCustomerQuery(customerId)
  const { setDocumentTitle } = useDocumentTitle(customer?.name)
  const filteredPanes = getPanes(customerId).filter(
    pane =>
      (pane.menuItem === 'Login options' && customer?.metadata?.nelx === true) ||
      (pane.menuItem === 'Account data' && customer?.salesforceId) ||
      (pane.menuItem !== 'Login options' && pane.menuItem !== 'Account data')
  )

  useEffect(() => {
    if (customer?.name) {
      setDocumentTitle(customer.name)
    }
  }, [customer])

  const { onTabChange, activeIndex } = useTabLinkingService(
    filterTabs(
      Object.keys(panes).map(pane => pane),
      customer
    ),
    'teams'
  )

  useEffect(() => {
    if (redirect) {
      routerProps.navigate('/customers')
    }
  })

  return (
    <PageContent>
      {banner && (
        <Message
          header="Changes to the customer model"
          content={
            <div>
              <p>
                This customer has been migrated to the new model. You are currently viewing the new customer details
                page.
                {urlSearchParams.get('multiTenants') && (
                  <span> You were redirected from a team with one or more tenants migrated to the new model. </span>
                )}{' '}
                <a
                  href="https://skedulo.atlassian.net/wiki/spaces/ProductDevelopment/pages/2202730518/New+Team+Model+Overview"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b style={{ fontWeight: 800, textDecoration: 'underline' }}>Learn more</b>
                </a>{' '}
                about changes or contact <b style={{ fontWeight: 800 }}> #squad-core-services</b> via slack if you have
                any questions.
              </p>
            </div>
          }
          icon="warning"
          warning
          onDismiss={() => setBanner(false)}
        />
      )}

      <Breadcrumb>
        <DynamicBreadcrumbs initialBreadcrumbs={[{ path: '/customers', name: 'Customers' }]} customerId={customerId} />
      </Breadcrumb>
      <h2>
        {customer?.name}
        <Menu secondary stackable>
          <Menu.Menu position="right">
            <Dropdown text="Actions" button basic className="action-menu">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NewDeleteCustomerModal customerId={customerId} onSuccessDelete={() => setRedirect(true)} menuItem />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </h2>

      <PageContent.Sidebar>
        <NewCustomerInformation customerId={customerId} />
      </PageContent.Sidebar>
      <PageContent.Main>
        <Tab panes={filteredPanes} onTabChange={onTabChange} activeIndex={activeIndex} />
      </PageContent.Main>
    </PageContent>
  )
}
