import { useEffect, useMemo, useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { DropdownItemProps } from 'semantic-ui-react'
import { DEFAULT_REGION, IRegionInfo } from '../actions/Regions'
import { Customer, NewCustomer, NewCustomerConfig } from '../actions/NewCustomers'
import { ICustomer } from '../actions/Customers'
import { IAuthConnection, ITeam } from '../actions/Teams'
import { IMetadata, ITeamTenant } from '../actions/Tenants'
import { useCreateCustomerMutation } from '../mutations/CreateCustomerMutation'
import { useMigrateTeamMutation } from '../mutations/MigrateTeamMutation'
import { ownerOptions } from '../views/newTeams/services/TeamInformationService'
import { useGetRegionQuery } from '../queries/GetRegionsQuery'
import { validateSalesforceId } from '../utils/salesforce'
import { Team } from '../actions/NewTeams'
import { useUpdateTeamNameMutation } from '../mutations/UpdateTeamNameMutation'
import { useGetEnvironmentsQuery } from '../queries/GetEnvironmentsQuery'

export interface MigrateCustomerService {
  migrateCustomerAndTeam: () => void
  isInvalidCustomerName: boolean
  setCustomerName: (name: string) => void
  setCustomerSalesforceId: (name: string) => void
  setSelectedRegion: (region: string) => void
  toggleCustomerIsInternal: () => void
  customer: NewCustomer
  customerName: string
  customerSalesforceId: string
  customerIsInternal: boolean
  modalIsOpen: boolean
  toggleModalIsOpen: () => void
  loading: boolean
  newTeamsRequest: INewTeamsRequest[]
  updateTeamRequest: (tenantId: string, teamRequest: INewTeamsRequest) => void
  addMigratedTenantId: (tenantId: string) => void
  removeMigratedTenantId: (tenantId: string) => void
  migratedTenantIds: string[]
  isReadyMigrate: boolean
  showSalesforceIdError: boolean
  setIsInvalidTeamsRequest: (errorNotUnique: boolean) => void
  environmentOptions: DropdownItemProps[]
  regionsInfo: IRegionInfo[]
  selectedRegion: string
}

const initMigratedTenantIds = (oldTeams: ITeam[]) => {
  const allTenantIds: string[] = []
  oldTeams.forEach(oldTeam => {
    oldTeam.tenants?.forEach(tenant => {
      allTenantIds.push(tenant.tenantId)
    })
  })
  return allTenantIds
}

const validateMigrateCustomerNameInput = (name: string) => {
  if (name === '') {
    return true
  }

  return /^(?!\s)[A-Za-z0-9 '-]+(?<!\s)$/.test(name)
}

export const checkIfPrefilledTeam = (
  prefilledTeamTenants: IPrefilledTeamTenantDetails[],
  newTeam: INewTeamsRequest
) => {
  const prefilledTeamTenant = prefilledTeamTenants.find(teamTenant => teamTenant.tenantId === newTeam.team.tenantId)
  const isPrefilledTeamRenamed: boolean =
    (prefilledTeamTenant &&
      prefilledTeamTenant.teamName !== '' &&
      prefilledTeamTenant.teamName !== newTeam.team.primaryName) ??
    false
  return { prefilledTeamTenant, isPrefilledTeamRenamed }
}
interface NewTeamRequest {
  isSalesforce: boolean
  primaryName: string
  description: string
  customerId: string
  region: string
  authProvider: string
  existingAuthConnections: IAuthConnection[]
  owner: string
  metadata: IMetadata
  tenantId: string
}
export interface INewTeamsRequest {
  environment: string
  team: NewTeamRequest
}

export interface IPrefilledTeamTenantDetails {
  teamName: string
  tenantId: string
}

export interface IMigratedTeamNameChanges {
  oldTeamName: string
  newTeamName: string
}

export const useMigrateCustomerService = (
  oldCustomer: ICustomer,
  oldTeams: ITeam[],
  setRedirect: (redirectUrl: string) => () => void
): MigrateCustomerService => {
  const [customerName, setCustomerName] = useState(oldCustomer.name)
  const [newTeamsRequest, setNewTeamsRequest] = useState([] as INewTeamsRequest[])
  const [migratedTenantIds, setMigratedTenantIds] = useState<string[]>(initMigratedTenantIds(oldTeams))
  const [customerSalesforceId, setCustomerSalesforceId] = useState(oldCustomer.salesforceId ?? '')
  const { value: customerIsInternal, toggle: toggleCustomerIsInternal } = useBoolean(false)
  const { value: modalIsOpen, toggle: toggleModalIsOpen } = useBoolean(false)
  const { value: invalidTeamsName, setValue: setIsInvalidTeamsName } = useBoolean(false)
  const [selectedRegion, setSelectedRegion] = useState('')
  const customerMetadata = oldCustomer.metadata
  const [prefilledTeamTenants, setPrefilledTeamTenants] = useState<IPrefilledTeamTenantDetails[]>([])
  const [isMigrating, setIsMigrating] = useState<boolean>(false)

  const { data: regionsInfo } = useGetRegionQuery()
  const { data: teamEnvironments } = useGetEnvironmentsQuery()
  const environmentOptions: DropdownItemProps[] = useMemo(
    () =>
      teamEnvironments?.map(env => ({
        key: `environment-${env.toLowerCase()}`,
        text: env,
        value: env
      })) ?? [],
    [teamEnvironments]
  )

  useEffect(() => {
    const isPrefilled = (team: ITeam, oldTenant: ITeamTenant) => {
      const hasProdTeam = team.tenants?.find(tenant => tenant.category === 'Production') !== undefined
      return hasProdTeam ? oldTenant?.category === 'Production' : team.tenants?.at(0)?.tenantId === oldTenant.tenantId
    }
    const finalTeamRequests: INewTeamsRequest[] = []
    const teamTenants: IPrefilledTeamTenantDetails[] = []
    oldTeams.forEach(team =>
      team.tenants?.forEach(tenant => {
        const isPrefilledTeam = isPrefilled(team, tenant)
        finalTeamRequests.push(initNewTeamRequest(team, tenant, isPrefilledTeam))
        if (isPrefilledTeam) {
          teamTenants.push({ teamName: team.name, tenantId: tenant.tenantId })
        }
      })
    )
    setNewTeamsRequest(finalTeamRequests)
    setPrefilledTeamTenants(teamTenants)
  }, [oldTeams, environmentOptions])

  const initNewTeamRequest = (team: ITeam, tenant: ITeamTenant, isPrefilled: boolean) => {
    const isExistInOptions = (tenantCategory: string, options: DropdownItemProps[]) => {
      let isExist = false
      options.forEach(option => {
        if (tenantCategory === option.text) {
          isExist = true
        }
      })
      return isExist
    }

    const environment = tenant.category && isExistInOptions(tenant.category, environmentOptions) ? tenant.category : ''
    const owner = tenant.environment && isExistInOptions(tenant.environment, ownerOptions) ? tenant.environment : ''
    return {
      environment,
      team: {
        isSalesforce: tenant?.vendor === 'salesforce',
        primaryName: isPrefilled ? (team.name ?? '') : '',
        description: isPrefilled ? (team.description ?? '') : '',
        customerId: team.customerId ?? '',
        region: team.region ? team.region.toLocaleUpperCase() : 'US',
        authProvider: 'auth0',
        existingAuthConnections: [...team.auth0Connections],
        owner,
        metadata: {},
        tenantId: tenant.tenantId
      }
    }
  }

  const updateTeamRequest = (tenantId: string, teamRequest: INewTeamsRequest) => {
    const modifiedTeamRequest = newTeamsRequest.map(newTeam =>
      newTeam.team.tenantId === tenantId ? teamRequest : newTeam
    )
    setNewTeamsRequest(modifiedTeamRequest)
  }

  const addMigratedTenantId = (tenantId: string) => {
    setMigratedTenantIds([...migratedTenantIds, tenantId])
  }

  const removeMigratedTenantId = (tenantId: string) => {
    const modifiedTenantIds = migratedTenantIds.filter(rtenantId => rtenantId !== tenantId)
    setMigratedTenantIds(modifiedTenantIds)
  }

  const customer: NewCustomer = customerIsInternal
    ? {
        name: customerName,
        isInternal: true,
        metadata: customerMetadata,
        region: selectedRegion ? selectedRegion.toUpperCase() : DEFAULT_REGION.toUpperCase()
      }
    : {
        name: customerName,
        isInternal: false,
        salesforceId: customerSalesforceId,
        metadata: customerMetadata,
        region: selectedRegion ? selectedRegion.toUpperCase() : DEFAULT_REGION.toUpperCase()
      }

  const { mutateAsync: createNewCustomer, isLoading: isLoadingCreateCustomer } = useCreateCustomerMutation()
  const { mutateAsync: migrateToNewTeam, isLoading: isLoadingMigrateTeam } = useMigrateTeamMutation()
  const { mutateAsync: updateTeamName } = useUpdateTeamNameMutation(() => null)

  const customerSalesforceIdIsValid = validateSalesforceId(customerSalesforceId)

  const migratedTeamsRequest = newTeamsRequest.filter(request => migratedTenantIds.includes(request.team.tenantId))

  const showSalesforceIdError =
    (!customerIsInternal && !customerSalesforceId) || !(!customerSalesforceId.trim() || customerSalesforceIdIsValid)

  const isRequiredDataFilled = () => {
    const isCustomerNameFilled = customerName.length > 0
    const isSalesforceIdFilled = !showSalesforceIdError

    const checkTeamValid = (teamRequest: INewTeamsRequest) =>
      !!teamRequest.team.primaryName &&
      !!teamRequest.team.description &&
      !!teamRequest.environment &&
      !!teamRequest.team.owner
    const allTeamsValid = migratedTeamsRequest.map(checkTeamValid).reduce((prev, curr) => prev && curr, true)

    return isCustomerNameFilled && isSalesforceIdFilled && allTeamsValid
  }

  const isInvalidCustomerName = !validateMigrateCustomerNameInput(customerName)

  const isReadyMigrate =
    !isInvalidCustomerName &&
    migratedTenantIds.length > 0 &&
    newTeamsRequest.length > 0 &&
    isRequiredDataFilled() &&
    !invalidTeamsName &&
    selectedRegion !== ''

  const migrateTeams = async (newCustomer: Customer) => {
    const changedTeamNames: IMigratedTeamNameChanges[] = []

    const migratedTeamPromises = migratedTeamsRequest.map(newTeam => {
      const api =
        regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === newTeam.team.region.toLowerCase())?.server
          .api || ''

      const { prefilledTeamTenant, isPrefilledTeamRenamed } = checkIfPrefilledTeam(prefilledTeamTenants, newTeam)

      if (isPrefilledTeamRenamed) {
        changedTeamNames.push({
          oldTeamName: prefilledTeamTenant?.teamName ?? '',
          newTeamName: newTeam.team.primaryName
        })
      }

      const team = {
        ...newTeam,
        team: {
          ...newTeam.team,
          primaryName:
            isPrefilledTeamRenamed && prefilledTeamTenant?.teamName
              ? prefilledTeamTenant.teamName
              : newTeam.team.primaryName,
          customerId: newCustomer.id
        }
      }
      return migrateToNewTeam({ api, team })
    })

    const newTeams = await Promise.all(migratedTeamPromises)

    return { newTeams, changedTeamNames }
  }

  const renamePrefilledTeams = (newTeams: Team[], migratedTeamNameChanges: IMigratedTeamNameChanges[]) => {
    const renameTeamPromises = migratedTeamNameChanges.map(change => {
      const teamToBeRenamed = newTeams.filter(newTeam => newTeam.primaryName === change.oldTeamName)[0]

      return updateTeamName({ teamId: teamToBeRenamed.id, name: change.newTeamName })
    })

    return Promise.all(renameTeamPromises)
  }

  const migrateCustomerAndTeam = async () => {
    setIsMigrating(true)

    try {
      const newCustomer = await createNewCustomer({ customer } as NewCustomerConfig)
      // Wait 5 second for dynamo db to be consistent
      await new Promise(r => setTimeout(r, 5000))
      const { newTeams, changedTeamNames } = await migrateTeams(newCustomer)
      await renamePrefilledTeams(newTeams, changedTeamNames)
      setIsMigrating(false)
      toggleModalIsOpen()
      setRedirect(`/customers/${newCustomer.id}`)()
    } catch (_error) {
      setIsMigrating(false)
    }
  }

  const setIsInvalidTeamsRequest = (isError: boolean) => setIsInvalidTeamsName(isError)

  return {
    migrateCustomerAndTeam,
    isInvalidCustomerName,
    setCustomerName,
    setCustomerSalesforceId,
    setSelectedRegion,
    toggleCustomerIsInternal,
    toggleModalIsOpen,
    customer,
    customerName,
    newTeamsRequest,
    customerSalesforceId,
    customerIsInternal,
    modalIsOpen,
    updateTeamRequest,
    addMigratedTenantId,
    removeMigratedTenantId,
    migratedTenantIds,
    isReadyMigrate,
    showSalesforceIdError,
    loading: isLoadingCreateCustomer || isLoadingMigrateTeam || isMigrating,
    setIsInvalidTeamsRequest,
    environmentOptions,
    regionsInfo: regionsInfo?.map(regionInfo => ({ ...regionInfo, region: regionInfo.region.toUpperCase() })) ?? [],
    selectedRegion
  }
}
