import * as React from 'react'
import { Navigate } from 'react-router'
import { Breadcrumb, Button, Grid, Icon, Menu, Segment } from 'semantic-ui-react'
import { ITeamTenant } from 'src/actions/Tenants'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getTeamByName, getTeamByTenant, Team } from '../../actions/NewTeams'
import { getRegions, IRegions, IRegionUrls, toRegions, toRegionUrls } from '../../actions/Regions'
import { getConnectionDetails, getTeamById, IAuthConnection, IDetailedAuthConection, ITeam } from '../../actions/Teams'
import { createErrorToast } from '../alertComponents/Alert'
import { AddTenantModal } from '../tenants/components/AddTenantModal'
import { TenantsList } from '../tenants/components/TenantsList'
import { elasticServerConfig } from '../../actions/Endpoints'
import MigrationWarning from '../banners/MigrationWarning'
import { RouterProps } from '../../router/RouterProps'
import { ConnectionsList } from './components/ConnectionsList'
import { DeleteTeamModal } from './components/DeleteTeamModal'
import { NewConnectionForm } from './components/NewConnectionForm'
import { TeamInformation } from './components/TeamInformation'

interface IState {
  redirectUrl: string
  team?: ITeam
  detailedConnections: IDetailedAuthConection[]
  fetching: boolean
  addConnectionFormActive: boolean
  addTenantFormActive: boolean
  regions: IRegions
  regionUrls: IRegionUrls
}

type IProps = RouterProps

export default class TeamDetails extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      redirectUrl: '',
      fetching: false,
      regions: {},
      regionUrls: {},
      addConnectionFormActive: false,
      addTenantFormActive: false,
      detailedConnections: []
    }
  }

  async componentDidMount() {
    this.setState({ fetching: true })
    await this.loadData()
    this.setState({ fetching: false })
    await this.checkForNewModelTeam()
  }

  componentDidUpdate() {
    document.title = formatDocumentTitle(this.state.team?.name)
  }

  loadData = () => Promise.all([this.fetchTeam(), this.fetchRegions()]).catch(error => createErrorToast(error))

  fetchConnectionDetails = (connections: IAuthConnection[]) =>
    Promise.all(
      connections.map(connection =>
        getConnectionDetails(
          connection.name,
          this.state.regionUrls[this.state.team?.region || ''] || elasticServerConfig.baseURL
        ).then(connectionDetails => ({ ...connection, ...connectionDetails }))
      )
    ).then(detailedConnections => this.setState({ detailedConnections }))

  fetchRegions = (): Promise<void> =>
    getRegions().then(regionInfo =>
      this.setState({ regions: toRegions(regionInfo), regionUrls: toRegionUrls(regionInfo) })
    )

  fetchTeam = (): Promise<void> => {
    const id = this.props.params.id || ''
    return getTeamById(id).then((team?: ITeam) => {
      if (team !== undefined) {
        this.setState({
          team
        })
        return this.fetchConnectionDetails(team.auth0Connections)
      } else {
        createErrorToast(new Error(`No team found with the id: ${id}`), { title: 'An error has occurred:' })
        this.setRedirect('/old-model/teams')()
        return Promise.resolve()
      }
    })
  }

  handleNewModelRedirect = ({ teamId, customerId }: { teamId?: string; customerId?: string }) => {
    if (teamId) {
      this.setRedirect(`/teams/${teamId}?banner=true`)()
    }

    if (customerId) {
      this.setRedirect(`/customers/${customerId}?banner=true&multiTenants=true`)()
    }
  }

  checkForNewModelTeam = async (): Promise<void> => {
    // Check if new model team with this name exists, otherwise look at the tenants
    const newModelTeam = await this.fetchNewModelTeamByName()
    if (newModelTeam) {
      this.handleNewModelRedirect({ teamId: newModelTeam.id })
    } else {
      this.checkTenantsForNewModelTeams(this.state?.team?.tenants)
    }
  }

  checkTenantsForNewModelTeams = async (tenants: ITeamTenant[] | undefined): Promise<void> => {
    if (!tenants) {
      return
    }

    if (tenants.length === 1) {
      // There is only one tenant so we want to redirect to a team if it exists
      const team = await this.fetchNewModelTeamByTenant(tenants[0].tenantId)
      if (team) {
        this.handleNewModelRedirect({ teamId: team.id })
      }
    } else {
      // Multiple tenants exist, maybe one is a new model team
      for (const tenant of tenants) {
        const team = await this.fetchNewModelTeamByTenant(tenant.tenantId)
        if (team) {
          this.handleNewModelRedirect({ customerId: team.customerId })
          break
        }
      }
    }
  }

  fetchNewModelTeamByName = async (): Promise<Team | null> => {
    try {
      const teamName = this.state.team?.name
      if (teamName) {
        return await getTeamByName(teamName)
      }
      return null
    } catch (error: any) {
      if (error.response?.status !== 404) {
        createErrorToast(error)
      }
      return null
    }
  }

  fetchNewModelTeamByTenant = async (tenantId: string): Promise<Team | null> => {
    try {
      if (tenantId) {
        return await getTeamByTenant(tenantId)
      }
      return null
    } catch (error: any) {
      if (error.response?.status !== 404) {
        createErrorToast(error)
      }
      return null
    }
  }

  loadTeam = (): Promise<void> => {
    this.setState({ fetching: true })
    return this.fetchTeam()
      .then(() => this.setState({ fetching: false }))
      .catch(error => createErrorToast(error))
  }

  setRedirect = (redirectUrl: string) => () => {
    this.setState({ redirectUrl })
  }

  toggleAddConnectionForm = (): void => {
    this.setState(prevState => ({ addConnectionFormActive: !prevState.addConnectionFormActive }))
  }

  render() {
    const { fetching, addConnectionFormActive, redirectUrl, detailedConnections, regions } = this.state

    const team: ITeam = this.state.team || {
      id: '',
      name: '',
      description: '',
      auth0Connections: [],
      createdDate: '',
      updatedDate: ''
    }

    return (
      <div className="route-component">
        {redirectUrl && <Navigate to={redirectUrl} />}
        <MigrationWarning />
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => this.props.navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <DeleteTeamModal name={team.name} id={team.id} setRedirect={this.setRedirect('/old-model/teams')} />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ maxHeight: '100%' }} className="scrollable">
            <TeamInformation
              team={team || { id: '', name: '', description: '', auth0Connections: [] }}
              regions={regions || {}}
              loadTeam={this.loadTeam}
              loading={fetching}
            />
          </Grid.Column>
          <Grid.Column width={11} style={{ maxHeight: '100%' }} className="scrollable">
            <Menu secondary fluid stackable>
              <Menu.Menu position="left">
                <Menu.Item>
                  <h3>Login Options</h3>
                </Menu.Item>
              </Menu.Menu>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    className={addConnectionFormActive ? '' : 'form-button-sked-blue'}
                    content={addConnectionFormActive ? 'Cancel' : 'Add a New Login Option'}
                    onClick={this.toggleAddConnectionForm}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            {addConnectionFormActive && (
              <NewConnectionForm
                updateTeam={this.loadTeam}
                teamId={team.id}
                toggleForm={this.toggleAddConnectionForm}
                auth0Connections={detailedConnections}
              />
            )}
            <ConnectionsList
              auth0Connections={detailedConnections}
              teamId={team.id}
              updateTeam={this.loadTeam}
              loading={fetching}
            />
            <Menu fluid stackable secondary>
              <Menu.Menu position="left">
                <Menu.Item>
                  <h3>Tenants</h3>
                </Menu.Item>
              </Menu.Menu>
              <Menu.Menu position="right">
                <Menu.Item>
                  <AddTenantModal updateTeam={this.loadTeam} teamId={team.id} />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Segment color="blue">
              <TenantsList
                tenants={team.tenants || []}
                loading={fetching}
                searchValue={{ searchCategory: 'All', searchValue: '' }}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
