import { Button, Grid } from 'semantic-ui-react'
import { UpdateCustomerConnectionService } from '../../../../../views/newCustomers/services/UpdateCustomerConnectionService'
import { CustomerConnection } from '../../../../../actions/CustomerConnections'
import { CUSTOMER_LOGIN_TYPE_REQUEST, EMAIL_PASSWORD, OPEN_ID_CONNECT } from '../../../services/Constants'
import EmailPasswordLoginOptionDetails from './EmailPasswordLoginOptionDetails'
import OpenIDConnectLoginOptionDetails from './OpenIDConnectLoginOptionDetails'

export const renderRow = (title: string, component: JSX.Element): JSX.Element => (
  <Grid.Row>
    <Grid.Column textAlign="left" verticalAlign="middle">
      <h4>{title}</h4>
    </Grid.Column>
    {component}
  </Grid.Row>
)

export const renderLoginOptionDetails = (loginOption: CustomerConnection): JSX.Element => {
  if (loginOption.type === CUSTOMER_LOGIN_TYPE_REQUEST[EMAIL_PASSWORD]) {
    return <EmailPasswordLoginOptionDetails loginOption={loginOption} />
  } else if (loginOption.type === CUSTOMER_LOGIN_TYPE_REQUEST[OPEN_ID_CONNECT]) {
    return <OpenIDConnectLoginOptionDetails loginOption={loginOption} />
  }
  return <></>
}

export const renderEditSaveCancelButton = (
  updateCustomerConnectionService: UpdateCustomerConnectionService
): JSX.Element => {
  const {
    runUpdateCustomerLoginOption,
    setIsResetForm,
    isEditable,
    setIsEditable,
    isUpdatingCustomerLoginOption,
    isSaveButtonEnabled
  } = updateCustomerConnectionService

  const handleEditClicked = () => setIsEditable(true)
  const handleSaveClicked = () => runUpdateCustomerLoginOption()
  const handleCancelClicked = () => setIsResetForm(true)

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8} />
        {isEditable ? (
          <Grid.Column width={3} textAlign="right">
            <Button
              size="small"
              color="blue"
              content="Save"
              loading={isUpdatingCustomerLoginOption}
              disabled={!isSaveButtonEnabled}
              onClick={handleSaveClicked}
            />
            <Button
              size="small"
              content="Cancel"
              onClick={handleCancelClicked}
              disabled={isUpdatingCustomerLoginOption}
            />
          </Grid.Column>
        ) : (
          <Grid.Column width={3} textAlign="right">
            <Button color="blue" size="small" content="Edit" disabled={isEditable} onClick={handleEditClicked} />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  )
}
