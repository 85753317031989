import * as React from 'react'
import { Link } from 'react-router'
import { Button, Pagination, Table } from 'semantic-ui-react'
import { ITeamTenant, ITenant, ITenantWithTeamName } from '../../../actions/Tenants'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, getDisplayedData, handlePaginationChange, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { ISearch } from '../../Utils'

interface IProps {
  tenants: ITenantWithTeamName[] | ITeamTenant[]
  loading: boolean
  withTeamName?: boolean
  searchValue: ISearch
}

interface IState {
  activePage: number
  sortColumn: string
  sortDirection: 'ascending' | 'descending'
}

export class TenantsList extends React.PureComponent<IProps, IState> {
  TENANTS_PER_PAGE = 17

  constructor(props: IProps) {
    super(props)
    this.state = {
      activePage: 1,
      sortColumn: 'teamName',
      sortDirection: 'ascending'
    }
  }

  handlePaginationChangeFn = handlePaginationChange(activePage => this.setState({ activePage }))

  handleSort = (clickedColumn: string) => () => {
    const { sortColumn, sortDirection } = this.state
    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        sortDirection: 'ascending'
      })
    } else {
      this.setState({
        sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending'
      })
    }
  }

  render() {
    const { withTeamName, loading, searchValue } = this.props
    const { activePage, sortColumn, sortDirection } = this.state

    const SortedTeamHeaderCell = SortedHeaderCell(sortColumn, sortDirection, this.handleSort)

    const tenants = this.props.tenants as ITeamTenant[]
    const filteredTenants = filterData<ITenant>(searchValue, this.props.tenants as ITenantWithTeamName[])
    const sortedTenants = sortData(filteredTenants, sortColumn, sortDirection)
    const displayedTenants = getDisplayedData(sortedTenants, activePage, this.TENANTS_PER_PAGE)
    const totalPages = Math.ceil(filteredTenants.length / this.TENANTS_PER_PAGE)
    return (
      <React.Fragment>
        <div className="scrollable-with-pagination">
          <Table
            celled={!withTeamName}
            basic={withTeamName ? false : 'very'}
            stackable
            size="small"
            color="blue"
            striped={withTeamName}
            sortable={withTeamName}
            className={withTeamName ? 'sticky-table' : undefined}
          >
            <Table.Header>
              <Table.Row>
                {withTeamName && <SortedTeamHeaderCell name="teamName" title="Team" width="4" />}
                <SortedTeamHeaderCell name="tenantId" title="Tenant ID / Org ID" width={withTeamName ? '5' : '7'} />
                <SortedTeamHeaderCell title="Vendor" />
                <SortedTeamHeaderCell title="Environment" />
                <SortedTeamHeaderCell title="Category" />
                <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap loading={loading} array={tenants} emptyMessage="No Tenants">
              <Table.Body>
                {(withTeamName ? displayedTenants : tenants).map(tenant => (
                  <Table.Row key={tenant.tenantId}>
                    {withTeamName && (
                      <Table.Cell className="table-cell-overflow">
                        {(tenant as ITenantWithTeamName).teamName}
                      </Table.Cell>
                    )}
                    <Table.Cell className="table-cell-overflow">{tenant.tenantId}</Table.Cell>
                    <Table.Cell>{tenant.vendor}</Table.Cell>
                    <Table.Cell>{tenant.category}</Table.Cell>
                    <Table.Cell>{tenant.environment}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button icon="file alternate outline" as={Link} to={`/old-model/tenants/${tenant.tenantId}`} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
        </div>
        {withTeamName && (
          <Pagination
            activePage={activePage}
            boundaryRange={1}
            onPageChange={this.handlePaginationChangeFn}
            totalPages={totalPages}
          />
        )}
      </React.Fragment>
    )
  }
}
